import React from "react";
import { useParams } from "react-router-dom";
import { IRouterParams } from "router/route-params";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { ContentLayout } from "pages/content-layout";
import { CompanyBreadcrumbs } from "components/breadcrumbs/company-breadcrumbs";
import { CompanyProfileHeader } from "./company-profile-header";
import { useCompany } from "utils/hooks/use-company";
import { CompanyContext } from "./company-context";
import { CompanyInfo } from "./tab-contents/company-info";
import { PlanInfo } from "pages/plan-info";
import { AdminAPITypes } from "@stellar/api-logic";
import { ProjectsInfo } from "pages/projects-info";
import { LoadingScreen } from "components/loading-screen";
import { GroupsInfo } from "./tab-contents/groups-info";

/**
 * Fetch company data and display the data in company profile page
 */
export function CompanyProfilePage(): JSX.Element {
  const { companyId } = useParams<IRouterParams>();

  const { handleErrorWithPage } = useErrorHandler();

  const [company, isFetchingData] = useCompany(companyId, handleErrorWithPage);

  return (
    <LoadingScreen isFetching={isFetchingData} data={company}>
      {company && (
        <CompanyContext.Provider value={company}>
          <ContentLayout
            breadcrumbs={<CompanyBreadcrumbs company={company} />}
            header={<CompanyProfileHeader />}
            tabs={[
              {
                label: "Company Information",
                content: <CompanyInfo />,
              },

              {
                label: "Groups",
                content: <GroupsInfo companyId={companyId} />,
              },

              {
                label: "Projects",
                content: <ProjectsInfo ownerId={companyId} />,
              },

              {
                label: "Plans",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.company}
                    subjectId={companyId}
                  />
                ),
              },
              {
                label: "Hardware Licenses",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.device}
                    subjectId={companyId}
                  />
                ),
              },
            ]}
            defaultTabIndex={2}
          />
        </CompanyContext.Provider>
      )}
    </LoadingScreen>
  );
}
