import { CoreApi } from "api";
import { InfoRow } from "components/info-grid/info-row";
import React, { useMemo } from "react";
import { formatPlanSubjectType } from "utils/data-display";
import { Color } from "utils/styles/default-colors";
import { useNavigation } from "router/use-navigation";
import { usePlanCreationContext } from "../plan-creation-context";
import { FormContainer } from "components/forms/form-container";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { Constraints } from "components/constraints";
import { createPlanCreationPayload } from "../create-plan-creation-payload";
import { DateTimeUtils } from "@stellar/web-core";
import { Box, Grid } from "@mui/material";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { createCustomPlanCreationPayload } from "../create-custom-plan-creation-payload";
import { useAppDispatch, useAppSelector } from "store/store-helper";
import {
  getProductByIdentifierSelector,
  selectedAddonSelector,
  selectedProductSelector,
} from "store/products/products-selector";
import { resetProductsState } from "store/products/products-slice";

/** Shows the summary of the plan that is going to be created */
export function PlanCreationSummary(): JSX.Element {
  const planCreationContext = usePlanCreationContext();
  const { navigateToProfilePage } = useNavigation();
  const { handleErrorWithDialog } = useErrorHandler();
  const {
    planSubjectId,
    planSubjectType,
    planSubjectName,
    startDate,
    endDate,
    isDateRequired,
    comments,
    setShouldShowSummary,
    setIsFetchingData,
    activeConstraints,
    shouldAddToExistingStripeSubscription,
    existingStripeSubscriptionId,
  } = planCreationContext;

  const isSubjectTypeDevice = useMemo(() => {
    return planSubjectType === AdminAPITypes.ESubjectType.device;
  }, [planSubjectType]);

  const selectedProduct = useAppSelector(selectedProductSelector);
  const selectedAddon = useAppSelector(selectedAddonSelector);
  const cadDisplayFeature = useAppSelector(
    getProductByIdentifierSelector(APITypes.EUserSubscriptionRole.cadDisplay)
  );
  const dispatch = useAppDispatch();

  async function createDevicePlan(
    plan: AdminAPITypes.CreatePlanPayload,
    planSubjectId: string
  ): Promise<void> {
    // For Device subject type, subscriber info is required as the subjectId alone can't identify the subscriber.
    const devicePlan: AdminAPITypes.CreateDevicePlanPayload = {
      ...plan,
      subscriber: {
        // We used companyId as the planSubjectId for the Hardware Licenses.
        // So, in this context, planSubjectId serves as companyId, representing the devicePlan subscriber.
        id: planSubjectId,
        type: AdminAPITypes.ESubscriberType.company,
      },
    };
    try {
      await CoreApi.V0.ADMIN.createPlan(devicePlan);
      navigateToProfilePage(AdminAPITypes.ESubjectType.company, planSubjectId);
      dispatch(resetProductsState());
    } catch (error) {
      handleErrorWithDialog("Error while creating device plan", error);
    }
  }

  async function createPlan(
    plan: AdminAPITypes.CreatePlanPayload
  ): Promise<void> {
    try {
      await CoreApi.V0.ADMIN.createPlan(plan);
      // Add cad-display feature if the selected plan is cad-import
      if (
        selectedProduct?.identifier ===
          APITypes.EUserSubscriptionRole.cadImport &&
        cadDisplayFeature
      ) {
        const cadDisplayPlan = createCustomPlanCreationPayload({
          planCreationContext,
          selectedProduct: cadDisplayFeature,
        });
        await CoreApi.V0.ADMIN.createPlan(cadDisplayPlan);
      }
      navigateToProfilePage(planSubjectType, planSubjectId);
      dispatch(resetProductsState());
    } catch (error) {
      const typedError = error as { devMessage?: string };

      // Determine if the error message indicates a conflict with an existing user role constraint
      const hasUserRoleConstraint = activeConstraints.find(
        (constraint) =>
          constraint.type === AdminAPITypes.EConstraintType.userRole
      );
      if (
        hasUserRoleConstraint &&
        typedError?.devMessage?.includes("conflicts")
      ) {
        handleErrorWithDialog(
          "A user role limit is already set for this company. " +
            "Please deactivate the existing limit before adding a new one.",
          error
        );
      } else {
        handleErrorWithDialog(
          "An error occurred while creating a new plan. Please try again later.",
          error
        );
      }
      // Hide spinner in case the request failed
      setIsFetchingData(false);
    }
  }

  async function onCreateClicked(): Promise<void> {
    setIsFetchingData(true);
    try {
      const plan = createPlanCreationPayload(
        planCreationContext,
        selectedProduct,
        selectedAddon
      );
      isSubjectTypeDevice
        ? await createDevicePlan(plan, planSubjectId)
        : await createPlan(plan);
    } catch (error) {
      handleErrorWithDialog("Error while creating a new plan", error);

      // Hide spinner in case the request failed
      setIsFetchingData(false);
    }
  }

  const formattedStartDate = DateTimeUtils.format({
    date: startDate,
    shouldUseUtc: true,
  });
  const formattedEndDate = DateTimeUtils.format({
    date: endDate,
    shouldUseUtc: true,
  });

  return (
    <FormContainer
      sx={{
        "& > p": {
          color: Color.orange,
        },
      }}
      createButtonText="Confirm"
      handleCreateClicked={onCreateClicked}
      cancelButtonText="Back"
      handleCancelClicked={() => setShouldShowSummary(false)}
    >
      <b>Confirm the plan and invoicing details.</b>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <h2>Plan Details</h2>
            <Box sx={{ pl: "10px" }}>
              <InfoRow
                labelTextAlign="left"
                label={formatPlanSubjectType(planSubjectType)}
                info={planSubjectName}
              />

              <InfoRow
                labelTextAlign="left"
                label="Product"
                info={selectedProduct?.name}
              />

              {selectedAddon && (
                <InfoRow
                  labelTextAlign="left"
                  label="Add-on"
                  info={selectedAddon.name}
                />
              )}

              {/* We don't need to show the Plan limits section for the Device subject type: */}
              {!isSubjectTypeDevice && (
                <InfoRow
                  labelTextAlign="left"
                  label="Plan Limits"
                  info={
                    <Constraints
                      constraints={activeConstraints}
                      shouldFormatConstraints={false}
                    />
                  }
                />
              )}

              {isDateRequired && (
                <>
                  <InfoRow
                    labelTextAlign="left"
                    label="Start Date"
                    info={`${formattedStartDate} (UTC)`}
                  />
                  <InfoRow
                    labelTextAlign="left"
                    label="End Date"
                    info={`${formattedEndDate} (UTC)`}
                  />
                </>
              )}

              {shouldAddToExistingStripeSubscription && (
                <InfoRow
                  labelTextAlign="left"
                  label="Existing Stripe subscription"
                  info={existingStripeSubscriptionId}
                />
              )}

              <InfoRow labelTextAlign="left" label="Comment" info={comments} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormContainer>
  );
}
