import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { NavBar } from "components/nav-bar";
import { hbTheme } from "utils/styles/hb-theme";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RootRouter } from "router/root-router";
import "typeface-roboto";
import { useLoggedInUser } from "utils/hooks/use-logged-in-user";
import { HBSpinner } from "components/hb-customs/hb-spinner";
import { useNavigation } from "router/use-navigation";

/**
 * The root component of the admin-panel
 */
export function AdminPanel(): JSX.Element | null {
  const [loggedInUser, isFetching] = useLoggedInUser();

  const { navigateToWorkspacePage } = useNavigation();

  if (isFetching) {
    return (
      <ThemeProvider theme={hbTheme}>
        <HBSpinner />
      </ThemeProvider>
    );
  } else if (loggedInUser) {
    return (
      <ThemeProvider theme={hbTheme}>
        {/* DOC: CssBaseline takes care of CSS normalization */}
        {/* https://material-ui.com/components/css-baseline/ */}
        <CssBaseline />

        <BrowserRouter>
          <header>
            <NavBar profileImageUrl={loggedInUser.profileImageUrl} />
          </header>
          <main>
            <RootRouter />
          </main>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else {
    // The user will be leaving the admin-panel and go to the workspace page as they are not logged in
    navigateToWorkspacePage();
    return null;
  }
}
