import { CoreApi } from "api";
import { useEffect, useState } from "react";
import { CoreAPITypes } from "@stellar/api-logic";

/**
 * Hook returning the currently logged in user
 *
 * Returns a tuple of the fetched user and a loading flag, which is true while the user is being fetched
 */
export function useLoggedInUser(): [
  CoreAPITypes.IUserJson | undefined,
  boolean
] {
  const [loggedInUser, setLoggedInUser] = useState<CoreAPITypes.IUserJson>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Fetch the currently logged in user
  useEffect(() => {
    async function fetchUser(): Promise<void> {
      setIsFetching(true);

      try {
        setLoggedInUser(await CoreApi.V1.getLoggedInUser());
      } catch (error) {
        setLoggedInUser(undefined);
      } finally {
        setIsFetching(false);
      }
    }

    fetchUser();
  }, []);

  return [loggedInUser, isFetching];
}
