import { PlanInfoFooter } from "components/footers";
import { PlansTable } from "components/tables/plans-table";
import { PlansContext } from "contexts/plans-context";
import { ContentLayout } from "pages/content-layout";
import React, { useState } from "react";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { usePlansOfSubjectType } from "utils/hooks/plan/use-plans-of-subject-type";
import { SubjectEntityIdTypes, SubjectEntityTypes } from "utils/utility-types";
import { LoadingScreen } from "components/loading-screen";

interface IProps {
  /** Type of the subject entity that the plan tab is related to */
  subjectType: SubjectEntityTypes;

  /** ID of the subject entity that the plan tab is related to */
  subjectId: SubjectEntityIdTypes;
}

/** Content inside the plans tab */
export function PlanInfo({ subjectType, subjectId }: IProps): JSX.Element {
  const [shouldFetchPlans, setShouldFetchPlans] = useState<boolean>(true);

  const { handleErrorWithDialog } = useErrorHandler();

  const [plans, isFetchingPlans] = usePlansOfSubjectType(
    subjectType,
    subjectId,
    handleErrorWithDialog,
    shouldFetchPlans,
    setShouldFetchPlans
  );

  return (
    <ContentLayout
      footer={
        <PlanInfoFooter
          numberOfPlans={plans.length}
          subjectType={subjectType}
          subjectId={subjectId}
        />
      }
    >
      <LoadingScreen isFetching={isFetchingPlans}>
        <PlansContext.Provider
          value={{
            plans,
            fetchPlans: () => setShouldFetchPlans(true),
          }}
        >
          <PlansTable />
        </PlansContext.Provider>
      </LoadingScreen>
    </ContentLayout>
  );
}
