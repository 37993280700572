import { CreateProjectPlanPage } from "pages/create-plan/create-project-plan-page";
import { PageNotFound } from "pages/page-not-found";
import { ProjectProfilePage } from "pages/project-profile/project-profile";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ProjectAddPlanRoute, ProjectProfileRoute } from "./project-routes";

/**
 * Router for navigating all project related pages
 */
export function ProjectRouter(): JSX.Element {
  return (
    <Switch>
      <Route exact path={ProjectProfileRoute}>
        <ProjectProfilePage />
      </Route>

      <Route exact path={ProjectAddPlanRoute}>
        <CreateProjectPlanPage />
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
