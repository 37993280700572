import { TextField } from "@mui/material";
import { HBDialog } from "components/hb-customs/hb-dialog";
import { AdminAPITypes } from "@stellar/api-logic";
import React, { useState } from "react";
import { formatPlanName } from "utils/data-display";

interface IDeactivatePlanDialogProps {
  /** The plan that is about to get deactivated */
  plan: AdminAPITypes.IPlan;

  /** Function called when the user cancels the deactivation */
  onCancel(): void;

  /**
   * Function called when the user confirms the deactivation
   * Called with the updated comment, including the deactivation reason
   */
  onConfirm(updatedComment: string): void;
}

/**
 * Dialog to show when the user deactivates a plan. *
 * User has to enter a reason why the plan is being deactivated, and
 * the user is allowed to edit the plan's comment
 */
export function DeactivatePlanDialog({
  plan,
  onCancel: handleCancel,
  onConfirm,
}: IDeactivatePlanDialogProps): JSX.Element {
  const [planComment, setPlanComment] = useState<string>(plan.description);
  const [deactivationReason, setDeactivationReason] = useState<string>("");

  function handleConfirm(): void {
    let updatedComment = `Deactivation Reason:\n${deactivationReason}`;

    if (planComment) {
      updatedComment = `${planComment}\n\n${updatedComment}`;
    }

    onConfirm(updatedComment);
  }

  return (
    <HBDialog
      onClose={handleCancel}
      closeButtonText="Cancel"
      title={`Are you sure you want to deactivate plan "${formatPlanName(
        plan
      )}"?`}
      onConfirm={handleConfirm}
      confirmButtonText="Yes, deactivate"
      shouldDisableConfirmButton={!deactivationReason}
      shouldBeFullWidth={true}
      disabledConfirmationButtonTooltip="A deactivation reason must be entered"
    >
      <TextField
        label="Current plan comments"
        multiline
        minRows={2}
        value={planComment}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          setPlanComment(event.target.value);
        }}
        variant="outlined"
        fullWidth
      />

      <p>Please enter the reason why this plan is being deactivated:</p>
      <TextField
        label="Deactivation reason"
        multiline
        minRows={2}
        value={deactivationReason}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          setDeactivationReason(event.target.value);
        }}
        variant="outlined"
        fullWidth
        required
      />
    </HBDialog>
  );
}
