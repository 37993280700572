import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import pickBy from "lodash/pickBy";
import { GIGA_BYTE_SIZE } from "./data-display";

/**
 * remove the current field from the data property of each constraint if it exists.
 *
 * @param constraints
 * @returns a new array of constraints with the current field removed from the data property where applicable.
 */
export function removeCurrentFieldFromConstraints(
  constraints: AdminAPITypes.CreateConstraintPayload[]
): AdminAPITypes.CreateConstraintPayload[] {
  const cleandConstriants = constraints.map((constraint) => {
    if (constraint.data && "current" in constraint.data) {
      const { current, ...restData } = constraint.data;
      return { ...constraint, data: restData };
    }
    return constraint;
  });

  return cleandConstriants;
}

export const Constraint = {
  /** Returns the CreateConstraintPayload payload for a given constraintType and maxValue */
  createPayloadForConstraintWithValue(
    constraintType: AdminAPITypes.EConstraintType,
    max: number,
    current: number = 0,
    roles?: AdminAPITypes.IConstraintUserRoles
  ): AdminAPITypes.CreateConstraintPayload {
    return {
      type: constraintType,
      data: { max, current, roles },
    };
  },

  /** Get the max value of the given constraint */
  getMaxValueOfConstraint(
    constraint: AdminAPITypes.CreateConstraintPayload
  ): number {
    let maxValue = constraint.data.max;

    if (constraint.type === AdminAPITypes.EConstraintType.storage) {
      const maxBytes = constraint.data.max;
      maxValue = Math.round(maxBytes / GIGA_BYTE_SIZE);
    }

    return maxValue;
  },

  /**
   * Returns all constraints that are available to be added to a plan of a specific subjectType
   * As it is not possible to add plans for user and folder subjects right now, these two subjectTypes will throw error
   */
  availableConstraintsForSubjectType(
    planSubjectType: AdminAPITypes.ESubjectType
  ): {
    [key: string]: AdminAPITypes.EConstraintType;
  } {
    const allAvailableConstraints = {
      ...AdminAPITypes.EConstraintType,
    };

    switch (planSubjectType) {
      case AdminAPITypes.ESubjectType.project:
        return pickBy(allAvailableConstraints, (constraint) =>
          AllowedConstraints.forProjectPlans.includes(constraint)
        );

      case AdminAPITypes.ESubjectType.company:
      case AdminAPITypes.ESubjectType.device:
        return pickBy(allAvailableConstraints, (constraint) =>
          AllowedConstraints.forCompanyPlans.includes(constraint)
        );

      case AdminAPITypes.ESubjectType.user:
      case AdminAPITypes.ESubjectType.folder:
        throw new Error(
          `availableConstraintsForSubjectType(): Creating plans with subject ${planSubjectType} is not supported`
        );

      default:
        throw new Error("Subject not defined; creating plans is unsupported.");
    }
  },

  /**
   * Helper function to work the available constraints as array
   * For example, for loops or counting
   */
  availableConstraintsForSubjectTypeAsArray(
    planSubjectType: AdminAPITypes.ESubjectType
  ): AdminAPITypes.EConstraintType[] {
    return Object.values(
      this.availableConstraintsForSubjectType(planSubjectType)
    );
  },
};

/**
 * Define the allowed User roles, categorized by company and resource roles.
 */
export const AllowedUserRoles: {
  allResourceRoles: CoreAPITypes.EUserProjectRoleUpdated[];
  allCompanyRoles: CoreAPITypes.EUserCompanyRoleUpdated[];
} = {
  // All the allowed project Roles for the constraint
  allResourceRoles: [
    CoreAPITypes.EUserProjectRoleUpdated.admin,
    CoreAPITypes.EUserProjectRoleUpdated.editor,
    CoreAPITypes.EUserProjectRoleUpdated.viewer,
  ],

  // All the allowed Company Roles for the constraint
  allCompanyRoles: [
    CoreAPITypes.EUserCompanyRoleUpdated.enterpriseAdmin,
    CoreAPITypes.EUserCompanyRoleUpdated.enterpriseViewer,
    CoreAPITypes.EUserCompanyRoleUpdated.groupManager,
    CoreAPITypes.EUserCompanyRoleUpdated.projectManager,
    CoreAPITypes.EUserCompanyRoleUpdated.member,
  ],
};

/**
 * Returns the allowed constraints based upon the subjectType
 */
const AllowedConstraints = {
  // All the allowed constraints for project plans
  get forProjectPlans(): AdminAPITypes.EConstraintType[] {
    return [
      AdminAPITypes.EConstraintType.waypoint,
      AdminAPITypes.EConstraintType.timetravelOnWaypoint,
    ];
  },

  /** All the allowed constraints for company plans */
  get forCompanyPlans(): AdminAPITypes.EConstraintType[] {
    return [
      AdminAPITypes.EConstraintType.projectArea,
      AdminAPITypes.EConstraintType.projectCount,
      AdminAPITypes.EConstraintType.userCount,
      AdminAPITypes.EConstraintType.storage,
      AdminAPITypes.EConstraintType.userRole,
    ];
  },
};
