import React from "react";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { ContentLayout } from "pages/content-layout";
import { ProjectInfoFooter } from "components/footers";
import { ProjectsTable } from "components/tables/projects-table";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { Grid } from "@mui/material";
import { useProjectsOfSubjectType } from "utils/hooks/use-projects-of-subject-type";
import { LoadingScreen } from "components/loading-screen";

interface IProps {
  /** ID of the owner that the projects belong to */
  ownerId: APITypes.CompanyId;
}

/**
 * Shows the projects that belong to the provided owner
 * Fetches the owner's project and shows a message if the owner has no project
 */
export function ProjectsInfo({ ownerId }: IProps): JSX.Element {
  const { handleErrorWithDialog } = useErrorHandler();

  const [projects, isFetchingProjects] = useProjectsOfSubjectType(
    AdminAPITypes.ESubjectType.company,
    ownerId,
    handleErrorWithDialog
  );

  return (
    <ContentLayout
      footer={
        <ProjectInfoFooter
          projects={projects}
          subjectId={ownerId}
          subjectType={AdminAPITypes.ESubjectType.company}
        />
      }
    >
      <LoadingScreen
        isFetching={isFetchingProjects}
        data={projects.length}
        errorContent={<NoProjectMessage />}
      >
        <ProjectsTable projects={projects} />
      </LoadingScreen>
    </ContentLayout>
  );
}

/**
 * Showing a message as a paragraph describing that there is no projects to show
 */
function NoProjectMessage(): JSX.Element {
  return (
    <Grid container justifyContent="center" sx={{ padding: "20px" }}>
      <Grid item>
        <p>This company does not have projects yet.</p>
      </Grid>
    </Grid>
  );
}
