import { PageNotFound } from "pages/page-not-found";
import React, { PropsWithChildren } from "react";
import { HBSpinner } from "./hb-customs/hb-spinner";

interface IProps {
  /** Expresses whether the data is currently fetched or not */
  isFetching: boolean;

  /** Content to show to the user when the data is not available */
  errorContent?: JSX.Element;

  /** The data that is fetched */
  data?: unknown;
}

/**
 * Returns spinner while data is fetching, an error content if data is not available
 * and the children if fetching is finished and data is truthy
 *
 * @param data has a default truthy value in order to not show onError component if it is not provided
 */
export function LoadingScreen({
  isFetching,
  errorContent = <PageNotFound />,
  data = [],
  children,
}: PropsWithChildren<IProps>): JSX.Element {
  if (isFetching) {
    return <HBSpinner />;
  }

  if (!data) {
    return errorContent;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
  return <>{children}</>;
}
