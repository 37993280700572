import { configureStore } from "@reduxjs/toolkit";
import { AppDispatch } from "./store-helper";
import { productsReducer, resetProductsState } from "./products/products-slice";

export const rootReducer = {
  // When adding a new reducer here also add the corresponding reset action to the "resetStore" function.
  products: productsReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

/**
 * Resets the store. Dispatches all reducers reset actions. It can be called from any component.
 * For every new store slice added it's corresponding reset action must be added here.
 *
 * @param dispatch AppDispatch
 */
export function resetStore(dispatch: AppDispatch): void {
  dispatch(resetProductsState());
}
