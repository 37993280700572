import React, { useContext } from "react";
import { ErrorHandlingFunction } from "./error-handling-context-types";

interface IErrorHandlingContext {
  /** Use to show a error dialog */
  handleErrorWithDialog: ErrorHandlingFunction;

  /** Use to show a snackbar with an error message */
  handleErrorWithSnackbar: ErrorHandlingFunction;

  /** Use to unmount all components within the closest error boundary and show an error page instead */
  handleErrorWithPage: ErrorHandlingFunction;
}

export const ErrorHandlingContext =
  React.createContext<IErrorHandlingContext | null>(null);

/**
 * Hook to get access to error-handling functions.
 * Must be used within an ErrorBoundary.
 */
export function useErrorHandler(): IErrorHandlingContext {
  const context = useContext(ErrorHandlingContext);

  if (!context) {
    throw new Error(
      "useErrorHandler() has to be used within a ErrorHandlingContext."
    );
  }

  return context;
}
