import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { useHistory } from "react-router-dom";
import { runtimeConfig } from "runtime-config";
import { SubjectEntityIdTypes, SubjectEntityTypes } from "utils/utility-types";
import { AccountRoute } from "./account/account-routes";
import { CompanyRoute } from "./company/company-routes";
import { ProjectRoute } from "./project/project-routes";

interface IProjectCreationForAccount {
  /** The type of subject to create a project for */
  subjectType: AdminAPITypes.ESubjectType.user;

  /** The ID of the account in which the project is created for */
  accountId: APITypes.UserIdentity;
}

interface IProjectCreationForCompany {
  /** The type of subject to create a project for */
  subjectType: AdminAPITypes.ESubjectType.company;

  /** The ID of the company in which the project is created for */
  companyId: APITypes.CompanyId;

  /** The ID of the group in which the project is created for */
  groupId: APITypes.GroupId;
}

/**
 * Return type of useNavigation hook
 * Consist of different functions each navigating to a page in Admin Panel
 */
interface IUseNavigation {
  navigateToPlanCreation(
    subjectType: SubjectEntityTypes,
    subjectId: SubjectEntityIdTypes
  ): void;
  navigateToProjectCreation(
    subject: IProjectCreationForAccount | IProjectCreationForCompany
  ): void;
  navigateToProfilePage(
    subjectType: AdminAPITypes.ESubjectType,
    subjectId: SubjectEntityIdTypes
  ): void;
  navigateToWorkspacePage(): void;
}

/**
 * A custom hook that calls the history and wraps different navigation functions
 * Returns functions that can be called to navigate to different places
 */
export function useNavigation(): IUseNavigation {
  const history = useHistory();

  /**
   * Navigate to the plan creation page for the different entities
   */
  function navigateToPlanCreation(
    subjectType: SubjectEntityTypes,
    subjectId: SubjectEntityIdTypes
  ): void {
    switch (subjectType) {
      case AdminAPITypes.ESubjectType.company: {
        history.push(CompanyRoute.forAddPlan(subjectId));
        break;
      }
      case AdminAPITypes.ESubjectType.device: {
        history.push(CompanyRoute.forAddDevicePlan(subjectId));
        break;
      }
      case AdminAPITypes.ESubjectType.project: {
        history.push(ProjectRoute.forAddPlan(subjectId));
        break;
      }

      default: {
        throw new Error(
          `navigateToPlanCreation: Unsupported subjectType ${subjectType}`
        );
      }
    }
  }

  /**
   * Navigate to the project creation page for different entities
   */
  function navigateToProjectCreation(
    subject: IProjectCreationForAccount | IProjectCreationForCompany
  ): void {
    switch (subject.subjectType) {
      case AdminAPITypes.ESubjectType.user:
        history.push(AccountRoute.forAddProject(subject.accountId));
        break;
      case AdminAPITypes.ESubjectType.company:
        history.push(
          CompanyRoute.forAddProject(subject.companyId, subject.groupId)
        );
        break;
    }
  }

  /**
   * Navigate to the profile page for different entities
   */
  function navigateToProfilePage(
    profileType: AdminAPITypes.ESubjectType,
    profileId: SubjectEntityIdTypes
  ): void {
    switch (profileType) {
      case AdminAPITypes.ESubjectType.project: {
        history.push(ProjectRoute.forProfile(profileId));
        break;
      }
      case AdminAPITypes.ESubjectType.company: {
        history.push(CompanyRoute.forProfile(profileId));
        break;
      }
      case AdminAPITypes.ESubjectType.device: {
        history.push(CompanyRoute.forProfile(profileId));
        break;
      }
      case AdminAPITypes.ESubjectType.user: {
        history.push(AccountRoute.forProfile(profileId as string));
        break;
      }
      default: {
        // eslint-disable-next-line no-console -- No need to show this to user, even though worth share it in console
        console.warn(
          `navigateToProfilePage not implemented for ${profileType} yet`
        );
        break;
      }
    }
  }

  /**
   * Navigate to workspace page by making a new http request
   */
  function navigateToWorkspacePage(): void {
    window.location.href = runtimeConfig.urls.entryPageUrl;
  }

  return {
    navigateToPlanCreation,
    navigateToProjectCreation,
    navigateToProfilePage,
    navigateToWorkspacePage,
  };
}
