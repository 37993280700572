import { APITypes } from "@stellar/api-logic";
import { generatePath } from "react-router-dom";

export const CompanyRouteParam = "companyId";
export const DeviceRouteParam = "deviceId";
export const GroupRouteParam = "groupId";

const CompanyRouteFragments = {
  root: "/company",
  company: `/:${CompanyRouteParam}`,
  addPlan: "/add-plan",
  addDevicePlan: "/add-device-plan",
  addProject: `/group/:${GroupRouteParam}/add-project`,
};

export const CompanyRouterRoot = `${CompanyRouteFragments.root}`;
export const CompanyProfileRoute = `${CompanyRouterRoot}${CompanyRouteFragments.company}`;
export const CompanyAddPlanRoute = `${CompanyProfileRoute}${CompanyRouteFragments.addPlan}`;
export const CompanyAddProjectRoute = `${CompanyProfileRoute}${CompanyRouteFragments.addProject}`;
export const CompanyAddDevicePlanRoute = `${CompanyProfileRoute}${CompanyRouteFragments.addDevicePlan}`;

export const CompanyRoute = {
  /**
   * Provides the path for the profile page of a specific company
   */
  forProfile(companyId: APITypes.CompanyId): string {
    return generatePath(CompanyProfileRoute, { companyId });
  },

  /**
   * Provides the path for the plan creation page for a specific company
   */
  forAddPlan(companyId: APITypes.CompanyId): string {
    return generatePath(CompanyAddPlanRoute, { companyId });
  },

  /**
   * Provides the path for the device plan creation page for a specific company
   */
  forAddDevicePlan(companyId: APITypes.CompanyId): string {
    return generatePath(CompanyAddDevicePlanRoute, { companyId });
  },

  /**
   * Provides the path for the project creation page for a specific company and group of it
   */
  forAddProject(
    companyId: APITypes.CompanyId,
    groupId: APITypes.GroupId
  ): string {
    return generatePath(CompanyAddProjectRoute, { companyId, groupId });
  },
};
