import { Box, Grid } from "@mui/material";
import { ITabComponent, TabComponent } from "components/tab-component";
import React, { ReactNode } from "react";

interface IContentLayoutProps {
  /** The breadcrumb to show in the page */
  breadcrumbs?: ReactNode;

  /** The header to show in the page */
  header?: ReactNode;

  /** The tabs and their content to show in the page */
  tabs?: ITabComponent[];

  /** Zero-based index of the tab that should be opened/selected per default */
  defaultTabIndex?: number;

  /** The footer to show in the page */
  footer?: ReactNode;
}

/**
 * Manages the placement of breadcrumb, headers, tabs, footer etc.
 */
export function ContentLayout({
  breadcrumbs,
  header,
  tabs,
  defaultTabIndex,
  footer,
  children,
}: React.PropsWithChildren<IContentLayoutProps>): JSX.Element {
  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element */}
      {!!breadcrumbs && <>{breadcrumbs}</>}

      {!!header && <header>{header}</header>}

      {!!tabs && <TabComponent tabs={tabs} defaultTabIndex={defaultTabIndex} />}

      {!!children && (
        <Box
          component="main"
          sx={{ flexBasis: 0, flexGrow: 1, overflowY: "auto" }}
        >
          {children}
        </Box>
      )}

      {!!footer && (
        <Box component="footer" sx={{ justifySelf: "flex-end", width: "100%" }}>
          {footer}
        </Box>
      )}
    </Grid>
  );
}
