import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { isRecurringPlan } from "./type-guards";

/**
 * Helper functions to access common data from different kind of plans.
 */
export const PlanDataExtractor = {
  /**
   * Returns the endDate of plans that have an actual endDate
   * Returns undefined for recurring plan
   */
  getEndDate(plan: AdminAPITypes.IPlan): APITypes.Iso8601Date | undefined {
    return plan.endDate;
  },

  Payment: {
    /**
     * Returns the price per unit of recurring plan.
     * Returns 0 if the plan is unpaid
     */
    getPrice(plan: AdminAPITypes.IPlan): string {
      if (isRecurringPlan(plan)) {
        return plan.payment.recurring.priceLabel;
      } else {
        return "";
      }
    },

    /**
     * Returns the amount of days until the payment of a recurring plan is due.
     */
    getDaysUntilDue(
      recurringPlan: AdminAPITypes.IRecurringPlan
    ): number | undefined {
      return recurringPlan.payment.recurring.daysUntilDue;
    },

    /**
     * Returns the subscription id of a recurring plan.
     */
    getSubscriptionId(
      recurringPlan: AdminAPITypes.IRecurringPlan
    ): AdminAPITypes.PaymentProviderCustomerId {
      return recurringPlan.payment.recurring.id;
    },

    /**
     * Returns the customer of a recurring plan.
     */
    getPaymentProviderCustomer(
      recurringPlan: AdminAPITypes.IRecurringPlan
    ): AdminAPITypes.IPaymentProviderBaseCustomer {
      return recurringPlan.payment.recurring.customer;
    },
  },
  Payload: {
    /**
     * Transforms a given plan into UpdatePlanPayload
     * The returned object can be adjusted and send to the API to update the plan
     */
    extractUpdatePlanPayloadFromPlan(
      plan: AdminAPITypes.IPlan
    ): AdminAPITypes.UpdatePlanPayload {
      const featureBundleIds = plan.featureBundles.map(
        (featureBundle) => featureBundle.id
      );

      const featureIds = plan.features.map((features) => features.id);
      const constraints: AdminAPITypes.CreateConstraintPayload[] =
        plan.constraints.map((constraint) => {
          const roles = {
            company: constraint.data.roles?.company ?? [],
            resource: constraint.data.roles?.resource ?? [],
          };
          return {
            type: constraint.type,
            data: {
              max: constraint.data.max,
              current: constraint.data.current,
              ...(roles.company.length > 0 || roles.resource.length > 0
                ? { roles }
                : {}),
            },
            createdAt: constraint.createdAt,
            modifiedAt: constraint.modifiedAt,
          };
        });
      return {
        label: plan.label,
        description: plan.description,
        featureBundles: featureBundleIds,
        features: featureIds,
        constraints,
      };
    },
  },
};
