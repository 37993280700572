import { IconButton, Snackbar, SnackbarProps } from "@mui/material";
import { Clear } from "@mui/icons-material";
import React from "react";
import { Color } from "utils/styles/default-colors";

interface IProps extends Pick<SnackbarProps, "anchorOrigin"> {
  isOpen: boolean;
  onClose(): void;
}

export function HBSnackbar({
  isOpen,
  onClose: handleClose,
  children,
  anchorOrigin,
}: React.PropsWithChildren<IProps>): JSX.Element {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={5000}
      message={children}
      action={
        <IconButton
          sx={{ color: Color.white }}
          onClick={handleClose}
          size="large"
        >
          <Clear />
        </IconButton>
      }
    />
  );
}
