import { Box, BoxProps, Tooltip } from "@mui/material";
import { HBButton } from "components/hb-customs/hb-button";
import React, { ReactNode } from "react";

interface IFormContainerProps {
  /** The text to be shown on cancel button */
  cancelButtonText?: string;

  /** Callback triggers when the cancel button is clicked */
  handleCancelClicked?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void;

  /** The text to be shown on create button */
  createButtonText?: string;

  /** Callback triggers when the create button is clicked */
  handleCreateClicked?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void;

  /** Whether or not the create button is disabled or not */
  shouldCreateButtonBeDisabled?: boolean;

  /** Tooltip shown over the create button */
  createButtonTooltip?: ReactNode;

  /** Optional sx properties to override the main div style. */
  sx?: BoxProps["sx"];
}

export function FormContainer({
  children,
  cancelButtonText = "Cancel",
  handleCancelClicked,
  createButtonText = "Create",
  handleCreateClicked,
  shouldCreateButtonBeDisabled,
  createButtonTooltip = "",
  sx = {},
}: React.PropsWithChildren<IFormContainerProps>): JSX.Element {
  return (
    <Box sx={sx}>
      {children}

      {/* form controls */}
      <Box
        sx={{
          margin: "56px 0px",
          "& > *": {
            mr: "10px",
          },
        }}
      >
        <HBButton
          sx={{ p: "7px 50px", mr: "10px" }}
          onClick={handleCancelClicked}
          type="secondary"
        >
          {cancelButtonText}
        </HBButton>

        {/* Add a span-element around the button inside of the Tooltip, */}
        {/* because when the button is disabled it can't fire an event */}
        {/* that the Tooltip component needs */}
        <Tooltip title={createButtonTooltip || ""}>
          <span>
            <HBButton
              sx={{ p: "7px 50px" }}
              onClick={handleCreateClicked}
              isDisabled={shouldCreateButtonBeDisabled}
            >
              {createButtonText}
            </HBButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}
