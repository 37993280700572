import { Box } from "@mui/material";
import { HBRadioGroup } from "components/hb-customs/hb-radio-group";
import React from "react";
import { Color } from "utils/styles/default-colors";

interface IStandaloneProjectToggleProps {
  /** State of the toggle */
  isStandalone: boolean;

  /** Called when the toggle is changed */
  setIsStandalone(isStandalone: boolean): void;

  /** The label to show for the toggle */
  label?: string;
}

export function StandaloneProjectToggle({
  isStandalone,
  setIsStandalone,
  label = "Standalone?",
}: IStandaloneProjectToggleProps): JSX.Element {
  const standaloneProjectHelpText = isStandalone
    ? "This project won't use up any project slot or sq ft of the company."
    : "This project will count towards the project/sq ft limit of the company.";

  return (
    <>
      <HBRadioGroup
        isRequired
        isFullWidth
        label={label}
        value={isStandalone}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setIsStandalone(event.target.value === "true");
        }}
        radioOptions={{
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Radio Button label
          No: false,
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Radio Button label
          Yes: true,
        }}
      />

      <Box component="p" sx={{ color: Color.orange }}>
        {standaloneProjectHelpText}
      </Box>
    </>
  );
}
