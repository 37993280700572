import { IRouterParams } from "router/route-params";
import React from "react";
import { useParams } from "react-router-dom";
import { ContentLayout } from "../content-layout";
import { AccountBreadcrumbs } from "components/breadcrumbs/account-breadcrumbs";
import { AccountContext } from "./account-context";
import { AccountProfileHeader } from "./account-profile-header";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { useAccount } from "utils/hooks/use-account";
import { AccountInfo } from "./tab-contents/account-info";
import { AccountActions } from "./tab-contents/account-actions";
import { PlanInfo } from "pages/plan-info";
import { AdminAPITypes } from "@stellar/api-logic";
import { LoadingScreen } from "components/loading-screen";

/**
 * Fetch account data and display the data in account profile page
 */
export function AccountProfilePage(): JSX.Element {
  const { accountId } = useParams<IRouterParams>();

  const { handleErrorWithPage } = useErrorHandler();

  const [account, isFetchingData] = useAccount(accountId, handleErrorWithPage);

  return (
    <LoadingScreen isFetching={isFetchingData} data={account}>
      {account && (
        <AccountContext.Provider value={account}>
          <ContentLayout
            breadcrumbs={<AccountBreadcrumbs account={account} />}
            header={<AccountProfileHeader />}
            tabs={[
              {
                label: "User Information",
                content: <AccountInfo userId={accountId} />,
              },
              {
                label: "Plans",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.user}
                    subjectId={accountId}
                  />
                ),
              },
              {
                label: "Account",
                content: <AccountActions userId={accountId} />,
              },
            ]}
          />
        </AccountContext.Provider>
      )}
    </LoadingScreen>
  );
}
