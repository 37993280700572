import { AdminAPITypes } from "@stellar/api-logic";
import { getAccountDisplayName } from "./data-display";

export const Clipboard = {
  copyToClipboard(clipboardString: string): void {
    navigator.clipboard.writeText(clipboardString);
  },

  copyCompanyInfo(company: AdminAPITypes.IAdmCompany): void {
    const clipboardString = `***Company Details***
**Company ID:** \`${company.id}\`
**Name:** ${company.name}
[Admin Panel Link](${window.location.href})`;

    Clipboard.copyToClipboard(clipboardString);
  },

  copyProjectInfo(project: AdminAPITypes.IAdmProject): void {
    const clipboardString = `***Project Details***
**Project ID:** \`${project.id}\`
**Name:** ${project.name}
[Admin Panel Link](${window.location.href})`;

    Clipboard.copyToClipboard(clipboardString);
  },

  copyAccountInfo(account: AdminAPITypes.IAdmUser): void {
    const clipboardString = `***User Details***
**User ID:** \`${account.identity}\`
**Email:** \`${account.email}\`
**Name:** ${getAccountDisplayName(account)}
[Admin Panel Link](${window.location.href})`;

    Clipboard.copyToClipboard(clipboardString);
  },
};
