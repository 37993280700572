import { AccountProfilePage } from "pages/account-profile/account-profile";
import { CreateProjectForAccount } from "pages/create-project/create-project-for-account";
import { PageNotFound } from "pages/page-not-found";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { AccountAddProjectRoute, AccountProfileRoute } from "./account-routes";

/**
 * Router for navigating all account related pages
 */
export function AccountRouter(): JSX.Element {
  return (
    <Switch>
      <Route exact path={AccountProfileRoute}>
        <AccountProfilePage />
      </Route>

      <Route exact path={AccountAddProjectRoute}>
        <CreateProjectForAccount />
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
