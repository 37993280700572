import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  CustomProductIdentification,
  ProductIdentifier,
} from "store/products/products-slice-helper-types";

/* eslint-disable @typescript-eslint/naming-convention */
/** List of all available features in Admin Panel */
const allAvailableFeatures: Partial<
  Record<APITypes.EUserSubscriptionRole, BaseProduct>
> = {
  [APITypes.EUserSubscriptionRole.globalFaceBlurring]: {
    identifier: APITypes.EUserSubscriptionRole.globalFaceBlurring,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.ft_spotwalk]: {
    identifier: APITypes.EUserSubscriptionRole.ft_spotwalk,
    type: "feature",
    scope: { [AdminAPITypes.ESubjectType.project]: false },
  },

  [APITypes.EUserSubscriptionRole.ft_download_360]: {
    identifier: APITypes.EUserSubscriptionRole.ft_download_360,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.ft_project_evaluation]: {
    identifier: APITypes.EUserSubscriptionRole.ft_project_evaluation,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.projectProgress]: {
    identifier: APITypes.EUserSubscriptionRole.projectProgress,
    type: "feature",
    scope: { [AdminAPITypes.ESubjectType.project]: false },
  },

  [APITypes.EUserSubscriptionRole.insightsTeaser]: {
    identifier: APITypes.EUserSubscriptionRole.insightsTeaser,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.globalVideoMode]: {
    identifier: APITypes.EUserSubscriptionRole.globalVideoMode,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.globalProgressAi]: {
    identifier: APITypes.EUserSubscriptionRole.globalProgressAi,
    type: "feature",
    scope: { [AdminAPITypes.ESubjectType.project]: false },
  },

  [APITypes.EUserSubscriptionRole.globalOpenInSphereViewer]: {
    identifier: APITypes.EUserSubscriptionRole.globalOpenInSphereViewer,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.globalPointCloudSendTo]: {
    identifier: APITypes.EUserSubscriptionRole.globalPointCloudSendTo,
    type: "feature",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [APITypes.EUserSubscriptionRole.sphereDashboard]: {
    identifier: APITypes.EUserSubscriptionRole.sphereDashboard,
    type: "feature",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/** List of all available bundles in Admin Panel */
const allAvailableBundles: Partial<
  Record<AdminAPITypes.EFeatureBundleIdentifier, BaseProduct>
> = {
  [AdminAPITypes.EFeatureBundleIdentifier.dashboard]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.dashboard,
    type: "bundle",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.education]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.education,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: true,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.planFm]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.planFm,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: true,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.trial]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.trial,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.premiumInsights]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.premiumInsights,
    type: "bundle",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.gps]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.gps,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.snapshot]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.snapshot,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.vpm]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.vpm,
    type: "bundle",
    scope: { [AdminAPITypes.ESubjectType.project]: true },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise,
    type: "bundle",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.pointCloud]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.pointCloud,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.cloudProcessingTrial]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.cloudProcessingTrial,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.cad]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.cad,
    type: "bundle",
    scope: {
      [AdminAPITypes.ESubjectType.company]: false,
      [AdminAPITypes.ESubjectType.project]: false,
    },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.flash]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.flash,
    type: "bundle",
    scope: { [AdminAPITypes.ESubjectType.device]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.faroStream]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.faroStream,
    type: "bundle",
    scope: { [AdminAPITypes.ESubjectType.device]: false },
  },
};

/** List of all available subscriptions in Admin Panel */
const allAvailableSubscriptions: Partial<
  Record<AdminAPITypes.EFeatureBundleIdentifier, BaseProduct>
> = {
  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    type: "subscription",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    type: "subscription",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    type: "subscription",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/** List of all available addons in Admin Panel */
const allAvailableAddons: Partial<
  Record<AdminAPITypes.EFeatureBundleIdentifier, BaseProduct>
> = {
  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    type: "addon",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  [AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud]: {
    identifier: AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    type: "addon",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};

/** List of all available custom products in Admin Panel */
export const allAvailableCustomProducts: Record<
  CustomProductIdentification,
  Required<BaseProduct>
> = {
  standard360: {
    identifier: "standard360",
    name: "Sphere XG Standard + 360°",
    description: "Standard Sphere XG subscription with 360° addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  standardPointCloud: {
    identifier: "standardPointCloud",
    name: "Sphere XG Standard + Point Cloud",
    description: "Standard Sphere XG subscription with point cloud addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  pro360: {
    identifier: "pro360",
    name: "Sphere XG Professional + 360°",
    description: "Professional Sphere XG subscription with 360° addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  proPointCloud: {
    identifier: "proPointCloud",
    name: "Sphere XG Professional + Point Cloud",
    description: "Professional Sphere XG subscription with point cloud addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprise360: {
    identifier: "enterprise360",
    name: "Sphere XG Enterprise + 360°",
    description: "Enterprise Sphere XG subscription with 360° addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprisePointCloud: {
    identifier: "enterprisePointCloud",
    name: "Sphere XG Enterprise + Point Cloud",
    description: "Enterprise Sphere XG subscription with point cloud addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },

  enterprise360PointCloud: {
    identifier: "enterprise360PointCloud",
    name: "Sphere XG Enterprise + 360° + Point Cloud",
    description:
      "Enterprise Sphere XG subscription with 360° and point cloud addon",
    type: "custom",
    scope: { [AdminAPITypes.ESubjectType.company]: false },
  },
};
/* eslint-enable */

/** All the possible products in Admin Panel */
export const allProducts: Partial<Record<ProductIdentifier, BaseProduct>> = {
  ...allAvailableFeatures,
  ...allAvailableBundles,
  ...allAvailableSubscriptions,
  ...allAvailableAddons,
  ...allAvailableCustomProducts,
};
