import { Grid } from "@mui/material";
import { HBDialog } from "components/hb-customs/hb-dialog";
import { HBMenuItems } from "components/hb-customs/hb-menu-items";
import { HBSelect } from "components/hb-customs/hb-select";
import { APITypes, CoreAPITypes } from "@stellar/api-logic";
import React, { useState } from "react";

interface ISelectGroupDialogProps {
  /**
   * List of groups that the user can select from.
   * Using unknown because the type of the role attribute does not matter in the component.
   */
  groups: CoreAPITypes.IGroup<unknown>[];

  /** Function to execute when the user just closes the dialog (i.e. they clicked on "close") */
  onClose(): void;

  /** Function to execute when the user confirms the selected group */
  onConfirm(groupId: APITypes.GroupId): void;
}

/**
 * Dialog that allows to select a group from a dropdown
 * Will be displayed if the user tries to create a project for a user account which is managing multiple groups
 */
export function SelectGroupDialog({
  groups,
  onClose,
  onConfirm,
}: ISelectGroupDialogProps): JSX.Element {
  const [selectedGroupId, setSelectedGroupId] = useState<APITypes.GroupId>("");

  return (
    <HBDialog
      onClose={onClose}
      closeButtonText="Cancel"
      onConfirm={() => onConfirm(selectedGroupId)}
      confirmButtonText="Create project in selected group"
      shouldDisableConfirmButton={!selectedGroupId}
      title="Please select a group"
    >
      <Grid>
        <p>
          The user appears to be the manager of multiple groups. Please select
          the group in which the project should be created.
        </p>

        <HBSelect
          label="Group"
          value={selectedGroupId}
          isFullWidth
          onChange={(event: React.ChangeEvent<{ value: APITypes.GroupId }>) => {
            setSelectedGroupId(event.target.value);
          }}
        >
          {HBMenuItems({
            menuItemInputs: groups.map(({ name, id }) => ({
              label: name,
              value: id,
            })),
          })}
        </HBSelect>
      </Grid>
    </HBDialog>
  );
}
