import React from "react";
import { CircularProgress, Grid } from "@mui/material";

/**
 * Comes without additional styles and should be used when you need to inline
 * a spinning loading circle together with text. In contrast to HBSpinner, which
 * is always trying to force itself to be "as big as possible" and centered
 */
export function HBSpinnerInline(): JSX.Element {
  return <CircularProgress size={15} />;
}

/** Shows a spinner in the middle of the page */
export function HBSpinner(): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      sx={{ width: "100%", height: "100%", minHeight: "80px" }}
    >
      <CircularProgress />
    </Grid>
  );
}
