import React from "react";
import { Link } from "react-router-dom";
import { RootRoute } from "router/root-routes";
import { AccountRoute } from "router/account/account-routes";
import { CompanyRoute } from "router/company/company-routes";
import { ProjectRoute } from "router/project/project-routes";
import { APITypes } from "@stellar/api-logic";
import { ReactChildrenOnly } from "utils/utility-types";

/**
 * Provide a link to the homepage from anywhere
 */
export function HomeLink({ children }: ReactChildrenOnly): JSX.Element {
  return <Link to={RootRoute}>{children}</Link>;
}

interface IAccountLinkProps {
  /** ID of the user account that the user will be redirected when they click on the link */
  accountId: APITypes.UserId;
}

/**
 * Provide a link to an account from anywhere
 */
export function AccountLink({
  accountId,
  children,
}: React.PropsWithChildren<IAccountLinkProps>): JSX.Element {
  return <Link to={AccountRoute.forProfile(accountId)}>{children}</Link>;
}

interface ICompanyLinkProps {
  // ID of the company that the user will be redirected when they click on the link
  companyId: string | number;
}

/**
 * Provide a link to a company from anywhere
 */
export function CompanyLink({
  companyId,
  children,
}: React.PropsWithChildren<ICompanyLinkProps>): JSX.Element {
  return <Link to={CompanyRoute.forProfile(companyId)}>{children}</Link>;
}

interface IProjectLinkProps {
  /** ID of the project that the user will be redirected when they click on the link */
  projectId: APITypes.ProjectId;
}

/**
 * Provide a link to a project from anywhere
 */
export function ProjectLink({
  projectId,
  children,
}: React.PropsWithChildren<IProjectLinkProps>): JSX.Element {
  return <Link to={ProjectRoute.forProfile(projectId)}>{children}</Link>;
}
