import { useTheme } from "@mui/material";
import React from "react";
import { Add, GetApp } from "@mui/icons-material";
import { HBButton, IHBButtonProps } from "./hb-customs/hb-button";
import { ISubjectInfo } from "utils/utility-types";
import { useNavigation } from "router/use-navigation";
import { AdminAPITypes } from "@stellar/api-logic";

type ClipboardButtonProps = Pick<IHBButtonProps, "onClick">;

export function ClipboardButton({
  onClick: handleClick,
}: ClipboardButtonProps): JSX.Element {
  return (
    <HBButton sx={{ width: "251px", height: "40px" }} onClick={handleClick}>
      Copy to Clipboard
    </HBButton>
  );
}

type AddButtonProps = Pick<IHBButtonProps, "onClick" | "isDisabled" | "type">;

export function AddButton({
  children,
  onClick,
  isDisabled,
  type,
}: React.PropsWithChildren<AddButtonProps>): JSX.Element {
  const theme = useTheme();

  return (
    <HBButton
      startIcon={<Add sx={{ color: theme.palette.info.main }} />}
      {...{
        onClick,
        isDisabled,
        type,
      }}
    >
      {children}
    </HBButton>
  );
}

type CSVExportButtonProps = Pick<IHBButtonProps, "onClick" | "isDisabled">;

/**
 * Adds a button with download icon used for exporting csv data
 */
export function CSVExportButton({
  onClick: handleClick,
  isDisabled,
}: CSVExportButtonProps): JSX.Element {
  const theme = useTheme();

  return (
    <HBButton
      startIcon={<GetApp sx={{ color: theme.palette.info.main }} />}
      onClick={handleClick}
      isDisabled={isDisabled}
    >
      Export as CSV
    </HBButton>
  );
}

interface ICreateProjectButtonProps extends ISubjectInfo {
  /** Disable add button when it is true */
  isDisabled?: boolean;
}

/**
 * A button which navigates to the project creation page based on the subject type and id
 */
export function CreateProjectButton({
  isDisabled,
  subjectId,
  subjectType,
}: ICreateProjectButtonProps): JSX.Element {
  const { navigateToProjectCreation } = useNavigation();

  function onClickHandler(): void {
    switch (subjectType) {
      case AdminAPITypes.ESubjectType.user:
        navigateToProjectCreation({
          subjectType,
          accountId: subjectId.toString(),
        });
        break;
      default:
        throw new Error(
          `CreateProjectButton: Unsupported subjectType ${subjectType}`
        );
    }
  }

  return (
    <AddButton onClick={onClickHandler} isDisabled={isDisabled}>
      Create Project
    </AddButton>
  );
}
