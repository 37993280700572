import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { HBButton } from "components/hb-customs/hb-button";
import React, { ReactNode } from "react";
import { HBTooltip } from "./hb-tooltips";

interface IHBDialogProps {
  /** Callback fired when the user presses the close button, or the component requests to be closed */
  onClose(): void;

  /** Text to put into the close button, defaults to "Close" */
  closeButtonText?: string;

  /**
   * Callback fired when the user presses the confirm button
   * When confirm callback is not provided, the button will not be rendered
   */
  onConfirm?(): void;

  /** Text to put into the confirm button, defaults to "Confirm" */
  confirmButtonText?: string;

  /**
   * Whether the confirm button should be disabled or not
   * Useful in case the user has to do some action before they can confirm (e.g. make a selection)
   */
  shouldDisableConfirmButton?: boolean;

  /** Optional text to show as tooltip when the confirm button is not disabled */
  disabledConfirmationButtonTooltip?: string;

  /** Component that will be rendered as title */
  title: ReactNode;

  /** If true, the dialog stretched. See https://material-ui.com/api/dialog/ */
  shouldBeFullWidth?: boolean;
}

/**
 * A custom dialog component that uses the HBButton component for the buttons
 */
export function HBDialog({
  onClose: handleClose,
  closeButtonText = "Close",
  onConfirm: handleConfirm,
  confirmButtonText = "Confirm",
  shouldDisableConfirmButton = false,
  disabledConfirmationButtonTooltip = "",
  shouldBeFullWidth = false,
  title,
  children,
}: React.PropsWithChildren<IHBDialogProps>): JSX.Element {
  return (
    <Dialog onClose={handleClose} open fullWidth={shouldBeFullWidth}>
      <DialogTitle>
        <Grid item container direction="row" alignItems="center">
          {title}
        </Grid>
      </DialogTitle>

      <DialogContent sx={{ pt: "8px !important" }}>{children}</DialogContent>

      <DialogActions>
        <HBButton onClick={handleClose} type="secondary">
          {closeButtonText}
        </HBButton>

        {handleConfirm && (
          <HBTooltip
            title={
              shouldDisableConfirmButton && disabledConfirmationButtonTooltip
            }
          >
            <HBButton
              onClick={handleConfirm}
              isDisabled={shouldDisableConfirmButton}
            >
              {confirmButtonText}
            </HBButton>
          </HBTooltip>
        )}
      </DialogActions>
    </Dialog>
  );
}
