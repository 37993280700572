import React, { PropsWithChildren } from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface IProps
  extends Pick<TextFieldProps, "label" | "variant" | "value" | "onChange"> {
  /** If `true`, the `input` element will be disabled. */
  isDisabled?: boolean;

  /** If `true`, the input will take up the full width of its container. */
  isFullWidth?: boolean;

  /** If `true`, the label is displayed as required and the `input` element` will be required. */
  isRequired?: boolean;
}

export function HBSelect({
  children,
  label,
  variant,
  value,
  isFullWidth,
  isDisabled,
  isRequired,
  onChange,
}: PropsWithChildren<IProps>): JSX.Element {
  return (
    <TextField
      required={isRequired}
      select
      InputProps={{
        sx: { height: "100%" },
      }}
      disabled={isDisabled}
      fullWidth={isFullWidth}
      {...{ label, variant, value, onChange }}
    >
      {children}
    </TextField>
  );
}
