import React from "react";
import { Box, Grid } from "@mui/material";
import { Color } from "utils/styles/default-colors";
import { HBHelpTooltip } from "components/hb-customs/hb-tooltips";

/** The explanation of plan status in an HBToolTip is wrapped with a HBHelpTooltip */
export function PlanStatusTableHeader(): JSX.Element {
  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item>Status</Grid>
      <Grid item>
        <HBHelpTooltip>
          <TooltipContent />
        </HBHelpTooltip>
      </Grid>
    </Grid>
  );
}

/** Explains each plan status and set a background color to distinguish between them */
function TooltipContent(): JSX.Element {
  return (
    <>
      <Box sx={{ mb: "15px", ml: "32px", mt: "26px" }}>
        Plans can have the following status:
      </Box>
      <PlanStatusExplanationEntry
        label="Active"
        explanation="Current date is within Plan's start and end date, and all constraints are met."
        backgroundColor={Color.planStatusActive}
      />
      <PlanStatusExplanationEntry
        label="Invalid"
        explanation="Current date is within Plan's start and end date, but some constraints are violated."
        backgroundColor={Color.planStatusInvalid}
      />
      <PlanStatusExplanationEntry
        label="Inactive"
        explanation="Current date is not within Plan's start and end date."
        backgroundColor={Color.planStatusInactive}
      />
      <PlanStatusExplanationEntry
        label="Deactivated"
        explanation="Plan has no practical impact and exists only for documentary purposes."
        backgroundColor={Color.planStatusDeactivated}
      />
    </>
  );
}

interface IPlanStatusExplanationEntryProps {
  /** The label that should be displayed on the left of the explanation. Intended to display a plan status */
  label: string;

  /** The text explaining the subject displayed in the label. Will be displayed next to the label. */
  explanation: string;

  /** The background color of this label-explanation-pair. Intended to match the status in the label. */
  backgroundColor?: string;
}

/** Style the label and explanation with a background color */
function PlanStatusExplanationEntry({
  label,
  explanation,
  backgroundColor = "",
}: IPlanStatusExplanationEntryProps): JSX.Element {
  return (
    <Grid
      container
      sx={{
        padding: "13px 35px",
        height: "63px",
        backgroundColor: backgroundColor,
      }}
    >
      <Grid item xs={3} sx={{ fontWeight: 700 }}>
        {label}
      </Grid>
      <Grid item xs={9}>
        {explanation}
      </Grid>
    </Grid>
  );
}
