import React, { ComponentProps, FormEvent, useEffect, useState } from "react";
import { TextField } from "@mui/material";

interface IProps
  extends Pick<ComponentProps<typeof TextField>, "variant" | "label"> {
  /** null indicates an invalid/unset value */
  value: number | null;
  onChange?(newValue: number | null): void;

  /** minimum valid value (inclusive) */
  minValue?: number;

  /** maximum valid value (inclusive) */
  maxValue?: number;

  /** Only allow integer numbers to be input */
  isIntegerValue?: boolean;

  /** If `true`, the `input` element will be disabled. */
  isDisabled?: boolean;

  /** If `true`, the input will take up the full width of its container. */
  isFullWidth?: boolean;

  /** If `true`, the label is displayed as required and the `input` element` will be required. */
  isRequired?: boolean;
}

export function HBNumberInput({
  value,
  onChange,
  isIntegerValue,
  minValue = Number.NEGATIVE_INFINITY,
  maxValue = Number.POSITIVE_INFINITY,
  variant = "outlined",
  label,
  isFullWidth,
  isRequired,
  isDisabled,
}: IProps): JSX.Element {
  const [inputError, setInputError] =
    useState<{
      message: string;
      wrongValue: number | null;
    } | null>(null);

  function handleInvalidInput(
    message: string,
    wrongValue: number | null
  ): void {
    setInputError({
      message,
      wrongValue,
    });
    if (onChange) {
      onChange(null);
    }
  }

  function handleTextFieldInput(event: FormEvent<HTMLInputElement>): void {
    const numberInput = event.target as HTMLInputElement;

    const parsedInput = isIntegerValue
      ? parseInt(numberInput.value, 10)
      : parseFloat(numberInput.value);

    if (parsedInput < minValue) {
      handleInvalidInput(`${label} must be at least ${minValue}.`, parsedInput);
      return;
    } else if (parsedInput > maxValue) {
      handleInvalidInput(`${label} must be at most ${maxValue}.`, parsedInput);
      return;
    } else {
      setInputError(null);
    }

    if (isNaN(parsedInput)) {
      if (isRequired || !numberInput.validity.valid) {
        handleInvalidInput("Please enter a valid number", null);
        return;
      } else {
        setInputError(null);
      }
    }

    if (onChange && parsedInput !== value) {
      onChange(isNaN(parsedInput) ? null : parsedInput);
    }
  }

  // Effect to automatically select a single unambiguous number if maxValue === minValue
  useEffect(() => {
    if (minValue === maxValue && value !== minValue && onChange) {
      onChange(minValue);
      setInputError(null);
    }
  }, [value, minValue, maxValue, onChange]);

  // Disable the input field if only a single value is allowed
  isDisabled = isDisabled || maxValue === minValue;

  // Show the wrong value to allow for corrections
  const displayValue = inputError ? inputError.wrongValue : value;

  return (
    <TextField
      type="number"
      value={displayValue ?? ""}
      onInput={handleTextFieldInput}
      error={inputError !== null}
      helperText={inputError?.message}
      fullWidth={isFullWidth}
      required={isRequired}
      disabled={isDisabled}
      {...{ variant, label }}
    />
  );
}
