import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { BreadcrumbsData } from "./breadcrumbs-data";
import { IBreadcrumbsData } from "./breadcrumbs-types";
import { HBBreadcrumbs } from "./hb-breadcrumbs";

interface IProjectBreadcrumbsProps {
  /** Object represents current project to generate breadcrumb out of it */
  project: AdminAPITypes.IAdmProject;
}

/**
 * Display breadcrumbs for a project *
 * Will include the company for company projects, and the user for user projects
 */
export function ProjectBreadcrumbs({
  project,
}: IProjectBreadcrumbsProps): JSX.Element {
  const breadcrumbsData: IBreadcrumbsData[] = [];
  breadcrumbsData.push(
    BreadcrumbsData.forCompany(
      (project.context as AdminAPITypes.IAdmProjectCompanyContext).company
    )
  );
  breadcrumbsData.push(BreadcrumbsData.forProject(project));
  return <HBBreadcrumbs {...{ breadcrumbsData }} />;
}
