import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { IPlanCreationContext } from "./plan-creation-context-types";
import {
  BaseProduct,
  CustomProductIdentification,
} from "store/products/products-slice-helper-types";
import {
  isProductCustomFeature,
  isProductSingleFeature,
} from "utils/product/product-utils";
import { customProductIdToBundleIdentification } from "utils/product/product-utils";
import { removeCurrentFieldFromConstraints } from "utils/constraint";

/** Convert the PlanCreationContext into an AdminAPITypes.CreatePlanPayload object */
export function createPlanCreationPayload(
  planCreationContext: IPlanCreationContext,
  selectedProduct: BaseProduct | null,
  selectedAddon: BaseProduct | null
): AdminAPITypes.CreatePlanPayload {
  const {
    endDate,
    shouldAddToExistingStripeSubscription,
    existingStripeSubscriptionId,
    planSubjectType,
    planSubjectId,
    activeConstraints,
    startDate,
    comments,
  } = planCreationContext;

  let payment: AdminAPITypes.CreatePlanPaymentInformation;

  if (shouldAddToExistingStripeSubscription) {
    payment = {
      existing: {
        paymentId: existingStripeSubscriptionId,
      },
    };
  } else {
    if (!startDate) {
      throw new Error(
        "createPlanCreationPayload: startDate must be set for unpaid plans"
      );
    }
    if (!endDate) {
      throw new Error(
        "createPlanCreationPayload: endDate must be set for unpaid plans"
      );
    }

    payment = {
      unpaid: {
        startDate: DateTimeUtils.getStartOfDay({
          date: startDate,
          shouldUseUtc: true,
        }).toISOString(),

        endDate: DateTimeUtils.getEndOfDay({
          date: endDate,
          shouldUseUtc: true,
        }).toISOString(),
      },
    };
  }

  // Remove 'current' field from each constraint in activeConstraints
  const cleanedActiveConstraints =
    removeCurrentFieldFromConstraints(activeConstraints);
  // TODO: Think about moving this feature logic somewhere else
  // because createPlanCreationPayload should not be concerned with figuring out
  // if the selected "product" is a feature or a bundle
  const selectedFeatureBundleIdentifiers: string[] = [];
  const selectedFeatureIdentifiers: string[] = [];

  if (selectedProduct) {
    if (isProductCustomFeature(selectedProduct)) {
      const allSelectedIdentifications =
        customProductIdToBundleIdentification[
          selectedProduct.identifier as CustomProductIdentification
        ];
      selectedFeatureBundleIdentifiers.push(...allSelectedIdentifications);
    } else if (isProductSingleFeature(selectedProduct)) {
      selectedFeatureIdentifiers.push(selectedProduct.identifier);
    } else {
      selectedFeatureBundleIdentifiers.push(selectedProduct.identifier);
    }
  }

  if (selectedAddon) {
    selectedFeatureBundleIdentifiers.push(selectedAddon.identifier);
  }

  return {
    subject: {
      type: planSubjectType,
      id:
        // There are two methods for creating device licenses:
        // one where admins provide a specific serial number, and another for any device without a serial number,
        // which uses auto-binding later.For these subscriptions, a special string '??-' is used as the serial number;
        // creating the subscription with subject ID '??-'.
        planSubjectType === AdminAPITypes.ESubjectType.device
          ? "??-"
          : planSubjectId,
    },

    constraints: cleanedActiveConstraints,
    featureBundles: selectedFeatureBundleIdentifiers,
    features: selectedFeatureIdentifiers,
    // As we will simple use the feature name for displaying the plan, value of label does not matter
    label: "",
    description: comments,
    payment,
  };
}
