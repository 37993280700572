/* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
import { AdminAPITypes } from "@stellar/api-logic";
import { CSVExporter, DateTimeUtils } from "@stellar/web-core";
import { getAccountDisplayName } from "./data-display";
import { isCompanyContext } from "./type-guards";

export const CSVDataUtils = {
  /**
   * Create csv file out of projects array and create a download handler
   */
  downloadProjectData(projects: AdminAPITypes.IAdmProject[]): void {
    const csvData = projects.map((project: AdminAPITypes.IAdmProject) => {
      return {
        ID: project.id,
        Name: project.name,
        Area: `${project.area.amount} ${project.area.unit}`,
        Address: project.address,
        State: project.state,
        URL: project.fileUrl,
        "Created At": DateTimeUtils.format({ date: project.createdAt }),
        "Modified At": DateTimeUtils.format({ date: project.modifiedAt }),
        Standalone: project.standalone,
        "Access Level": project.accessLevel,
        "Project Manager": getAccountDisplayName(project.projectManager),
        "Project Context": project.context.kind,
        "Company's Name": isCompanyContext(project.context)
          ? project.context.company.name
          : "-",
        "Group's Name": isCompanyContext(project.context)
          ? project.context.group.name
          : "-",
      };
    });

    CSVExporter.toFileDownload(csvData, "Projects List.csv");
  },

  /**
   * Create csv file out of group array and create a download handler
   */
  downloadGroupData(groups: AdminAPITypes.ICompanyGroup[]): void {
    const csvData = groups.map((group: AdminAPITypes.ICompanyGroup) => ({
      ID: group.id,
      Name: group.name,
      "Created At": DateTimeUtils.format({ date: group.createdAt }),
      "Sqft Assigned": group.sqft.assigned,
      "Sqft Used": group.sqft.used,
      "Thumbnail Url": group.thumbnailUrl,
    }));

    CSVExporter.toFileDownload(csvData, "Groups List.csv");
  },
};
