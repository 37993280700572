import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

/**
 * Use this hook to fetch all the projects of a specific subjectId.
 * Returns the fetched projects and a loading flag.
 * If the loading fails, the fetched projects will be an empty array.
 */
export function useProjectsOfSubjectType(
  subjectType:
    | AdminAPITypes.ESubjectType.company
    | AdminAPITypes.ESubjectType.user,
  subjectId: APITypes.UserIdentity | APITypes.CompanyId,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IAdmProject[], boolean] {
  const [projects, setProjects] = useState<AdminAPITypes.IAdmProject[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Fetches all the projects of subject type
  useEffect(() => {
    async function getProjectsOfSubjectType(): Promise<void> {
      setIsFetching(true);

      try {
        switch (subjectType) {
          case AdminAPITypes.ESubjectType.company:
            setProjects(
              await CoreApi.V0.ADMIN.getAllProjectsForCompanyWithId(subjectId)
            );
            break;
          case AdminAPITypes.ESubjectType.user:
            setProjects(
              await CoreApi.V0.ADMIN.getAllProjectsForUserWithId(
                subjectId as string
              )
            );
            break;
          default:
            throw new Error(
              `getProjectsOfSubjectType: Unsupported subjectType ${subjectType}`
            );
        }
      } catch (error) {
        errorHandler(`Could not load ${subjectType} projects`, error);
      } finally {
        setIsFetching(false);
      }
    }

    getProjectsOfSubjectType();
  }, [subjectType, subjectId, errorHandler]);

  return [projects, isFetching];
}
