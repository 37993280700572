import React from "react";

interface IProps {
  /** Text show in bold before the label. Can be used as the number of a specific item defined with label */
  counter: number | string;

  /** Text show after the bold text. Can be used as a label for the counter */
  label?: string;
}

/**
 * Shows a text in bold (e.g. counter) and a label after it
 */
export function Counter({ counter, label }: IProps): JSX.Element {
  return (
    <>
      <b>{counter}</b> {label}
    </>
  );
}
