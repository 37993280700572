import { Grid } from "@mui/material";
import { HBDatePicker } from "components/hb-customs/hb-date-picker";
import React from "react";
import { DateTimeUtils } from "@stellar/web-core";
import { HBNumberInput } from "./hb-number-input";

interface IProps {
  /** Start date and its corresponding setter */
  startDate: Date;
  setStartDate(newDate: Date): void;

  /** End date and its corresponding setter */
  endDate: Date;
  setEndDate(newDate: Date): void;

  /** Function that will be called when either start or end date change */
  onDateChange?(): void;
}

/**
 * Date range form.
 * The startDate timestamp will be set to the start of the selected day.
 * The endDate timestamp will be set to the end of the selected day.
 */
export function HBDateRangePicker({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onDateChange,
}: IProps): JSX.Element {
  // Makes sure the startDate timestamp is set to the start of the day
  function handleStartDateChange(newStartDate: Date | null): void {
    if (newStartDate) {
      setStartDate(newStartDate);

      onDateChange?.();
    }
  }

  /** Makes sure the endDate timestamp is set to the end of the day */
  function handleEndDateChange(newEndDate: Date | null): void {
    if (newEndDate) {
      setEndDate(newEndDate);

      onDateChange?.();
    }
  }

  function handleDaysChange(newDays: number | null): void {
    if (newDays !== null) {
      handleEndDateChange(
        DateTimeUtils.addDays({
          date: startDate,
          days: newDays - 1,
        })
      );
    }
  }

  const diffBetweenDatesInDays =
    DateTimeUtils.diffBetweenTwoDatesInDays(endDate, startDate) + 1;

  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        {/* Start date */}
        <HBDatePicker
          label="Start Date"
          value={startDate}
          handleOnChange={handleStartDateChange}
        />
      </Grid>
      <Grid item xs={5}>
        {/* Duration */}
        <HBNumberInput
          label="Days"
          value={diffBetweenDatesInDays}
          onChange={handleDaysChange}
          minValue={1}
          isRequired
          isIntegerValue
          isFullWidth
        />
      </Grid>
      <Grid item xs={7}>
        {/* End date */}
        <HBDatePicker
          label="End Date"
          value={endDate}
          handleOnChange={handleEndDateChange}
          minDate={startDate}
          minDateMessage={
            endDate < startDate
              ? "End date should not be before start date"
              : ""
          }
        />
      </Grid>
    </Grid>
  );
}
