import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { HBSpinner } from "components/hb-customs/hb-spinner";
import { AdminAPITypes } from "@stellar/api-logic";
import { PageNotFound } from "pages/page-not-found";
import React from "react";
import { useParams } from "react-router-dom";
import { IRouterParams } from "router/route-params";
import { useAccount } from "utils/hooks/use-account";
import { CreateProjectContainer } from "./create-project-container";

/**
 * Fetch the account data and call the create project container with the needed data
 */
export function CreateProjectForAccount(): JSX.Element {
  const { accountId } = useParams<IRouterParams>();

  const { handleErrorWithPage } = useErrorHandler();

  const [account, isFetchingAccount] = useAccount(
    accountId,
    handleErrorWithPage
  );

  if (isFetchingAccount) {
    return <HBSpinner />;
  } else if (!account) {
    return <PageNotFound />;
  }

  return (
    <CreateProjectContainer
      subject={{ type: AdminAPITypes.ESubjectType.user, account }}
    />
  );
}
