import { AdminAPITypes } from "@stellar/api-logic";
import { AccountUtils } from "utils/account";
import { formatPlanSubjectType } from "utils/data-display";
import { isCreateProjectForAccount } from "utils/type-guards";
import { ISubjectProject } from "./project-creation-context-types";

interface IGetSubjectDetailsForAccount {
  /** Type of the subject entity depending on users managing role */
  subjectType: AdminAPITypes.ESubjectType;

  /** ID of the subject entity depending on users managing role */
  subjectId: string;
}

/**
 * If subject is company or a user with managing role in a company, subjectType is COMPANY and subjectId is companyId
 * Otherwise subjectType is USER and subjectId is accountId
 */
export function getSubjectDetails(
  subject: ISubjectProject
): IGetSubjectDetailsForAccount {
  if (!isCreateProjectForAccount(subject)) {
    return {
      subjectType: subject.type,
      subjectId: subject.company.id.toString(),
    };
  }

  const managingCompany = AccountUtils.getCompanyWithManagingRole(
    subject.account
  );

  if (managingCompany) {
    return {
      subjectType: AdminAPITypes.ESubjectType.company,
      subjectId: managingCompany.id.toString(),
    };
  }

  return {
    subjectType: AdminAPITypes.ESubjectType.user,
    subjectId: subject.account.identity,
  };
}

/**
 * Returns the subject needed for create project context
 */
export function generateSubjectForContext(
  subject: ISubjectProject
): ISubjectProject {
  if (isCreateProjectForAccount(subject)) {
    return {
      type: AdminAPITypes.ESubjectType.user,
      account: subject.account,
    };
  }

  return {
    type: AdminAPITypes.ESubjectType.company,
    company: subject.company,
    group: subject.group,
    groupMembers: subject.groupMembers,
  };
}

/**
 * Generates a text displaying whether the project is for a group in a company or a user
 */
export function formatCreatedProjectSubject(subject: ISubjectProject): string {
  if (isCreateProjectForAccount(subject)) {
    return formatPlanSubjectType(subject.type);
  }

  return `${formatPlanSubjectType(subject.type)} - ${subject.group.name}`;
}

// TODO: This logic is copied from the Dashboard. Needs to be moved to web-core and add appropriate tests for it
/**
 * Check whether the input is a valid email address or not
 */
export function isValidEmail(email: string): boolean {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 6 is the minimum length of an email address
  if (!email || !email.length || email.length < 6) {
    return false;
  } else {
    // eslint-disable-next-line max-len
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }
}
