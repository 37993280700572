import { CompanyProfilePage } from "pages/company-profile/company-profile";
import { CreateCompanyPlanPage } from "pages/create-plan/create-company-plan-page";
import { CreateDevicePlanPage } from "pages/create-plan/create-device-plan-page";
import { CreateProjectForCompany } from "pages/create-project/create-project-for-company";
import { PageNotFound } from "pages/page-not-found";
import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  CompanyAddPlanRoute,
  CompanyAddDevicePlanRoute,
  CompanyAddProjectRoute,
  CompanyProfileRoute,
} from "./company-routes";

/**
 * Router for navigating all company related pages
 */
export function CompanyRouter(): JSX.Element {
  return (
    <Switch>
      <Route exact path={CompanyProfileRoute}>
        <CompanyProfilePage />
      </Route>

      <Route exact path={CompanyAddPlanRoute}>
        <CreateCompanyPlanPage />
      </Route>

      <Route exact path={CompanyAddDevicePlanRoute}>
        <CreateDevicePlanPage />
      </Route>

      <Route exact path={CompanyAddProjectRoute}>
        <CreateProjectForCompany />
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
