import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { IPlanCreationContext } from "./plan-creation-context-types";
import { BaseProduct } from "store/products/products-slice-helper-types";
import { isProductSingleFeature } from "utils/product/product-utils";

interface IProps {
  /** The plan creation context */
  planCreationContext: IPlanCreationContext;

  /** The selected feature or bundle to add to plan */
  selectedProduct: BaseProduct;

  /** Whether the plan is infinite or not */
  isInfinite?: boolean;
}

/**
 * Convert PlanCreationContext into an AdminAPITypes.CreatePlanPayload object with the custom selected feature or bundle
 */
export function createCustomPlanCreationPayload({
  planCreationContext,
  selectedProduct,
  isInfinite = false,
}: IProps): AdminAPITypes.CreatePlanPayload {
  const {
    endDate,
    planSubjectType,
    planSubjectId,
    activeConstraints,
    startDate,
    comments,
  } = planCreationContext;

  const payment: AdminAPITypes.CreatePlanPaymentInformation = {
    unpaid: {
      startDate: DateTimeUtils.getStartOfDay({
        date: startDate,
        shouldUseUtc: true,
      }).toISOString(),

      endDate: isInfinite
        ? "2099-01-01T00:00:00Z"
        : DateTimeUtils.getEndOfDay({
            date: endDate,
            shouldUseUtc: true,
          }).toISOString(),
    },
  };

  return {
    subject: {
      type: planSubjectType,
      id: planSubjectId,
    },
    constraints: activeConstraints,
    featureBundles: isProductSingleFeature(selectedProduct)
      ? []
      : [selectedProduct.identifier],
    features: isProductSingleFeature(selectedProduct)
      ? [selectedProduct.identifier]
      : [],
    // As we will simply use the feature name for displaying the plan, value of label does not matter
    label: "",

    description: comments,

    payment,
  };
}
