import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";
import { AdminAPITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";
import {
  ReactSetStateFunction,
  SubjectEntityIdTypes,
  SubjectEntityTypes,
} from "utils/utility-types";

/**
 * Use this hook to fetch all the plans of a specific subject type.
 * Returns the fetched plans and a loading flag.
 * If the loading fails, the fetched plans will be an empty array.
 */
export function usePlansOfSubjectType(
  subjectType: SubjectEntityTypes,
  subjectId: SubjectEntityIdTypes,
  errorHandler: ErrorHandlingFunction,
  shouldFetchPlans: boolean,
  setShouldFetchPlans: ReactSetStateFunction<boolean>
): [AdminAPITypes.IPlan[], boolean] {
  const [plans, setPlans] = useState<AdminAPITypes.IPlan[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Fetches all relevant the plans of subjectType and subjectId
  useEffect(() => {
    async function getSubjectTypePlans(): Promise<void> {
      setIsFetching(true);

      try {
        const fetchedPlans = await subjectTypeToApi[subjectType](
          // Convert to string because getPlansBySubjectUser expects a string
          `${subjectId}`
        );

        // Currently, there is no endpoint to directly fetch all device plans by its subject type.
        // Therefore, we fetch all plans from the company subscriber and then filter them by the subject type 'device'.
        // TODO: Implement and use a separate API call to retrieve device plans directly
        const filteredPlans = fetchedPlans.filter((plan) => {
          if (subjectType === AdminAPITypes.ESubjectType.device) {
            // Return only plans with 'device' type
            return plan.subject.type === AdminAPITypes.ESubjectType.device;
          } else {
            // Return all plans except those with 'device' type
            return plan.subject.type !== AdminAPITypes.ESubjectType.device;
          }
        });
        setPlans(filteredPlans.filter(shouldPlanBeVisible));
      } catch (error) {
        errorHandler(`Could not load ${subjectType} plans`, error);
      } finally {
        setIsFetching(false);
        setShouldFetchPlans(false);
      }
    }

    if (shouldFetchPlans) {
      getSubjectTypePlans();
    }
  }, [
    subjectType,
    subjectId,
    errorHandler,
    shouldFetchPlans,
    setShouldFetchPlans,
  ]);

  return [plans, isFetching];
}

// API methods to use for the individual ESubjectTypes
const subjectTypeToApi = {
  [AdminAPITypes.ESubjectType.user]: CoreApi.V0.ADMIN.getPlansBySubjectUser,
  [AdminAPITypes.ESubjectType.company]:
    CoreApi.V0.ADMIN.getPlansBySubscriberCompany,
  [AdminAPITypes.ESubjectType.project]:
    CoreApi.V0.ADMIN.getPlansBySubjectProject,
  [AdminAPITypes.ESubjectType.device]:
    CoreApi.V0.ADMIN.getPlansBySubscriberCompany,
};

// Some plans should not be visible at all in the admin-panel
// Those plans have one of the following feature bundles
const invisibleBundles = [
  // Plans with the minimal bundle are not relevant to handle in the admin-panel
  // This is because those plans are assigned to every company
  AdminAPITypes.EFeatureBundleIdentifier.minimal,
];

/**
 * Filter method for deciding if a given plan should be visible or not
 */
function shouldPlanBeVisible(plan: AdminAPITypes.IPlan): boolean {
  return !plan.featureBundles.some((bundle) =>
    invisibleBundles.includes(bundle.identifier)
  );
}
