import { AdminAPITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";
import { CoreApi } from "api";
import { cloneDeep } from "lodash";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { EAllSearchOptions, SearchState } from "./use-search-types";

/** Determines what you can search for and how responses must look */
type SearchResponse =
  | AdminAPITypes.IAdmUser
  | AdminAPITypes.IAdmProject
  | AdminAPITypes.IAdmCompany;

const initialState: SearchState = {
  [EAllSearchOptions.account]: {
    result: [],
    isFetching: false,
  },
  [EAllSearchOptions.project]: {
    result: [],
    isFetching: false,
  },
  [EAllSearchOptions.company]: { result: [], isFetching: false },
};

const domainToRequest: {
  [key in EAllSearchOptions]: (key: string) => Promise<SearchResponse[]>;
} = {
  [EAllSearchOptions.project]: CoreApi.V0.ADMIN.searchProjects,
  [EAllSearchOptions.company]: CoreApi.V0.ADMIN.searchCompanies,
  [EAllSearchOptions.account]: CoreApi.V0.ADMIN.searchUsers,
};

/**
 * hook to access search results for different types of domains
 *
 * @param query: an arbitrary string we want to search for
 * @param domain: optional domain we want the key to be limited to
 */
export function useSearch(
  query: string,
  domain?: EAllSearchOptions
): [SearchState, boolean] {
  const { handleErrorWithSnackbar } = useErrorHandler();

  const [searchResult, setSearchResult] = useState<SearchState>(
    cloneDeep(initialState)
  );

  // currently used as "global" fetching state which acts as a "first" results flag right now
  const [isFetching, setIsFetching] = useState(false);

  // init search upon receiving query and domain
  useEffect(() => {
    async function search(): Promise<void> {
      setIsFetching(true);

      const fetchResults: SearchState = cloneDeep(initialState);

      function setAllFetching(isFetching: boolean): void {
        for (const domain of searchDomains) {
          fetchResults[domain].isFetching = isFetching;
        }
        setSearchResult({ ...fetchResults });
      }

      const searchDomains = domain
        ? [domain]
        : Object.values(EAllSearchOptions);

      // set all to fetching before actually executing any requests
      setAllFetching(true);

      for (const domain of searchDomains) {
        // we trigger all requests in direct succession and want to make sure that the results
        // are displayed in the UI as soon as possible, which is why we aren't using await but
        // set the state as soon as the promise resolves, triggering state change and rerender
        domainToRequest[domain](encodeURIComponent(query))
          .then((response) => {
            switch (domain) {
              case EAllSearchOptions.account:
                fetchResults[domain].result =
                  response as AdminAPITypes.IAdmUser[];
                break;
              case EAllSearchOptions.company:
                fetchResults[domain].result =
                  response as AdminAPITypes.IAdmCompany[];
                break;
              case EAllSearchOptions.project:
                fetchResults[domain].result =
                  response as AdminAPITypes.IAdmProject[];
                break;
            }
            fetchResults[domain].isFetching = false;

            // update each result as soon as it's available independently
            setSearchResult({ ...fetchResults });
          })
          .catch((error) => {
            handleErrorWithSnackbar("Could not retrieve search results", error);
            setAllFetching(false);
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    }

    if (query?.length) {
      search();
    }
  }, [query, domain, handleErrorWithSnackbar]);

  return [searchResult, isFetching];
}
