import { Box, TextField } from "@mui/material";
import React, { useState } from "react";

const ENTER_KEYCODE = 13;

interface IProps {
  onSearchExecution(query: string): void;
}

/**
 * Simple input field that will execute the current value when user hits Enter key
 * But will only do so if input is not empty
 */
export function SearchBar({ onSearchExecution }: IProps): JSX.Element {
  const [searchQuery, setSearchQuery] = useState<string>("");

  function onTextFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchQuery(event.target.value);
  }

  function handleOnKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.keyCode === ENTER_KEYCODE) {
      search();
    }
  }

  function search(): void {
    onSearchExecution(searchQuery);
  }

  return (
    <Box sx={{ maxWidth: "100%", px: "24px" }}>
      <TextField
        autoFocus
        variant="standard"
        label="Search"
        value={searchQuery}
        onChange={onTextFieldChange}
        onKeyDown={handleOnKeyDown}
        fullWidth
      />
    </Box>
  );
}
