import { HBSnackbar } from "components/hb-customs/hb-snackbar";
import { Grid } from "@mui/material";
import { InfoGrid } from "components/info-grid/info-grid";
import { ILabelInfoPair } from "components/info-grid/info-row-types";
import { AdminAPITypes } from "@stellar/api-logic";
import React, { useState } from "react";
import { Clipboard } from "utils/clipboard";
import {
  getAccountDisplayName,
  uppercaseFirst,
  formatProjectArea,
  DASH,
} from "utils/data-display";
import { isCompanyContext } from "utils/type-guards";
import { OptionalDate } from "../../../components/optional-date";
import { ClipboardButton } from "components/buttons";
import { AccountLink, CompanyLink } from "components/links/internal-links";
import { DateTimeUtils } from "@stellar/web-core";
import { useProjectContext } from "../project-context";

function getLeftColumnRowsForProject(
  project: AdminAPITypes.IAdmProject
): ILabelInfoPair[] {
  let leftColumnRows: ILabelInfoPair[] = [
    { label: "Name:", info: project.name },
    { label: "Project ID:", info: String(project.id) },
    { label: "Address:", info: project.address },
  ];

  if (isCompanyContext(project.context)) {
    leftColumnRows = leftColumnRows.concat([
      {
        label: "Company:",
        info: (
          <CompanyLink companyId={project.context.company.id}>
            {project.context.company.name}
          </CompanyLink>
        ),
      },
      { label: "Group:", info: project.context.group.name },
    ]);
  }

  return leftColumnRows.concat([
    {
      label: "Area:",
      info: formatProjectArea(project.area),
    },
    {
      label: "Project Manager:",
      info: (
        <AccountLink accountId={project.projectManager.identity}>
          {getAccountDisplayName(project.projectManager)}
        </AccountLink>
      ),
    },
  ]);
}

export function ProjectDetails(): JSX.Element {
  const project = useProjectContext();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  function handleClipboardButtonClick(): void {
    Clipboard.copyProjectInfo(project);
    setIsSnackbarOpen(true);
  }

  return (
    <Grid container wrap="nowrap" sx={{ padding: "47px" }}>
      <InfoGrid
        leftColumnRows={getLeftColumnRowsForProject(project)}
        rightColumnRows={[
          {
            label: "SalesForce Opportunity:",
            info: project.salesforceOpportunity
              ? project.salesforceOpportunity
              : DASH,
          },
          {
            label: "Last Edited:",
            info: DateTimeUtils.format({ date: project.modifiedAt }),
          },
          {
            label: "Last Capture:",
            info: <OptionalDate date={project.lastCapturedAt} />,
          },
          {
            label: "Last Download:",
            info: <OptionalDate date={project.lastDownloadedAt} />,
          },
          {
            label: "Access Level:",
            info: uppercaseFirst(project.accessLevel),
          },
        ]}
      />

      <ClipboardButton onClick={handleClipboardButtonClick} />

      <HBSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
      >
        Copied to Clipboard!
      </HBSnackbar>
    </Grid>
  );
}
