import { ProjectBreadcrumbs } from "components/breadcrumbs/project-breadcrumbs";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { AdminAPITypes } from "@stellar/api-logic";
import { PlanInfo } from "pages/plan-info";
import React from "react";
import { useParams } from "react-router-dom";
import { IRouterParams } from "router/route-params";
import { useProject } from "utils/hooks/use-project";
import { ContentLayout } from "../content-layout";
import { ProjectContext } from "./project-context";
import { ProjectProfileHeader } from "./project-profile-header";
import { ProjectDetails } from "./tab-contents/project-details";
import { LoadingScreen } from "components/loading-screen";

export function ProjectProfilePage(): JSX.Element {
  const { projectId } = useParams<IRouterParams>();

  const { handleErrorWithPage } = useErrorHandler();

  const [project, isFetchingProject] = useProject(
    projectId,
    handleErrorWithPage
  );

  return (
    <LoadingScreen isFetching={isFetchingProject} data={project}>
      {project && (
        <ProjectContext.Provider value={project}>
          <ContentLayout
            breadcrumbs={<ProjectBreadcrumbs project={project} />}
            header={<ProjectProfileHeader />}
            tabs={[
              {
                label: "Project Information",
                content: <ProjectDetails />,
              },
              {
                label: "Plans",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.project}
                    subjectId={project.id}
                  />
                ),
              },
            ]}
          />
        </ProjectContext.Provider>
      )}
    </LoadingScreen>
  );
}
