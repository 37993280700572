import React, { ReactNode } from "react";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { HBSortableTable } from "./hb-sortable-table";
import { SortableColumn } from "./hb-table-types";
import { Compare } from "utils/compare";
import { DataFormatter, DateTimeUtils } from "@stellar/web-core";
import { HBCollapsibleTableRow } from "./hb-collapsible-table-row";
import { InfoGrid } from "components/info-grid/info-grid";
import { formatGroupSqftAssigned } from "utils/data-display";
import { useNavigation } from "router/use-navigation";

interface IGroupsTableProps {
  /** ID of the company that the projects belong to */
  companyId: APITypes.CompanyId;

  /** The list of groups */
  groups: AdminAPITypes.ICompanyGroup[];
}

/**
 * Renders a list of groups in a sortable table
 */
export function GroupsTable({
  companyId,
  groups,
}: IGroupsTableProps): JSX.Element {
  const columns: SortableColumn<AdminAPITypes.ICompanyGroup>[] = [
    {
      id: "name",
      label: "Group",
      compareFunction: (groupA, groupB) =>
        Compare.defaultComparison(groupA.name, groupB.name),
    },
    {
      id: "numManagers",
      label: "Members",
      compareFunction: (groupA, groupB) =>
        Compare.defaultComparison(groupA.managerCount, groupB.managerCount),
    },
    {
      id: "numProjects",
      label: "Projects",
      compareFunction: (groupA, groupB) =>
        Compare.defaultComparison(groupA.projectCount, groupB.projectCount),
    },
    {
      id: "sqftUsed",
      label: "Sqft Used",
      compareFunction: (groupA, groupB) =>
        Compare.defaultComparison(groupA.sqft.used, groupB.sqft.used),
    },
    {
      id: "sqftAssigned",
      label: "Sqft Assigned",
      compareFunction: (groupA, groupB) =>
        Compare.defaultComparison(groupA.sqft.assigned, groupB.sqft.assigned),
    },
    {
      id: "createdAt",
      label: "Created Date",
      compareFunction: (groupA, groupB) =>
        Compare.parametersAsDate(groupA.createdAt, groupB.createdAt),
    },
    {
      id: "actions",
      label: "",
      shouldDisableSorting: true,
    },
  ];

  return (
    <HBSortableTable
      columns={columns}
      tableData={groups}
      renderTableRow={(group) => (
        <GroupsTableRow key={group.id} companyId={companyId} group={group} />
      )}
      isSelectable={false}
      orderById={"name"}
    />
  );
}

interface IGroupsTableRowProps {
  /** ID of the company that the projects belong to */
  companyId: APITypes.CompanyId;

  /** A group to show its details in a row */
  group: AdminAPITypes.ICompanyGroup;
}

/**
 * Receives a group and show it as a single row in group table
 */
function GroupsTableRow({
  companyId,
  group,
}: IGroupsTableRowProps): JSX.Element {
  const { navigateToProjectCreation } = useNavigation();

  /**
   * Cell values for each row of group table. The first cell is provided by name in HBCollapsibleTableRow component
   */
  function createCellNodesForGroup(): ReactNode[] {
    return [
      group.managerCount,
      group.projectCount,
      DataFormatter.numberThousandsSeparated(group.sqft.used),
      formatGroupSqftAssigned(group.sqft.assigned),
      DateTimeUtils.format({ date: group.createdAt }),
    ];
  }

  const dropdownMenuItems = [
    {
      label: "Create Project",
      // TODO: The actual click handler will be implemented in https://faro01.atlassian.net/browse/HBWB-22
      onClickHandler: () => {
        navigateToProjectCreation({
          subjectType: AdminAPITypes.ESubjectType.company,
          companyId: companyId,
          groupId: group.id,
        });
      },
    },
  ];

  return (
    <HBCollapsibleTableRow
      key={group.id}
      name={group.name}
      cellNodes={createCellNodesForGroup()}
      dropdownMenuItems={dropdownMenuItems}
    >
      <InfoGrid
        leftColumnRows={[{ label: "Members", info: "coming soon" }]}
        rightColumnRows={[{ label: "Projects", info: "coming soon" }]}
      />
    </HBCollapsibleTableRow>
  );
}
