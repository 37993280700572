import { Grid, GridSize } from "@mui/material";
import React from "react";

interface IHBGridItemProps {
  /** Defines the number of grids the component is going to use in xs format */
  width?: GridSize | boolean;
}

/**
 * Custom HoloBuilder Grid Item component.
 * Wrapper around the material-ui Grid Item component offering limited settings.
 *
 * @param width width of the grid item. Width by default is true making the items equitably share the available space
 */
export function HBGridItem({
  children,
  width = true,
}: React.PropsWithChildren<IHBGridItemProps>): JSX.Element | null {
  if (!React.Children.count(children)) {
    return null;
  }

  return (
    <Grid item xs={width}>
      {children}
    </Grid>
  );
}
