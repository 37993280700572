import { AdminAPITypes } from "@stellar/api-logic";
import React, { useContext } from "react";

export const CompanyContext =
  React.createContext<AdminAPITypes.IAdmCompany | null>(null);

export function useCompanyContext(): AdminAPITypes.IAdmCompany {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error(
      "useCompanyContext() has to be used within a CompanyContext."
    );
  }

  return context;
}
