// Some colors taken from our design guidelines:
// https://docs.google.com/document/d/19BOOS-XQ7XNVgT6jXb4s_adm_891Zbl9NVtAjcSddQw

export const Color = {
  white: "#FFFFFF",
  black: "#000000",

  // [DOC] 52, 46, 39, etc. are the values of the colors in CMYK encoding
  // See: https://www.w3schools.com/colors/colors_cmyk.asp
  grey80: "#333333",
  grey87: "#212121",
  grey76: "#3C3C3C",
  grey57: "#6E6E6E",
  grey52: "#7B7B7B",
  grey40: "#999999",
  grey46: "#898989",
  grey39: "#9C9C9C",
  grey15: "#D8D8D8",
  grey05: "#F2F2F2",
  grey02: "#fafafa",
  grey01: "#FCFCFC",

  blue: "#45BCFF",
  orange: "#FF9E45",

  planStatusActive: "#EEFFF4",
  planStatusInactive: "#EAF9FF",
  planStatusInvalid: "#FFECEC",
  planStatusDeactivated: "#F9F9F9",

  hbYellow: "#f4e83e",
};
