import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

/**
 * Use this hook to fetch the project for a specific projectId.
 * Returns the fetched project and a loading flag.
 * If the loading fails, the fetched project will be undefined.
 */
export function useProject(
  projectId: APITypes.ProjectId,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IAdmProject | undefined, boolean] {
  const [project, setProject] = useState<AdminAPITypes.IAdmProject>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Fetches the project
  useEffect(() => {
    async function fetchProject(projectId: APITypes.ProjectId): Promise<void> {
      setIsFetching(true);

      try {
        setProject(await CoreApi.V0.ADMIN.getProjectById(projectId));
      } catch (error) {
        errorHandler("Could not load Project", error);
        setProject(undefined);
      } finally {
        setIsFetching(false);
      }
    }

    fetchProject(projectId);
  }, [projectId, errorHandler]);

  return [project, isFetching];
}
