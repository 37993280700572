import { Grid } from "@mui/material";
import React from "react";
import { InfoRow } from "./info-row";
import { ILabelInfoPair } from "./info-row-types";

interface IProps {
  /** Pair of ino to show on the left column */
  leftColumnRows?: ILabelInfoPair[];

  /** Pair of ino to show on the right column */
  rightColumnRows?: ILabelInfoPair[];
}

/** Displays given label/info pairs, providing two columns */
export function InfoGrid({
  leftColumnRows,
  rightColumnRows,
}: IProps): JSX.Element {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {leftColumnRows &&
          leftColumnRows.map((row, index) => (
            <InfoRow label={row.label} info={row.info} key={index} />
          ))}
      </Grid>
      <Grid item xs={6}>
        {rightColumnRows &&
          rightColumnRows.map((row, index) => (
            <InfoRow label={row.label} info={row.info} key={index} />
          ))}
      </Grid>
    </Grid>
  );
}
