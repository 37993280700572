import { DateTimeUtils } from "@stellar/web-core";

export const TRIAL_PLAN_DURATION_IN_DAYS = 21;

/**
 * Check if the input date is in the last 30 days
 * Calculation does not include today or 30 days ago
 */
export function isInTheLast30Days(date: DateTimeUtils.DateTimeInput): boolean {
  const daysFromToday = DateTimeUtils.diffFromTodayInDays({
    date,
    shouldIncludeToday: false,
  });

  return (
    daysFromToday < 0 && daysFromToday > -DateTimeUtils.Duration.oneMonth.inDays
  );
}

/**
 * Check if the input date is in the next 30 days
 * Calculation does not include today or 30 days from now
 */
export function isInTheNext30Days(date: DateTimeUtils.DateTimeInput): boolean {
  const daysFromToday = DateTimeUtils.diffFromTodayInDays({
    date,
    shouldIncludeToday: false,
  });

  return (
    daysFromToday > 0 && daysFromToday < DateTimeUtils.Duration.oneMonth.inDays
  );
}
