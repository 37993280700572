import { AdminAPITypes } from "@stellar/api-logic";
import React, { useEffect, useState } from "react";
import { CreatePlanForm } from "./create-plan-form";
import { PlanCreationContext } from "../plan-creation-context";
import { PlanCreationSummary } from "./plan-creation-summary";
import { Constraint } from "utils/constraint";
import { NavigationWarning } from "components/navigation-warning";
import { LoadingScreen } from "components/loading-screen";
import { HBSpinner } from "components/hb-customs/hb-spinner";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store-helper";
import { isFetchingProductsSelector } from "store/products/products-selector";
import {
  fetchNonSingleFeatures,
  fetchSingleFeatures,
} from "store/products/products-slice-thunks";

interface IProps {
  /** The ID of the plan subject */
  planSubjectId: string;

  /** The type of the plan subject */
  planSubjectType: AdminAPITypes.ESubjectType;

  /** The name of the plan subject */
  planSubjectName: string;
}

/** Container that holds the plan creation page and its summary */
export function CreatePlanContainer({
  planSubjectType,
  planSubjectName,
  planSubjectId,
}: IProps): JSX.Element {
  const isFetchingProducts = useAppSelector(isFetchingProductsSelector);
  const dispatch = useAppDispatch();

  const [
    shouldAddToExistingStripeSubscription,
    setShouldAddToExistingStripeSubscription,
  ] = useState<boolean>(false);
  const [existingStripeSubscriptionId, setExistingStripeSubscriptionId] =
    useState<string>("");

  const [activeConstraints, setActiveConstraints] = useState<
    AdminAPITypes.CreateConstraintPayload[]
  >([]);

  const [comments, setComments] = useState<string>("");

  // Setting initial startDate & endDate to "now" because the time of the day does not matter at this point and will
  // be adjusted before creating the plan through API.
  // Using start/end of day at this point can cause problems because of different time zones vs. UTC and adds
  // to much confusion in general.
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  // No date is required for plans linked to existing Stripe subs as the dates will be provided by those subs
  const isDateRequired = !shouldAddToExistingStripeSubscription;

  /** Fetch the products */
  useEffect(() => {
    async function fetchProducts(): Promise<void> {
      await dispatch(fetchSingleFeatures());
      await dispatch(fetchNonSingleFeatures());
    }

    fetchProducts();
  }, [dispatch]);

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [shouldShowSummary, setShouldShowSummary] = useState<boolean>(false);

  // Filter out constraints with zero or no value
  useEffect(() => {
    if (shouldShowSummary) {
      setActiveConstraints((activeConstraints) =>
        removeEmptyConstraints(activeConstraints)
      );
    }
  }, [shouldShowSummary]);

  if (isFetchingData || isFetchingProducts) {
    return <HBSpinner />;
  }

  return (
    <LoadingScreen isFetching={isFetchingData}>
      <PlanCreationContext.Provider
        value={{
          planSubjectId,
          planSubjectType,
          planSubjectName,
          shouldAddToExistingStripeSubscription,
          setShouldAddToExistingStripeSubscription,
          existingStripeSubscriptionId,
          setExistingStripeSubscriptionId,
          activeConstraints,
          setActiveConstraints,
          comments,
          setComments,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          isDateRequired,
          isFetchingData,
          setIsFetchingData,
          shouldShowSummary,
          setShouldShowSummary,
        }}
      >
        <NavigationWarning />

        <Box sx={{ margin: "10px 110px 0px" }}>
          {shouldShowSummary ? <PlanCreationSummary /> : <CreatePlanForm />}
        </Box>
      </PlanCreationContext.Provider>
    </LoadingScreen>
  );
}

/** Removes the empty constraints */
function removeEmptyConstraints(
  constraints: AdminAPITypes.CreateConstraintPayload[]
): AdminAPITypes.CreateConstraintPayload[] {
  return constraints.filter((constraint) => {
    return Constraint.getMaxValueOfConstraint(constraint) > 0;
  });
}
