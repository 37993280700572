import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import { useEffect } from "react";
import { selectedProductSelector } from "store/products/products-selector";
import { useAppSelector } from "store/store-helper";
import { Constraint } from "utils/constraint";
import { ReactSetStateFunction } from "utils/utility-types";

interface Props {
  /** Setter for changing the active constraints */
  setActiveConstraints: ReactSetStateFunction<
    AdminAPITypes.CreateConstraintPayload[]
  >;
}

/** Returns the default plan limit depending on the selected product */
export function useDefaultPlanLimits({ setActiveConstraints }: Props): void {
  const selectedProduct = useAppSelector(selectedProductSelector);

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-magic-numbers */
    const activeConstraints = [];
    switch (selectedProduct?.identifier) {
      case "standardPointCloud":
        activeConstraints.push(createUserRolePayload(3));
        break;
      case "standard360":
        activeConstraints.push(
          createUserRolePayload(3),
          createProjectCountPayload(3)
        );
        break;
      case "proPointCloud":
        activeConstraints.push(createUserRolePayload(10));
        break;
      case "pro360":
        activeConstraints.push(
          createUserRolePayload(10),
          createProjectCountPayload(10)
        );
        break;

      default:
        break;
    }
    /* eslint-enable */

    setActiveConstraints(activeConstraints);
  }, [selectedProduct?.identifier, setActiveConstraints]);
}

/** Create user role payload */
function createUserRolePayload(
  limit: number
): AdminAPITypes.CreateConstraintPayload {
  return Constraint.createPayloadForConstraintWithValue(
    AdminAPITypes.EConstraintType.userRole,
    limit,
    0,
    {
      company: [
        CoreAPITypes.EUserCompanyRoleUpdated.enterpriseAdmin,
        CoreAPITypes.EUserCompanyRoleUpdated.groupManager,
        CoreAPITypes.EUserCompanyRoleUpdated.projectManager,
      ],
      resource: [
        CoreAPITypes.EUserProjectRoleUpdated.owner,
        CoreAPITypes.EUserProjectRoleUpdated.admin,
        CoreAPITypes.EUserProjectRoleUpdated.editor,
      ],
    }
  );
}

/** Create project count payload */
function createProjectCountPayload(
  limit: number
): AdminAPITypes.CreateConstraintPayload {
  return Constraint.createPayloadForConstraintWithValue(
    AdminAPITypes.EConstraintType.projectCount,
    limit
  );
}
