import { Grid } from "@mui/material";
import { InfoGrid } from "components/info-grid/info-grid";
import React, { useState } from "react";
import { Clipboard } from "utils/clipboard";
import { ClipboardButton } from "components/buttons";
import { HBSnackbar } from "components/hb-customs/hb-snackbar";
import { useCompanyContext } from "../company-context";
import { DASH } from "utils/data-display";

/**
 * Content inside the company information tab
 */
export function CompanyInfo(): JSX.Element {
  const company = useCompanyContext();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  function handleClipboardButtonClick(): void {
    if (company) {
      Clipboard.copyCompanyInfo(company);
    }

    setIsSnackbarOpen(true);
  }

  // TODO: Add information of company below when available, see https://bitstars.atlassian.net/browse/BACK-3297
  return (
    <Grid container sx={{ padding: "47px" }} wrap="nowrap">
      <InfoGrid
        leftColumnRows={[{ label: "Name", info: company.name }]}
        rightColumnRows={[{ label: "SalesForce", info: DASH }]}
      />

      <ClipboardButton onClick={handleClipboardButtonClick} />

      <HBSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
      >
        Copied to Clipboard!
      </HBSnackbar>
    </Grid>
  );
}
