import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { DateTimeUtils } from "@stellar/web-core";
import React from "react";

interface IPlanRangeInfoProps {
  /** Date to render for the start of the plan */
  startDate: DateTimeUtils.DateTimeInput;

  /** Date to render for the end of the plan */
  endDate: DateTimeUtils.DateTimeInput;
}

/**
 * A panel about informing on how the selected dates affect customers in different time zones
 */
export function PlanRangeInfo({
  startDate,
  endDate,
}: IPlanRangeInfoProps): JSX.Element {
  const theme = useTheme();

  const formattedStartDate = formatDateForTimezones(
    DateTimeUtils.getStartOfDay({
      date: startDate,
      shouldUseUtc: true,
    })
  );

  const formattedEndDate = formatDateForTimezones(
    DateTimeUtils.getEndOfDay({
      date: endDate,
      shouldUseUtc: true,
    })
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <InfoIcon
          sx={{
            color: theme.palette.info.light,
            mr: "5px",
            mt: "-2px",
          }}
        />{" "}
        At what time of the day will the plan start and end?
      </AccordionSummary>

      <AccordionDetails>
        <Grid>
          <p>
            Plans will start and end at midnight of the <i>UTC time zone</i>.
            Below is a list of <u>example</u> customer locations and when the
            plan with the selected dates will start and end for customers in
            those locations.
          </p>

          <p>
            Please take particular note of the{" "}
            <i>difference in calendar dates</i>. Consider letting the plan start
            earlier or end later in order to provide an uninterrupted customer
            experience.
          </p>

          <ul>
            <TimeZoneInfoListItem
              title="US west coast"
              startText={formattedStartDate.LA}
              endText={formattedEndDate.LA}
            />
            <TimeZoneInfoListItem
              title="Germany"
              startText={formattedStartDate.DE}
              endText={formattedEndDate.DE}
            />
            <TimeZoneInfoListItem
              title="Japan"
              startText={formattedStartDate.JP}
              endText={formattedEndDate.JP}
            />
          </ul>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

interface ITimeZoneInfoListItemProps {
  /** Title of the list item */
  title: string;

  /** String to show for the start entry */
  startText: string;

  /** String to show for the end entry */
  endText: string;
}

/**
 * List item that showing a bold title and another sub-list with two entries
 */
function TimeZoneInfoListItem({
  title,
  startText,
  endText,
}: ITimeZoneInfoListItemProps): JSX.Element {
  return (
    <li>
      <b>{title}</b>
      <ul>
        <li>Start: {startText}</li>
        <li>End: {endText}</li>
      </ul>
    </li>
  );
}

interface IFormattedDateForTimezones {
  /** Date as string in local time of Los Angeles */
  LA: string;

  /** Date as string in local time in Germany */
  DE: string;

  /** Date as string in local time of Japan */
  JP: string;
}

/**
 * Format the provided date into strings using the local times of Los Angeles, Germany and Japan
 */
function formatDateForTimezones(date: Date): IFormattedDateForTimezones {
  const stringFormat = "en-US";

  const stringOptions: Intl.DateTimeFormatOptions = {
    // Show date in this format: "June 24, 21" (example)
    year: "2-digit",
    month: "long",
    day: "2-digit",

    // Show time of day in this format: "1:59 AM" (example)
    hour: "2-digit",
    minute: "2-digit",

    // Show time zone in long format: "Central European Summer Time" (example)
    // Note: The other option here is "short", which will often only show the GMT offset and not the time zone's name
    timeZoneName: "long",
  };

  const timezones: {
    short: keyof IFormattedDateForTimezones;
    long: string;
  }[] = [
    { short: "LA", long: "America/Los_Angeles" },
    { short: "DE", long: "Europe/Berlin" },
    { short: "JP", long: "Japan" },
  ];

  return timezones.reduce((acc, timezone) => {
    acc[timezone.short] = date.toLocaleString(stringFormat, {
      ...stringOptions,

      timeZone: timezone.long,
    });

    return acc;
  }, {} as IFormattedDateForTimezones);
}
