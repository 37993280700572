import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { BreadcrumbsData } from "./breadcrumbs-data";
import { HBBreadcrumbs } from "./hb-breadcrumbs";

interface ICompanyBreadcrumbsProps {
  /** Object represents current company to generate breadcrumb out of it */
  company: AdminAPITypes.IAdmCompany;
}

/**
 * Display breadcrumbs for a company
 */
export function CompanyBreadcrumbs({
  company,
}: ICompanyBreadcrumbsProps): JSX.Element {
  return (
    <HBBreadcrumbs breadcrumbsData={[BreadcrumbsData.forCompany(company)]} />
  );
}
