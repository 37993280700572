import React from "react";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { ContentLayout } from "pages/content-layout";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { Grid } from "@mui/material";
import { LoadingScreen } from "components/loading-screen";
import { useGroupsOfCompany } from "utils/hooks/use-groups-of-company";
import { GroupsTable } from "components/tables/groups-table";
import { GroupInfoFooter } from "components/footers";

interface IProps {
  /** ID of the owner that the projects belong to */
  companyId: APITypes.CompanyId;
}

/**
 * Shows the groups that belong to the provided owner
 * Fetches the owner's group and shows a message if the owner has no group in the company
 */
export function GroupsInfo({ companyId }: IProps): JSX.Element {
  const { handleErrorWithDialog } = useErrorHandler();

  const [groups, isFetchingGroups] = useGroupsOfCompany(
    companyId,
    handleErrorWithDialog
  );

  return (
    <ContentLayout
      footer={
        <GroupInfoFooter
          groups={groups}
          subjectId={companyId}
          subjectType={AdminAPITypes.ESubjectType.company}
        />
      }
    >
      <LoadingScreen
        isFetching={isFetchingGroups}
        data={groups.length}
        errorContent={<NoGroupMessage />}
      >
        <GroupsTable groups={groups} companyId={companyId} />
      </LoadingScreen>
    </ContentLayout>
  );
}

/**
 * Showing a message as a paragraph describing that there is no groups to show
 */
function NoGroupMessage(): JSX.Element {
  return (
    <Grid container justifyContent="center" sx={{ padding: "20px" }}>
      <Grid item>
        <p>This company does not have groups yet.</p>
      </Grid>
    </Grid>
  );
}
