import React, { useState } from "react";
import { SearchBar } from "./search-bar";
import { SearchResults } from "./search-results";
import { useSearch } from "../../utils/hooks/use-search";
import { Box } from "@mui/material";

/**
 * The home page content of the application.
 */
export function HomePage(): JSX.Element {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResult] = useSearch(searchQuery);

  function onSearchExecution(query: string): void {
    setSearchQuery(query);
  }

  return (
    <section>
      <Box sx={{ mx: "20px" }} component="h2">
        Home
      </Box>

      <Box sx={{ my: "20px" }}>
        <SearchBar {...{ onSearchExecution }} />
      </Box>

      {searchQuery.length > 0 && <SearchResults results={searchResult} />}
    </section>
  );
}
