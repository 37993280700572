import React from "react";
import { Box, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Color } from "utils/styles/default-colors";

interface IInputControlsProps {
  /** Whether the confirm button is disabled or not */
  isConfirmButtonDisable?: boolean;

  /** Handler for confirm button */
  onConfirm(): void;

  /** Handler for cancel button */
  onCancel(): void;
}

/**
 * Shows a check and close buttons.
 * Can be used to add input controls to forms such as InlineEditableTextField component
 */
export function InputControls({
  isConfirmButtonDisable,
  onConfirm,
  onCancel,
}: IInputControlsProps): JSX.Element {
  return (
    <Box
      sx={{
        "& .Mui-disabled": {
          color: Color.grey15,
        },
      }}
    >
      <IconButton
        sx={{ margin: "0px 5px", verticalAlign: "top" }}
        color="primary"
        size="small"
        onClick={onConfirm}
        disabled={isConfirmButtonDisable}
      >
        <CheckIcon fontSize="small" />
      </IconButton>
      <IconButton
        sx={{ margin: "0px 5px", verticalAlign: "top" }}
        color="primary"
        size="small"
        onClick={onCancel}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}
