import { Logger } from "@stellar/web-core";
import { runtimeConfig } from "runtime-config";

// This must be called before any uncaught errors might occur
// Otherwise, those will not be reported
Logger.init({
  dsn: runtimeConfig.keys.sentryDsn,
  environment: `${runtimeConfig.deployment.topLevelDomain}-${runtimeConfig.deployment.environment}`,
  release: runtimeConfig.appVersion,
});
