import { Box, Divider, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FormContainer } from "components/forms/form-container";
import { HBSelect } from "components/hb-customs/hb-select";
import { HBEnumMenuItems } from "components/hb-customs/hb-menu-items";
import { Header } from "components/header";
import { HBNumberInput } from "components/hb-customs/hb-number-input";
import { AdminAPITypes } from "@stellar/api-logic";
import { ContentLayout } from "pages/content-layout";
import { StandaloneProjectToggle } from "components/hb-customs/hb-standalone-project-toggle";
import React from "react";
import { useNavigation } from "router/use-navigation";
import { useProjectCreationContext } from "pages/create-project/project-creation-context";
import { isCreateProjectForAccount } from "utils/type-guards";
import { CreateProjectBreadcrumb } from "./create-project-breadcrumb";
import {
  formatCreatedProjectSubject,
  isValidEmail,
} from "./create-project-helper";

export function CreateProjectPage(): JSX.Element {
  const projectCreationContext = useProjectCreationContext();
  const { navigateToProfilePage } = useNavigation();

  const {
    subject,
    projectName,
    setProjectName,
    projectAddress,
    setProjectAddress,
    projectArea,
    setProjectArea,
    projectAreaUnit,
    setProjectAreaUnit,
    projectManager,
    setProjectManager,
    isStandalone,
    setIsStandalone,
    setShouldShowSummary,
  } = projectCreationContext;

  function isProjectCreationDataValid(): boolean {
    return !!(
      projectName &&
      projectAddress &&
      projectArea &&
      isValidEmail(projectManager)
    );
  }

  function onCancelClicked(): void {
    const subjectId = isCreateProjectForAccount(subject)
      ? subject.account.identity
      : subject.company.id;

    navigateToProfilePage(subject.type, subjectId);
  }

  return (
    <ContentLayout
      breadcrumbs={<CreateProjectBreadcrumb subject={subject} />}
      header={<Header heading="Create New Project" shouldUseSlimVariant />}
    >
      <FormContainer
        sx={{
          width: "550px",
          ml: "110px",
          mt: "10px",
          "& .MuiFormControl-root": {
            mt: "20px",
          },
        }}
        handleCancelClicked={onCancelClicked}
        handleCreateClicked={() => setShouldShowSummary(true)}
        shouldCreateButtonBeDisabled={!isProjectCreationDataValid()}
      >
        <Box sx={{ fontSize: "20px", marginBottom: "20px" }}>
          Project Details
        </Box>

        <Grid container>
          {/* project name */}
          <TextField
            label="Project Name"
            variant="outlined"
            value={projectName}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setProjectName(event.target.value);
            }}
            required
            fullWidth
          />

          {/* project address */}
          <TextField
            label="Project Address"
            variant="outlined"
            value={projectAddress}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setProjectAddress(event.target.value);
            }}
            required
            fullWidth
          />

          <Grid item container spacing={1}>
            <Grid item xs={7}>
              {/* project area */}
              <HBNumberInput
                label="Project Area"
                value={projectArea}
                onChange={setProjectArea}
                minValue={1}
                isRequired
                isIntegerValue
                isFullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={5}>
              {/* product type */}
              <HBSelect
                label="Project Area Unit"
                value={projectAreaUnit}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setProjectAreaUnit(
                    event.target.value as AdminAPITypes.EAreaUnit
                  );
                }}
                variant="outlined"
                isFullWidth
              >
                {HBEnumMenuItems({ fromEnum: AdminAPITypes.EAreaUnit })}
              </HBSelect>
            </Grid>

            {/* standalone projects */}
            <StandaloneProjectToggle {...{ isStandalone, setIsStandalone }} />
          </Grid>
        </Grid>

        <Divider sx={{ margin: "20px 0px 10px 0px" }} />

        <Box sx={{ fontSize: "20px", marginBottom: "20px" }}>
          Project Assignment
        </Box>

        <Grid container>
          {/* Subject Type */}
          <TextField
            label="Add To"
            value={formatCreatedProjectSubject(subject)}
            variant="outlined"
            fullWidth
            disabled
          />

          {/* Project Manager */}
          {isCreateProjectForAccount(subject) ? (
            <TextField
              label={"Project Manager (email address)"}
              variant="outlined"
              value={projectManager}
              onChange={(event: React.ChangeEvent<{ value: string }>) =>
                setProjectManager(event.target.value)
              }
              fullWidth
              disabled={isCreateProjectForAccount(subject)}
            />
          ) : (
            <Autocomplete
              defaultValue={projectManager}
              value={projectManager}
              options={subject.groupMembers.map(({ user }) => user.email)}
              freeSolo
              autoHighlight
              onInputChange={(_, value) => setProjectManager(value)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label={"Project Manager"}
                  variant="outlined"
                />
              )}
            />
          )}
        </Grid>
      </FormContainer>
    </ContentLayout>
  );
}
