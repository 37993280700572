import { SvgIcon, SvgIconTypeMap } from "@mui/material";
import { ReactComponent as FlagOfEurope } from "icons/flag_of_europe.svg";
import HBLogoSVG from "icons/hb_logo_white.svg";
import React from "react";

type EuropeFlagIconProps = Pick<
  SvgIconTypeMap["props"],
  // Allow to change size of flag
  "fontSize"
>;

export function HBEuropeFlagIcon({
  fontSize,
}: EuropeFlagIconProps): JSX.Element {
  return (
    <SvgIcon {...{ fontSize }} component={FlagOfEurope} viewBox="0 0 810 540" />
  );
}

export function HBLogoIcon(): JSX.Element {
  return (
    <img alt="HoloBuilder Logo" src={HBLogoSVG} height="32px" color="grey" />
  );
}
