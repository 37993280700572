import { AccountBreadcrumbs } from "components/breadcrumbs/account-breadcrumbs";
import { CompanyBreadcrumbs } from "components/breadcrumbs/company-breadcrumbs";
import React from "react";
import { isCreateProjectForAccount } from "utils/type-guards";
import { ISubjectProject } from "./project-creation-context-types";

interface ICreateProjectBreadcrumbProps {
  /** The subject of the project which it belongs to */
  subject: ISubjectProject;
}

/**
 * Decide what type of breadcrumb to show depending on the subject type
 */
export function CreateProjectBreadcrumb({
  subject,
}: ICreateProjectBreadcrumbProps): JSX.Element {
  return isCreateProjectForAccount(subject) ? (
    <AccountBreadcrumbs account={subject.account} />
  ) : (
    <CompanyBreadcrumbs company={subject.company} />
  );
}
