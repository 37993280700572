import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { BreadcrumbsData } from "./breadcrumbs-data";
import { HBBreadcrumbs } from "./hb-breadcrumbs";

interface IAccountBreadcrumbsProps {
  /** Object represents current account to generate breadcrumb out of it */
  account: AdminAPITypes.IAdmUser;
}

/**
 * Display breadcrumbs for a user account
 * Will include the company if user is part of a company
 */
export function AccountBreadcrumbs({
  account,
}: IAccountBreadcrumbsProps): JSX.Element {
  return (
    <HBBreadcrumbs breadcrumbsData={[BreadcrumbsData.forAccount(account)]} />
  );
}
