import { APITypes } from "@stellar/api-logic";
import { generatePath } from "react-router-dom";

export const AccountRouteParam = "accountId";

const AccountRouteFragments = {
  root: "/user",
  account: `/:${AccountRouteParam}`,
  addProject: "/add-project",
};

export const AccountRoute = {
  /**
   * Provides the path for the profile page of a specific account
   */
  forProfile(accountId: APITypes.UserId): string {
    return generatePath(AccountProfileRoute, { accountId });
  },

  /**
   * Provides the path for the project creation page for a specific account
   */
  forAddProject(accountId: APITypes.UserId): string {
    return generatePath(AccountAddProjectRoute, { accountId });
  },
};

export const AccountRouterRoot = `${AccountRouteFragments.root}`;

export const AccountProfileRoute = `${AccountRouterRoot}${AccountRouteFragments.account}`;
export const AccountAddProjectRoute = `${AccountProfileRoute}${AccountRouteFragments.addProject}`;
