import { AdminAPITypes } from "@stellar/api-logic";
import React, { useContext } from "react";

export const AccountContext =
  React.createContext<AdminAPITypes.IAdmUser | null>(null);

export function useAccountContext(): AdminAPITypes.IAdmUser {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error(
      "useAccountContext() has to be used within a AccountContext."
    );
  }

  return context;
}
