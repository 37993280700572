import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { HBSpinner } from "components/hb-customs/hb-spinner";
import { AdminAPITypes } from "@stellar/api-logic";
import { PageNotFound } from "pages/page-not-found";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IRouterParams } from "router/route-params";
import { useCompany } from "utils/hooks/use-company";
import { useGroupsOfCompany } from "utils/hooks/use-groups-of-company";
import { useMembersOfGroup } from "utils/hooks/use-members-of-group";
import { CreateProjectContainer } from "./create-project-container";

/**
 * Fetch the company and group data and call the create project container with the needed data
 */
export function CreateProjectForCompany(): JSX.Element {
  const { companyId, groupId } = useParams<IRouterParams>();

  const [selectedGroup, setSelectedGroup] =
    useState<AdminAPITypes.ICompanyGroup>();

  const { handleErrorWithPage } = useErrorHandler();

  const [company, isFetchingCompany] = useCompany(
    companyId,
    handleErrorWithPage
  );

  const [groups, isFetchingGroups] = useGroupsOfCompany(
    companyId,
    handleErrorWithPage
  );

  const [groupMembers, isFetchingGroupMembers] = useMembersOfGroup(
    companyId,
    groupId,
    handleErrorWithPage
  );

  // Find the selected group information from the provided groupId
  useEffect(() => {
    setSelectedGroup(groups.find(({ id }) => id === groupId));
  }, [groupId, groups]);

  // TODO: isFetchingGroupMembers can be passed to CreateProjectContainer and show a spinner for project manager field
  if (isFetchingCompany || isFetchingGroups || isFetchingGroupMembers) {
    return <HBSpinner />;
  } else if (!company || !selectedGroup) {
    return <PageNotFound />;
  }

  return (
    <CreateProjectContainer
      subject={{
        type: AdminAPITypes.ESubjectType.company,
        company,
        group: selectedGroup,
        groupMembers,
      }}
    />
  );
}
