import { Header } from "components/header";
import { IRouterParams } from "router/route-params";
import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { useParams } from "react-router-dom";
import { CreatePlanContainer } from "./create-plan-container/create-plan-container";
import { ContentLayout } from "../content-layout";
import { useErrorHandler } from "../../components/error-boundary/error-handling-context";
import { CompanyBreadcrumbs } from "components/breadcrumbs/company-breadcrumbs";
import { useCompany } from "utils/hooks/use-company";
import { LoadingScreen } from "components/loading-screen";

export function CreateCompanyPlanPage(): JSX.Element {
  const { companyId } = useParams<IRouterParams>();

  const { handleErrorWithPage } = useErrorHandler();

  const [company, isLoading] = useCompany(companyId, handleErrorWithPage);

  return (
    <LoadingScreen isFetching={!company || isLoading}>
      {company && (
        <ContentLayout
          breadcrumbs={<CompanyBreadcrumbs company={company} />}
          header={
            <Header heading="Create New Company Plan" shouldUseSlimVariant />
          }
        >
          <CreatePlanContainer
            planSubjectType={AdminAPITypes.ESubjectType.company}
            planSubjectName={company.name}
            planSubjectId={companyId}
          />
        </ContentLayout>
      )}
    </LoadingScreen>
  );
}
