import { Box, Divider } from "@mui/material";
import React from "react";
import { FormContainer } from "components/forms/form-container";
import { Header } from "components/header";
import { InfoRow } from "components/info-grid/info-row";
import { ContentLayout } from "pages/content-layout";
import { formatProjectArea } from "utils/data-display";
import { useProjectCreationContext } from "./project-creation-context";
import { CreateProjectBreadcrumb } from "./create-project-breadcrumb";
import { formatCreatedProjectSubject } from "./create-project-helper";

interface IProjectSummaryPageProps {
  /** Function called when the user clicks on the "confirm" button */
  onConfirmCreation(): void;
}

export function ProjectSummaryPage({
  onConfirmCreation,
}: IProjectSummaryPageProps): JSX.Element {
  const projectCreationContext = useProjectCreationContext();

  const {
    subject,
    setShouldShowSummary,
    projectName,
    projectAddress,
    projectArea,
    projectAreaUnit,
    projectManager,
    isStandalone,
  } = projectCreationContext;

  return (
    <ContentLayout
      breadcrumbs={<CreateProjectBreadcrumb subject={subject} />}
      header={<Header heading="Project Summary" shouldUseSlimVariant />}
    >
      <FormContainer
        sx={{
          width: "550px",
          ml: "110px",
          mt: "10px",
          "& .MuiFormControl-root": {
            mt: "20px",
          },
        }}
        cancelButtonText="Back"
        handleCancelClicked={() => setShouldShowSummary(false)}
        createButtonText="Confirm"
        handleCreateClicked={onConfirmCreation}
      >
        <p>Confirm the project details.</p>
        <Box sx={{ fontSize: "20px", mb: "20px" }}>Project Details</Box>

        <Box sx={{ pl: "10px" }}>
          <InfoRow
            labelTextAlign="left"
            label={"Project Name"}
            info={projectName}
          />

          <InfoRow
            labelTextAlign="left"
            label={"Project Address"}
            info={projectAddress}
          />

          <InfoRow
            labelTextAlign="left"
            label={"Project Area"}
            info={formatProjectArea({
              amount: projectArea ?? 0,
              unit: projectAreaUnit,
            })}
          />

          <InfoRow
            labelTextAlign="left"
            label="Standalone"
            info={isStandalone ? "Yes" : "No"}
          />

          <Divider sx={{ margin: "20px 0px 10px 0px" }} />

          <Box sx={{ fontSize: "20px", marginBottom: "20px" }}>
            Project Assignment
          </Box>

          <InfoRow
            labelTextAlign="left"
            label={"Add To"}
            info={formatCreatedProjectSubject(subject)}
          />

          <InfoRow
            labelTextAlign="left"
            label={"Project Manager"}
            info={projectManager}
          />
        </Box>
      </FormContainer>
    </ContentLayout>
  );
}
