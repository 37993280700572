import { Link } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface IHBLinkProps {
  /** URL of the link */
  href: string;

  /** Text to be shown on hover */
  title?: string;
}

/** Custom Link component for HB */
export function HBLink({
  children,
  href,
  title,
}: PropsWithChildren<IHBLinkProps>): JSX.Element {
  return (
    <Link
      sx={{ textDecoration: "underline" }}
      href={href}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
}
