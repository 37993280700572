import { AdminAPITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  CustomProductIdentification,
} from "store/products/products-slice-helper-types";

/** Returns true if the product is a single feature */
export function isProductSingleFeature(product: BaseProduct): boolean {
  return product.type === "feature";
}

/** Returns true if the product is a custom feature */
export function isProductCustomFeature(product: BaseProduct): boolean {
  return product.type === "custom";
}

/** Maps the custom product ID to the bundle identifications that will be added with it */
export const customProductIdToBundleIdentification: Record<
  CustomProductIdentification,
  AdminAPITypes.EFeatureBundleIdentifier[]
> = {
  standard360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],
  standardPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
  ],
  pro360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],
  proPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
  ],
  enterprise360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],
  enterprisePointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
  ],
  enterprise360PointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
  ],
};
