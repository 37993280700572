import { ListItemText } from "@mui/material";
import React, { useCallback } from "react";
import { BaseProduct } from "store/products/products-slice-helper-types";
import { Color } from "utils/styles/default-colors";

// TODO: It can later become generic and part of HBSelect
/** Returns a callback that renders a listItem text with a value for product related dropdown */
export function useProductItem(): {
  (products: BaseProduct[]): {
    label: JSX.Element;
    value: string;
  }[];
} {
  return useCallback((products: BaseProduct[]) => {
    return products.map(({ name, description, identifier }) => ({
      label: (
        <ListItemText
          primary={name}
          secondary={description}
          sx={{
            margin: "0px",
            "& .MuiListItemText-secondary": {
              color: Color.grey39,
            },
          }}
        />
      ),
      value: identifier,
    }));
  }, []);
}
