import React from "react";
import { Grid, Typography } from "@mui/material";
import { IErrorWithTitle } from "./error-types";
import { ErrorBoundaryUtils } from "./error-boundary-utils";
import { Warning } from "@mui/icons-material";
import { HBButton } from "components/hb-customs/hb-button";

interface IProps {
  /** An error with user readable text and actual error */
  errorWithTitle: IErrorWithTitle;

  /** Callback when Reload button is clicked */
  onReload?(): void;

  /** Callback when Home button is clicked */
  onGoHome?(): void;
}

/** Displays an error message with stack trace and a reload button. */
export function ErrorPage({
  errorWithTitle,
  onReload: handleOnReload,
  onGoHome: handleOnGoHome,
}: IProps): JSX.Element {
  return (
    <Grid
      container
      sx={{
        maxWidth: "500px",
        marginLeft: "50%",
        transform: "translateX(-50%)",
        marginTop: "50px",
      }}
      direction="column"
      spacing={2}
      justifyContent="space-around"
    >
      <Grid item container direction="row" alignItems="center">
        <Warning sx={{ mr: "10px" }} />
        <Typography variant="h6">{errorWithTitle.title}</Typography>
      </Grid>

      <Grid item>
        <Typography component="div">
          <ErrorBoundaryUtils.GetErrorDisplayMessage
            error={errorWithTitle.error}
          />
        </Typography>
      </Grid>

      <Grid
        item
        sx={{
          alignSelf: "flex-end",
          "& Button": {
            marginLeft: "8px",
          },
        }}
      >
        <HBButton onClick={handleOnGoHome}>Home</HBButton>
        <HBButton onClick={handleOnReload}>Reload</HBButton>
      </Grid>
    </Grid>
  );
}
