import { Menu, MenuProps } from "@mui/material";
import React from "react";

interface IProps extends Pick<MenuProps, "anchorEl"> {
  /** Will be called when the menu is supposed to close (e.g. the user clicked somewhere outside of the menu) */
  onClose(): void;
}

/**
 * Menu that will open a popover below the provided anchor element, containing the children
 * The Menu will always be shown and the parent component needs to make sure to hide/remove it
 */
export function HBPopoverMenu({
  anchorEl,
  children,
  onClose: handleOnClose,
}: React.PropsWithChildren<IProps>): JSX.Element {
  return (
    <Menu
      // Element that the popover menu will attach to
      anchorEl={anchorEl}
      // Point on the anchor where the popover menu's anchorElement will attach to
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      // Point on the popover menu which will attach to the anchor's origin
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      // Menu will always be visible
      open
      // Callback fired when the component requests to be closed
      onClose={handleOnClose}
    >
      {children}
    </Menu>
  );
}
