import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

interface IHBRadioGroupProps {
  label: string;
  value: string | boolean;
  isRequired?: boolean;
  isFullWidth?: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  radioOptions: Record<string, unknown>;
}

function generateRadioItems(
  radioItemOptions: Record<string, unknown>
): JSX.Element[] {
  return Object.keys(radioItemOptions).map((key) => {
    return (
      <FormControlLabel
        key={key}
        value={radioItemOptions[key]}
        control={<Radio />}
        label={key}
      />
    );
  });
}

export function HBRadioGroup({
  label,
  value,
  isRequired,
  isFullWidth,
  onChange,
  radioOptions,
}: IHBRadioGroupProps): JSX.Element {
  return (
    <FormControl required={isRequired} fullWidth={isFullWidth}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup value={value} onChange={onChange} row={true}>
        {generateRadioItems(radioOptions)}
      </RadioGroup>
    </FormControl>
  );
}
