import {
  EntityAdapter,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { BaseEntityState } from "store/store-types";
import { ProductIdentifier, BaseProduct } from "./products-slice-helper-types";
import { AdminAPITypes } from "@stellar/api-logic";
import {
  allProducts,
  allAvailableCustomProducts,
} from "utils/product/product-list";
import {
  fetchNonSingleFeatures,
  fetchSingleFeatures,
} from "./products-slice-thunks";

/** State of the current product logic. The ID of the slice is equal to the identifier of the product */
export interface ProductsState extends BaseEntityState<BaseProduct> {
  /** The identifier of the selected product, either a feature, bundle or a subscription */
  selectedProductIdentifier: ProductIdentifier | null;

  /** The identifier of the selected add-on */
  selectedAddonIdentifier: AdminAPITypes.EFeatureBundleIdentifier | null;

  /** If the products are being fetched */
  isFetching: boolean;
}

/** Creates an entity adapter to store a map with all the products that are going to be updated */
export const ProductsAdapter: EntityAdapter<BaseProduct> = createEntityAdapter({
  selectId: (product) => product.identifier,
});

/** Fills the initial state with all the available custom products */
const filledState = ProductsAdapter.upsertMany(
  ProductsAdapter.getInitialState(),
  allAvailableCustomProducts
);

const initialState: ProductsState = {
  ...filledState,
  selectedProductIdentifier: null,
  selectedAddonIdentifier: null,
  isFetching: false,
};

/** Slice to access state of products */
const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    /** Set the selected product identifier */
    setSelectedProductIdentifier(
      state,
      action: PayloadAction<ProductIdentifier | null>
    ) {
      state.selectedProductIdentifier = action.payload;
    },

    /** Set the selected addon identifier */
    setSelectedAddonIdentifier(
      state,
      action: PayloadAction<AdminAPITypes.EFeatureBundleIdentifier | null>
    ) {
      state.selectedAddonIdentifier = action.payload;
    },

    /** Reset the whole product slice */
    resetProductsState: () => initialState,
  },

  extraReducers(builder) {
    builder
      .addCase(fetchSingleFeatures.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchSingleFeatures.fulfilled, (state, action) => {
        state.isFetching = false;

        const features = action.payload.allSingleFeatures.reduce<BaseProduct[]>(
          (acc, feature) => {
            const product = allProducts[feature.identifier];
            if (product) {
              acc.push({
                ...product,
                // The name and description of our definition has priority over what we receive from backend
                name: product.name ?? feature.name,
                description: product.description ?? feature.description,
              });
            }

            return acc;
          },
          []
        );

        ProductsAdapter.setMany(state, {
          ...features,
        });
      })
      .addCase(fetchSingleFeatures.rejected, (state, action) => {
        state.isFetching = false;
      })

      .addCase(fetchNonSingleFeatures.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchNonSingleFeatures.fulfilled, (state, action) => {
        state.isFetching = false;

        const nonSingleProducts = action.payload.allNonSingleFeatures.reduce<
          BaseProduct[]
        >((acc, bundle) => {
          const product = allProducts[bundle.identifier];
          if (product) {
            acc.push({
              ...product,
              // The name and description of our definition has priority over what we receive from backend
              name: product.name ?? bundle.name,
              description: product.description ?? bundle.description,
            });
          }
          return acc;
        }, []);

        ProductsAdapter.setMany(state, {
          ...nonSingleProducts,
        });
      })
      .addCase(fetchNonSingleFeatures.rejected, (state, action) => {
        state.isFetching = false;
      });
  },
});

export const {
  setSelectedProductIdentifier,
  setSelectedAddonIdentifier,
  resetProductsState,
} = productsSlice.actions;

export const productsReducer = productsSlice.reducer;
