import React from "react";
import { Box, Grid } from "@mui/material";
import { HBGridItem } from "components/hb-customs/hb-grid-item";
import { ContentLayout } from "pages/content-layout";
import { ProjectInfoFooter } from "components/footers";
import { ChangeAccountAuthProvider } from "../change-account-login-method";
import { useAccountContext } from "../account-context";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { Color } from "utils/styles/default-colors";

interface IProps {
  /** Id of the user entity that the account is related to */
  userId: APITypes.UserId;
}

/**
 * Content inside the account actions tab
 */
export function AccountActions({ userId }: IProps): JSX.Element {
  const account = useAccountContext();

  return (
    <ContentLayout
      footer={
        <ProjectInfoFooter
          subjectId={userId}
          subjectType={AdminAPITypes.ESubjectType.user}
        />
      }
    >
      <Grid container sx={{ padding: "16px", fontSize: "16px" }}>
        <HBGridItem width={12}>
          <Box sx={{ color: Color.black, pb: "16px" }}>
            Change Authentication Method
          </Box>
          <ChangeAccountAuthProvider
            userId={account.id}
            userName={`${account.firstName} ${account.lastName}`}
            providerId={account.providerId}
          />
        </HBGridItem>
      </Grid>
    </ContentLayout>
  );
}
