import { Header } from "components/header";
import { IRouterParams } from "router/route-params";
import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { useParams } from "react-router-dom";
import { CreatePlanContainer } from "./create-plan-container/create-plan-container";
import { ContentLayout } from "../content-layout";
import { useErrorHandler } from "../../components/error-boundary/error-handling-context";
import { useProject } from "utils/hooks/use-project";
import { ProjectBreadcrumbs } from "components/breadcrumbs/project-breadcrumbs";
import { LoadingScreen } from "components/loading-screen";

export function CreateProjectPlanPage(): JSX.Element {
  const { projectId } = useParams<IRouterParams>();

  const { handleErrorWithPage } = useErrorHandler();

  const [project, isFetchingProject] = useProject(
    projectId,
    handleErrorWithPage
  );

  return (
    <LoadingScreen isFetching={!project || isFetchingProject}>
      {project && (
        <ContentLayout
          breadcrumbs={<ProjectBreadcrumbs project={project} />}
          header={
            <Header heading="Create New Project Plan" shouldUseSlimVariant />
          }
        >
          <CreatePlanContainer
            planSubjectType={AdminAPITypes.ESubjectType.project}
            planSubjectName={project.name}
            planSubjectId={projectId}
          />
        </ContentLayout>
      )}
    </LoadingScreen>
  );
}
