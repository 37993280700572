import { InfoGrid } from "components/info-grid/info-grid";
import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { usePlanSubscription } from "utils/hooks/plan/use-plan-subscription";
import { Constraints } from "components/constraints";
import { ILabelInfoPair } from "components/info-grid/info-row-types";
import {
  formatBillingCycleOfRecurringPlan,
  formatPlanName,
  getAccountDisplayName,
  uppercaseFirst,
} from "utils/data-display";
import { PlanDataExtractor } from "utils/plan-data-extractor";
import { isRecurringPlan } from "utils/type-guards";
import { AccountEmail } from "./account-email";
import {
  PaymentProviderInvoiceLink,
  PaymentProviderSubscriptionLink,
} from "components/links/external-links";
import { LoadingScreen } from "components/loading-screen";
import { DateTimeUtils } from "@stellar/web-core";
import { InlineEditableTextField } from "components/inline-editable-text-field";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { usePlansContext } from "contexts/plans-context";
import { useUpdatePlan } from "utils/hooks/plan/use-update-plan";
import { PlanUtcDate } from "./plan-utc-date";

interface IProps {
  /** The plan to show the details of */
  plan: AdminAPITypes.IPlan;
}

/**
 * Shows an InfoGrid containing a plan's details.
 * Fetches additional information about a plan's subscription if needed.
 */
export function PlanInfoGrid({ plan }: IProps): JSX.Element {
  const [subscription, isLoadingSubscription] = usePlanSubscription(plan);

  return (
    <LoadingScreen isFetching={isLoadingSubscription}>
      <InfoGrid
        leftColumnRows={CreateLeftPlanInfoColumnRows(plan, subscription)}
        rightColumnRows={createRightPlanInfoColumnRows(plan, subscription)}
      />
    </LoadingScreen>
  );
}

/**
 * Creates the left column of ILabelInfoPair for the table used in a plan-info-grid component
 */
function CreateLeftPlanInfoColumnRows(
  plan: AdminAPITypes.IPlan,
  subscription: AdminAPITypes.IPaymentProviderSubscription | null
): ILabelInfoPair[] {
  const { fetchPlans } = usePlansContext();
  const { handleErrorWithDialog } = useErrorHandler();

  const { updatePlan } = useUpdatePlan(handleErrorWithDialog);

  const leftColumnRows: ILabelInfoPair[] = [
    { label: "Name:", info: formatPlanName(plan) },
  ];

  if (subscription) {
    leftColumnRows.push({
      label: "Stripe Subscription:",
      info: <PaymentProviderSubscriptionLink subscription={subscription} />,
    });
  }

  leftColumnRows.push(
    ...[
      createSubscriberInfoRowProps(plan),
      { label: "Recurring:", info: isRecurringPlan(plan) ? "Yes" : "No" },
      {
        label: "Start Date:",
        info: <PlanUtcDate date={plan.startDate} />,
      },
    ]
  );

  const planEndDate = PlanDataExtractor.getEndDate(plan);

  async function onCommentChanged(comment: string): Promise<void> {
    await updatePlan(plan, { description: comment });

    fetchPlans();
  }

  if (planEndDate) {
    leftColumnRows.push({
      label: "End Date:",
      info: <PlanUtcDate date={planEndDate} />,
    });
  }

  return leftColumnRows.concat([
    {
      label: "Limits:",
      info: <Constraints constraints={plan.constraints} />,
    },
    {
      label: "Comments:",
      info: (
        <InlineEditableTextField
          initialText={plan.description}
          isMultiLine={true}
          onConfirmCallback={onCommentChanged}
        />
      ),
    },
  ]);
}

/**
 * Creates the right column of ILabelInfoPair for the table used in a plan-info-grid component
 */
function createRightPlanInfoColumnRows(
  plan: AdminAPITypes.IPlan,
  subscription: AdminAPITypes.IPaymentProviderSubscription | null
): ILabelInfoPair[] {
  const rightColumnRows: ILabelInfoPair[] = [];

  if (subscription && isRecurringPlan(plan)) {
    rightColumnRows.push(
      ...[
        {
          label: "Invoice:",
          info: <PaymentProviderInvoiceLink subscription={subscription} />,
        },
        {
          label: "Customer:",
          info: PlanDataExtractor.Payment.getPaymentProviderCustomer(plan)
            .email,
        },
        {
          label: "Billing Cycle:",
          info: formatBillingCycleOfRecurringPlan(plan),
        },
        {
          label: "Price per unit:",
          info: PlanDataExtractor.Payment.getPrice(plan),
        },
      ]
    );

    const daysUntilDue = PlanDataExtractor.Payment.getDaysUntilDue(plan);
    if (daysUntilDue !== undefined) {
      rightColumnRows.push({
        label: "Payment Due Date:",
        info: `${daysUntilDue} days`,
      });
    }
  }

  rightColumnRows.push(
    {
      label: "Created by:",
      info: <AccountEmail accountId={plan.createdBy} />,
    },
    {
      label: "Created date:",
      info: DateTimeUtils.format({ date: plan.createdAt }),
    },
    {
      label: "Status:",
      info: uppercaseFirst(plan.status.identifier),
    }
  );

  if (plan.status.identifier === "invalid" && plan.status.message) {
    rightColumnRows.push({
      label: "Invalidation Reason:",
      info: <b>{plan.status.message}</b>,
    });
  }

  return rightColumnRows;
}

/**
 * Creates the ILabelInfoPair for the Subscriber field that varies with the subscription type.
 */
function createSubscriberInfoRowProps(
  plan: AdminAPITypes.IPlan
): ILabelInfoPair {
  switch (plan.subscriber.type) {
    case AdminAPITypes.ESubscriberType.company: {
      return {
        label: "Company:",
        info: plan.subscriber.obj.name,
      };
    }
    case AdminAPITypes.ESubscriberType.user: {
      return {
        label: "User:",
        info: getAccountDisplayName(plan.subscriber.obj),
      };
    }
  }
}
