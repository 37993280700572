import { AdminAPITypes } from "@stellar/api-logic";
import { AccountRoute } from "router/account/account-routes";
import { CompanyRoute } from "router/company/company-routes";
import { ProjectRoute } from "router/project/project-routes";
import { getAccountDisplayName } from "utils/data-display";
import { IBreadcrumbsData } from "./breadcrumbs-types";

/**
 * Helper module for generating breadcrumb data for different data types
 */
export const BreadcrumbsData = {
  /**
   * Generate breadcrumb data for a project
   */
  forProject(project: AdminAPITypes.IAdmProject): IBreadcrumbsData {
    return {
      label: project.name,
      link: ProjectRoute.forProfile(project.id),
    };
  },

  /**
   * Generate breadcrumb data for a user account
   */
  forAccount(account: AdminAPITypes.IAdmUser): IBreadcrumbsData {
    return {
      label: getAccountDisplayName(account),
      link: AccountRoute.forProfile(account.identity),
    };
  },

  /**
   * Generate breadcrumb data for a company
   */
  forCompany(company: AdminAPITypes.IAdmCompany): IBreadcrumbsData {
    return {
      label: company.name,
      link: CompanyRoute.forProfile(company.id),
    };
  },
};
