import React, { useState } from "react";
import { Box, InputBase, useTheme } from "@mui/material";
import { Color } from "utils/styles/default-colors";
import { InputControls } from "./input-controls";

interface IInlineEditableTextFieldProps {
  /** The initial text that is shown in the textarea */
  initialText: string;

  /** Determines whether the text is shown in multiple lines or just one line */
  isMultiLine?: boolean;

  /** A callback that is executed on confirm */
  onConfirmCallback(text: string): void;
}

/**
 * Show a text inside a textarea.
 * By clicking on it, the textarea becomes editable and two buttons will be added on the right.
 * The buttons will either approve or discard the changes
 */
export function InlineEditableTextField({
  initialText,
  isMultiLine = false,
  onConfirmCallback,
}: IInlineEditableTextFieldProps): JSX.Element {
  const theme = useTheme();

  const [text, setText] = useState<string>(initialText);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  function handleConfirm(): void {
    onConfirmCallback(text);
    setIsEditMode(false);
  }

  function handleCancel(): void {
    setText(initialText);
    setIsEditMode(false);
  }

  /**
   * Change the isEditMode to true only if the InputBase is disabled
   * This is needed as the EditModeButtons are located inside the InputBase
   * and therefore clicking them also triggers onClick
   */
  function onClickHandler(): void {
    if (!isEditMode) {
      setIsEditMode(true);
    }
  }

  return (
    <Box
      sx={{
        "& .Mui-disabled": {
          color: Color.grey46,
          alignItems: "flex-start",

          "&:hover": {
            backgroundColor: Color.grey15,
          },
        },

        "& .MuiInputBase-root": {
          alignItems: "flex-end",
        },

        "& .Mui-focused": {
          borderRadius: "4px",
          backgroundColor: theme.palette.background.default,
          border: "1px solid #ced4da",
        },
      }}
    >
      <InputBase
        sx={{
          fontSize: "14px",
          width: "85%",

          "& .MuiInputBase-input": {
            textOverflow: "ellipsis",
            paddingTop: "0px",
          },
        }}
        multiline={isMultiLine}
        minRows={3}
        value={text}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          setText(event.target.value);
        }}
        // This makes it possible to focus on the InputBase on first click
        inputRef={(inputBase) => inputBase && inputBase.focus()}
        required
        disabled={!isEditMode}
        onClick={onClickHandler}
        title={!isEditMode ? "Click to Edit" : undefined}
        endAdornment={
          isEditMode && (
            <InputControls
              isConfirmButtonDisable={!text}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )
        }
      />
    </Box>
  );
}
