import {
  AccountLink,
  CompanyLink,
  ProjectLink,
} from "components/links/internal-links";
import { AdminAPITypes } from "@stellar/api-logic";
import React from "react";
import { getPlanAssigneeName } from "utils/data-display";

interface IProps {
  /** Plan to show a subject link for. */
  plan: AdminAPITypes.IPlan;
}

/**
 * Component showing a clickable admin-panel link to a plan's subject.
 */
export function PlanSubjectLink({ plan }: IProps): JSX.Element {
  const formattedName = getPlanAssigneeName(plan);

  switch (plan.subject.type) {
    case AdminAPITypes.ESubjectType.company:
      return (
        <CompanyLink companyId={plan.subject.id}>{formattedName}</CompanyLink>
      );
    case AdminAPITypes.ESubjectType.project:
      return (
        <ProjectLink projectId={plan.subject.id}>{formattedName}</ProjectLink>
      );
    case AdminAPITypes.ESubjectType.user:
      return (
        <AccountLink accountId={plan.subject.id}>{formattedName}</AccountLink>
      );
    case AdminAPITypes.ESubjectType.device:
    default:
      return <span>{formattedName}</span>;
  }
}
