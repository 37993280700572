import React from "react";
import { DateTimeUtils } from "@stellar/web-core";

/** Props for the OptionalDate component */
interface IProps {
  /** The date to display */
  date?: DateTimeUtils.DateTimeInput;
}

/**
 * Shows a formatted date if it exists.
 * Shows "never" if the date is undefined.
 */
export function OptionalDate({ date }: IProps): JSX.Element {
  if (!date) {
    return <i>never</i>;
  }

  return <span>{DateTimeUtils.format({ date })}</span>;
}
