import { HBSpinnerInline } from "components/hb-customs/hb-spinner";
import React from "react";
import { useLoggedInUser } from "utils/hooks/use-logged-in-user";

export function UserMailAddress(): JSX.Element {
  const [loggedInUser, isFetching] = useLoggedInUser();

  return isFetching ? (
    <HBSpinnerInline />
  ) : (
    <span>{loggedInUser?.mailAddress}</span>
  );
}
