import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { HBCollapsibleTableRow } from "components/tables/hb-collapsible-table-row";
import { usePlansContext } from "contexts/plans-context";
import { AdminAPITypes } from "@stellar/api-logic";
import React, { ReactNode, useState } from "react";
import { Color } from "utils/styles/default-colors";
import {
  DASH,
  formatPlanName,
  getFormattedConstraints,
  uppercaseFirst,
} from "utils/data-display";
import { PlanDataExtractor } from "utils/plan-data-extractor";
import { HBSortableTable } from "./hb-sortable-table";
import { PlanStatusTableHeader } from "./plan-status-table-header";
import { Compare } from "utils/compare";
import { SortableColumn } from "./hb-table-types";
import { PlanInfoGrid } from "../plan-info-grid/plan-info-grid";
import { PlanSubjectLink } from "./plan-subject-link";
import WarningIcon from "@mui/icons-material/Warning";
import { DateTimeUtils } from "@stellar/web-core";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { LoadingScreen } from "components/loading-screen";
import { isInTheLast30Days, isInTheNext30Days } from "utils/date-time";
import { useUpdatePlan } from "utils/hooks/plan/use-update-plan";
import { DeactivatePlanDialog } from "./deactivate-plan-dialog";

/**
 * Renders a list of plans in a sortable table with collapsible table rows.
 * Can only be used within a PlansContext.
 */
export function PlansTable(): JSX.Element {
  // List of plans to render in this table
  const { plans, fetchPlans } = usePlansContext();
  const { handleErrorWithDialog } = useErrorHandler();

  const { updatePlan } = useUpdatePlan(handleErrorWithDialog);

  const [selectedPlan, setSelectedPlan] =
    useState<AdminAPITypes.IPlan | null>(null);
  const [isDeactivatingPlan, setIsDeactivatingPlan] = useState<boolean>(false);

  const [shouldFilterPlans, setShouldFilterPlans] = useState<boolean>(false);

  const filteredPlans = plans.filter(
    (plan) =>
      !(
        plan.status.identifier === "deactivated" ||
        plan.status.identifier === "inactive"
      )
  );

  /**
   * Function called when the user cancels the deactivation of a plan
   */
  function onCancelPlanDeactivation(): void {
    setSelectedPlan(null);
  }

  /**
   * Function called when the user confirms the deactivation of a plan
   */
  async function onConfirmPlanDeactivation(
    plan: AdminAPITypes.IPlan,
    updatedComment: string
  ): Promise<void> {
    setIsDeactivatingPlan(true);

    await updatePlan(plan, {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- backend variable definition
      deactivated: true,
      description: updatedComment,
    });

    fetchPlans();

    setSelectedPlan(null);

    setIsDeactivatingPlan(false);
  }

  return (
    <LoadingScreen isFetching={isDeactivatingPlan}>
      <>
        <FormControlLabel
          label="Hide inactive/deactivated plans"
          labelPlacement="start"
          control={
            <Switch
              checked={shouldFilterPlans}
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Naming is clearer this way
              onChange={() => setShouldFilterPlans((prevCheck) => !prevCheck)}
              color="secondary"
            />
          }
        />
        <HBSortableTable
          columns={columns}
          tableData={shouldFilterPlans ? filteredPlans : plans}
          renderTableRow={(plan) => {
            let dropdownMenuItems;

            if (plan.status.identifier !== "deactivated") {
              dropdownMenuItems = [
                {
                  label: "Deactivate Plan",
                  onClickHandler: async () => {
                    setSelectedPlan(plan);
                  },
                },
              ];
            }

            return (
              <HBCollapsibleTableRow
                dropdownMenuItems={dropdownMenuItems}
                key={plan.id}
                name={formatPlanName(plan)}
                cellNodes={createCellNodesForPlan(plan)}
                backgroundColor={pickStatusColor(plan.status.identifier)}
              >
                <PlanInfoGrid plan={plan} />
              </HBCollapsibleTableRow>
            );
          }}
          isSelectable={false}
          orderById={"status"}
        />

        {selectedPlan && (
          <DeactivatePlanDialog
            plan={selectedPlan}
            onCancel={onCancelPlanDeactivation}
            onConfirm={(updatedComment) =>
              onConfirmPlanDeactivation(selectedPlan, updatedComment)
            }
          />
        )}
      </>
    </LoadingScreen>
  );
}

function pickStatusColor(status: AdminAPITypes.PlanStatusIdentifier): string {
  switch (status) {
    case "active":
      return Color.planStatusActive;
    case "inactive":
      return Color.planStatusInactive;
    case "invalid":
      return Color.planStatusInvalid;
    case "deactivated":
      return Color.planStatusDeactivated;
    default:
      return "";
  }
}

// Column descriptors for the HBSortableTable used in the PlansTable component.
const columns: SortableColumn<AdminAPITypes.IPlan>[] = [
  { id: "name", label: "Plan", compareFunction: Compare.plansByName },
  {
    id: "limits",
    label: "Limits",
    compareFunction: Compare.plansByConstraints,
  },
  {
    id: "status",
    label: <PlanStatusTableHeader key={"Status"} />,
    compareFunction: Compare.plansByStatus,
  },
  {
    id: "subject",
    label: "Assigned To",
    compareFunction: Compare.plansBySubject,
  },
  {
    id: "assignee",
    label: "Assignee Name",
    compareFunction: Compare.plansByAssignee,
  },
  {
    id: "startDate",
    label: "Start Date",
    compareFunction: Compare.plansByStartDate,
  },
  {
    id: "endDate",
    label: "Expiration Date",
    compareFunction: Compare.plansByEndDate,
  },
  {
    id: "actions",
    label: "",
    shouldDisableSorting: true,
  },
];

/**
 * Use to create the cellValues for a plan to be used in the HBCollapsibleTableRow component
 */
function createCellNodesForPlan(plan: AdminAPITypes.IPlan): ReactNode[] {
  return [
    <div key="constraints">
      {getFormattedConstraints(plan.constraints)
        .split("\n")
        .map((constraint, index) => (
          <p key={index}>{constraint}</p>
        ))}
    </div>,
    uppercaseFirst(plan.status.identifier),
    plan.subject.type,
    <PlanSubjectLink plan={plan} key={plan.subject.id} />,
    DateTimeUtils.format({ date: plan.startDate }),
    <EndDateColumn
      endDate={PlanDataExtractor.getEndDate(plan)}
      key={plan.subject.id}
    />,
  ];
}

interface IEndDateColumnProps {
  /** The expiration date of a plan */
  endDate?: DateTimeUtils.DateTimeInput;
}

/**
 * Use to show the plan's end date and an alert icon if the end date is around the current date
 */
function EndDateColumn({ endDate }: IEndDateColumnProps): JSX.Element {
  if (!endDate) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
    return <>{DASH}</>;
  }

  return (
    <>
      {DateTimeUtils.format({ date: endDate })}

      {isInTheLast30Days(endDate) && (
        <Box
          component="span"
          title="Plan expired less than a month ago"
          sx={{ marginLeft: "10px" }}
        >
          <WarningIcon
            sx={{ verticalAlign: "text-bottom" }}
            color="error"
            fontSize="small"
          />
        </Box>
      )}

      {(isInTheNext30Days(endDate) || DateTimeUtils.isToday(endDate)) && (
        <Box
          component="span"
          title="Plan will expire in less than a month"
          sx={{ marginLeft: "10px" }}
        >
          <WarningIcon
            sx={{ verticalAlign: "text-bottom" }}
            color="secondary"
            fontSize="small"
          />
        </Box>
      )}
    </>
  );
}
