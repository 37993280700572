import { APITypes } from "@stellar/api-logic";
import { generatePath } from "react-router-dom";

export const ProjectRouteParam = "projectId";

const ProjectRouteFragments = {
  root: "/project",
  project: `/:${ProjectRouteParam}`,
  addPlan: "/add-plan",
};

export const ProjectRouterRoot = `${ProjectRouteFragments.root}`;
export const ProjectProfileRoute = `${ProjectRouterRoot}${ProjectRouteFragments.project}`;
export const ProjectAddPlanRoute = `${ProjectProfileRoute}${ProjectRouteFragments.addPlan}`;

export const ProjectRoute = {
  /**
   * Provides the path for the profile page of a specific project
   */
  forProfile(projectId: APITypes.ProjectId): string {
    return generatePath(ProjectProfileRoute, { projectId });
  },

  /**
   * Provides the path for the plan creation page for a specific project
   */
  forAddPlan(projectId: APITypes.ProjectId): string {
    return generatePath(ProjectAddPlanRoute, { projectId });
  },
};
