import React, { useContext } from "react";
import { IProjectCreationContext } from "./project-creation-context-types";

export const ProjectCreationContext =
  React.createContext<IProjectCreationContext | null>(null);

/**
 * Hook providing the ProjectCreationContext
 * Removes the need for making null-check whenever the context is used
 * Must be used within an ProjectCreationContext
 */
export function useProjectCreationContext(): IProjectCreationContext {
  const context = useContext(ProjectCreationContext);

  if (!context) {
    throw new Error(
      "useProjectCreationContext() has to be used within a ProjectCreationContext."
    );
  }

  return context;
}
