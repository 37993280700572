import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

/**
 * Use this hook to fetch the Account for a specific accountId.
 * Returns a tuple of the fetched account and a loading flag.
 * If the loading fails, the fetched account will be undefined.
 */
export function useAccount(
  accountId: APITypes.UserId,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IAdmUser | undefined, boolean] {
  const [account, setAccount] = useState<AdminAPITypes.IAdmUser>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Effect fetches the account once the component is mounted or the parameters changes.
  useEffect(() => {
    async function fetchAccount(accountId: APITypes.UserId): Promise<void> {
      setIsFetching(true);

      try {
        setAccount(await CoreApi.V0.ADMIN.getUserById(accountId));
      } catch (error) {
        errorHandler("Could not load User", error);
        setAccount(undefined);
      } finally {
        setIsFetching(false);
      }
    }

    fetchAccount(accountId);
  }, [accountId, errorHandler]);

  return [account, isFetching];
}
