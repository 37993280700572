import { Grid } from "@mui/material";
import React from "react";
import { Color } from "utils/styles/default-colors";

interface IHBFooterProps {
  /** Content shown in the left side of footer */
  leftProperty?: JSX.Element;

  /** Content shown in the left side of footer */
  rightProperties: JSX.Element | JSX.Element[];
}

/**
 * Shows a footer in the bottom of the page. The footer is divided into left and right section
 */
export function HBFooter({
  leftProperty,
  rightProperties,
}: IHBFooterProps): JSX.Element {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: Color.white,
        backgroundColor: Color.grey52,
        padding: "12px 47px",
      }}
    >
      <Grid item xs={6} container alignItems="center" spacing={2}>
        <Grid item>{leftProperty}</Grid>
      </Grid>

      <Grid
        item
        xs={6}
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {Array.isArray(rightProperties) ? (
          rightProperties.map((rightProperty, index) => (
            <Grid key={index} item>
              {rightProperty}
            </Grid>
          ))
        ) : (
          <Grid item>{rightProperties}</Grid>
        )}
      </Grid>
    </Grid>
  );
}
