import { CoreAPIUtils } from "@stellar/api-logic";
import { SanitizeUtils } from "@stellar/web-core";
import { StatusCodes } from "http-status-codes";
import React from "react";
import { useNavigation } from "router/use-navigation";
import { HandleableError } from "./error-types";

interface IGetErrorDisplayMessageProps {
  /** The error that is sent from different parts of the application */
  error: HandleableError;
}

export const ErrorBoundaryUtils = {
  /**
   * Converts errors of unknown shape into a displayable JSX element.
   */
  GetErrorDisplayMessage({ error }: IGetErrorDisplayMessageProps): JSX.Element {
    const { navigateToWorkspacePage } = useNavigation();

    let htmlErrorString: string | undefined;

    if (error) {
      if (typeof error === "string") {
        htmlErrorString = error;
      } else if (
        CoreAPIUtils.isResponseError(error) &&
        error.status === StatusCodes.FORBIDDEN
      ) {
        // Navigate to workspace page if the user is not logged in
        navigateToWorkspacePage();
      } else if (
        CoreAPIUtils.isResponseError(error) &&
        error.status === StatusCodes.INTERNAL_SERVER_ERROR
      ) {
        htmlErrorString = "Something went wrong";
      } else if ((error as Error).message) {
        // Try to find a message in the error-like object
        if (typeof (error as Error).message === "string") {
          htmlErrorString = (error as Error).message;
        } else {
          // Try to display the message stringified with JSON if it is an object.
          try {
            htmlErrorString = JSON.stringify((error as Error).message, null, 2);
          } catch (ignoreJsonError) {
            htmlErrorString = `${(error as Error).message}`;
          }
        }
      }

      if (CoreAPIUtils.isBaseResponse(error)) {
        htmlErrorString = `${htmlErrorString} (error status: ${error.status})`;
      }
    }

    if (!htmlErrorString) {
      htmlErrorString = "No message";
    }

    return (
      <div
        // eslint-disable-next-line react/no-danger -- the only place that we can use it
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention -- from npm package
          __html: SanitizeUtils.sanitizeString(htmlErrorString),
        }}
      />
    );
  },
};
