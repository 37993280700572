import React from "react";
import { Box, Grid } from "@mui/material";
import ARScene from "assets/ar-scene.png";
import GoToScene from "assets/go-to-scene.png";
import { HomeLink } from "components/links/internal-links";

/** Content to show when page is not available */
export function PageNotFound(): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundImage: `url(${ARScene})`,
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <HomeLink>
        <Box
          sx={{ height: "300px", cursor: "pointer" }}
          component="img"
          src={GoToScene}
          title="Go to HomePage"
          alt="Go to HomePage"
        />
      </HomeLink>
    </Grid>
  );
}
