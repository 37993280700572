import { useState } from "react";
import { ReactSetStateFunction } from "utils/utility-types";

/**
 * Hook for storing & handling an error object
 *
 * Will be used in the future to handle error depending on their type
 * E.g. log 500 BE errors but not 400 errors
 */
export function useErrorState<ErrorType = unknown>(): {
  error: ErrorType | null;
  setError: ReactSetStateFunction<ErrorType | null>;
} {
  const [error, setError] = useState<ErrorType | null>(null);

  return { error, setError };
}
