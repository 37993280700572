import { Grid } from "@mui/material";
import { InfoGrid } from "components/info-grid/info-grid";
import { ProjectInfoFooter } from "components/footers";
import { ILabelInfoPair } from "components/info-grid/info-row-types";
import React, { useState } from "react";
import { Clipboard } from "utils/clipboard";
import { ContentLayout } from "pages/content-layout";
import { useAccountContext } from "../account-context";
import { AdminAPITypes } from "@stellar/api-logic";
import { HBSnackbar } from "components/hb-customs/hb-snackbar";
import { SubjectEntityIdTypes } from "utils/utility-types";
import { ClipboardButton } from "components/buttons";

function getLeftColumnRows(account: AdminAPITypes.IAdmUser): ILabelInfoPair[] {
  return [
    { label: "First Name", info: account.firstName },
    { label: "Last Name", info: account.lastName },
    { label: "Email", info: account.email },
    {
      label: "Email Verified",
      info: account.emailVerified ? "Yes" : "No",
    },
  ];
}

/**
 * This props is specifically used instead of account.id, since ProjectInfoFooter requires type SubjectEntityIdTypes.
 * Adding ProjectInfoFooter here is a workaround (details: ARWEB-18835),
 * therefore refrained from changing other, deeper files.
 */
interface IProps {
  /** ID of the subject entity that the plan tab is related to */
  userId: SubjectEntityIdTypes;
}

/**
 * Content inside the account information tab
 */
export function AccountInfo({ userId }: IProps): JSX.Element {
  const account = useAccountContext();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  function handleClipboardButtonClick(): void {
    Clipboard.copyAccountInfo(account);
    setIsSnackbarOpen(true);
  }

  return (
    <ContentLayout
      footer={
        <ProjectInfoFooter
          subjectId={userId}
          subjectType={AdminAPITypes.ESubjectType.user}
          options={{ shouldHideCreateProjectButton: true }}
        />
      }
    >
      <Grid container wrap="nowrap" sx={{ padding: "47px" }}>
        <InfoGrid
          leftColumnRows={getLeftColumnRows(account)}
          rightColumnRows={[
            {
              label: "Job Title",
              info: account.registrationInfo.jobTitle,
            },
            {
              label: "360 Camera",
              info: account.registrationInfo.hasCamera ? "Yes" : "No",
            },
            { label: "Country", info: account.country },
          ]}
        />

        <ClipboardButton onClick={handleClipboardButtonClick} />

        <HBSnackbar
          isOpen={isSnackbarOpen}
          onClose={() => setIsSnackbarOpen(false)}
        >
          Copied to Clipboard!
        </HBSnackbar>
      </Grid>
    </ContentLayout>
  );
}
