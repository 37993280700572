import { AdminAPITypes, APITypes, CoreAPITypes } from "@stellar/api-logic";
import { DataFormatter } from "@stellar/web-core";

// [DOC]
// Functions in this file are intended to create strings
// out of specific objects and format them so they can be
// displayed in a human-readable form. Because these functions
// have a very similar purpose it makes sense to give them a
// consistent naming schema.
// That's why all functions in this file should be prefixed with "format"

/** This constant is used to show a dash when no data is available, e.g. table's cell is empty */
export const DASH = "—";

/** Used when as plan has no set limits */
export const UNLIMITED = "Unlimited";

/** Used when an exception occurs */
export const UNDEFINED = "Undefined";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const GIGA_BYTE_SIZE = 1024 ** 3;

/**
 * Returns a name to identify a plan.
 * Currently the first assigned featureBundle- or feature-name is be used.
 */
export function formatPlanName(plan: AdminAPITypes.IPlan): string {
  if (plan.featureBundles[0]?.name && plan.featureBundles[0].name !== "") {
    return plan.featureBundles[0].name;
  } else if (plan.features[0]?.name && plan.features[0].name !== "") {
    return plan.features[0].name;
  }
  return DASH;
}

export function getPlanAssigneeName(plan: AdminAPITypes.IPlan): string {
  switch (plan.subject.type) {
    case AdminAPITypes.ESubjectType.project:
    case AdminAPITypes.ESubjectType.company:
    case AdminAPITypes.ESubjectType.folder: {
      return plan.subject.obj.name;
    }
    case AdminAPITypes.ESubjectType.device: {
      return plan.subject.id?.toString();
    }
    case AdminAPITypes.ESubjectType.user: {
      return getAccountDisplayName(plan.subject.obj);
    }
    default: {
      return DASH;
    }
  }
}

/**
 * Return the account display name
 * Will check account's name in the order of first name, last name, name and email
 * If shouldAddEmailAtEnd is true, it will add account's email address at the end of the account's name in parentheses
 */
export function getAccountDisplayName(
  account: AdminAPITypes.IAdmUser | AdminAPITypes.IUserSubjectObj,
  shouldAddEmailAtEnd: boolean = false
): string {
  let accountFullName: string = "";

  if (account.firstName && account.lastName) {
    accountFullName = `${account.firstName} ${account.lastName}`.trim();
  } else if (account.firstName) {
    accountFullName = account.firstName.trim();
  } else if (account.lastName) {
    accountFullName = account.lastName.trim();
  }

  if (accountFullName) {
    if (shouldAddEmailAtEnd) {
      return `${accountFullName} (${account.email})`.trim();
    } else {
      return accountFullName.trim();
    }
  } else if (account.email) {
    return account.email.trim();
  }

  return DASH;
}

export function formatProjectState(state: APITypes.ArchivingState): string {
  switch (state) {
    case APITypes.ArchivingState.UNARCHIVED:
      return "Unarchived";
    case APITypes.ArchivingState.ARCHIVED:
      return "Archived";
    case APITypes.ArchivingState.ARCHIVED_READY_TO_DOWNLOAD:
      return "Archived, ready to download";
    case APITypes.ArchivingState.ARCHIVED_DOWNLOADED:
      return "Archived, downloaded";
    default:
      return DASH;
  }
}

/**
 * Returns a formatted string showing the project area with unit.
 */
export function formatProjectArea(
  projectArea: AdminAPITypes.IAdmProject["area"]
): string {
  return `${DataFormatter.numberThousandsSeparated(projectArea.amount)} ${
    projectArea.unit
  }`;
}

export function formatConstraintType(
  constraintType: AdminAPITypes.EConstraintType
): string {
  switch (constraintType) {
    case AdminAPITypes.EConstraintType.projectArea:
      return "sqft";

    case AdminAPITypes.EConstraintType.projectCount:
      return "Projects";

    case AdminAPITypes.EConstraintType.userCount:
      return "Users";

    case AdminAPITypes.EConstraintType.waypoint:
      return "Capture Locations";

    case AdminAPITypes.EConstraintType.timetravelOnWaypoint:
      return "Captures per Location";

    case AdminAPITypes.EConstraintType.storage:
      return "Storage";

    case AdminAPITypes.EConstraintType.userRole:
      return "User Roles";

    default:
      return "";
  }
}

/**
 * Formats the max value from bytes to gigabytes, applying thousand separators.
 * If the value is not defined, it defaults to 0.
 *
 * @param maxBytes - The input value in bytes to be converted and formatted.
 * @returns A string representing the formatted value in gigabytes with thousand separators.
 */
export function formatStorageMaxValue(maxBytes: number | undefined): string {
  const maxInGigabytes = maxBytes ? maxBytes / GIGA_BYTE_SIZE : 0;
  return DataFormatter.numberThousandsSeparated(maxInGigabytes);
}

/**
 * Formats storage type constraint values into a human-readable string.
 * Converts values from bytes to gigabytes, applies thousand separators,
 * and formats the current and maximum values for display, appending a 'GB' unit label.
 * the current value is formatted with up to four decimal places, removing trailing zeros.
 *
 * @param constraint - The constraint payload containing storage data to be formatted.
 * @returns A string representing the formatted current and maximum storage values.
 */
export function formatStorageValues(
  constraint: AdminAPITypes.CreateConstraintPayload
): string {
  const displayCurrentValue = constraint.data.current
    ? (constraint.data.current / GIGA_BYTE_SIZE)
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        .toFixed(4)
        .replace(/\.?0+$/, "")
    : "0";

  const displayMaxValue = formatStorageMaxValue(constraint.data.max);

  if (displayMaxValue === "0" && displayCurrentValue !== "0") {
    return UNDEFINED;
  }

  return `${displayCurrentValue} | ${displayMaxValue} GB`;
}

/**
 * Formats non-storage constraint values into a human-readable string.
 * Displays the current value and maximum value, appending the constraint type.
 *
 * @param constraint - The constraint payload
 * @returns A string representing the formatted current and maximum values with the constriant type.
 */
export function formatNonStorageValues(
  constraint: AdminAPITypes.CreateConstraintPayload
): string {
  return `${constraint.data.current || 0} | ${
    constraint.data.max
  } ${formatConstraintType(constraint.type)}`;
}

/**
 *  Takes a constraint payload object and formats it into a human-readable string.
 *  The format of the output string depends on the type of the constraint,
 *  Handling storage constraints differently from other types.
 */
export function formatConstraint(
  constraint: AdminAPITypes.CreateConstraintPayload
): string {
  if (constraint.type === AdminAPITypes.EConstraintType.storage) {
    return formatStorageValues(constraint);
  } else {
    return formatNonStorageValues(constraint);
  }
}

/**
 * Formats constraint values into a human-readable string without additional
 * formatting and displaying of the current value.
 */
export function nonFormatConstraint(
  constraint: AdminAPITypes.CreateConstraintPayload
): string {
  let displayMaxValue: string;
  if (constraint.type === AdminAPITypes.EConstraintType.storage) {
    displayMaxValue = formatStorageMaxValue(constraint.data.max);
    return `${displayMaxValue} GB`;
  } else {
    displayMaxValue = DataFormatter.numberThousandsSeparated(
      constraint.data.max
    );
    return `${displayMaxValue} ${formatConstraintType(constraint.type)}`;
  }
}

/**
 * Returns constraints in a readable format
 */
export function getFormattedConstraints(
  constraints: AdminAPITypes.CreateConstraintPayload[]
): string {
  return constraints.map(formatConstraint).join("\n") || UNLIMITED;
}

export function formatPlanSubjectType(
  subjectType: AdminAPITypes.ESubjectType
): string {
  switch (subjectType) {
    case AdminAPITypes.ESubjectType.user: {
      return "User";
    }
    case AdminAPITypes.ESubjectType.company: {
      return "Company";
    }
    case AdminAPITypes.ESubjectType.project: {
      return "Project";
    }
    case AdminAPITypes.ESubjectType.folder: {
      return "Folder";
    }
    case AdminAPITypes.ESubjectType.device: {
      // Note: since we don't have a device name and the summary page currently displays the company name,
      // we return "Company" here. This will be updated once we create a separate page for hardware licenses.
      return "Company";
    }
    default: {
      return "";
    }
  }
}

/**
 * Convert the billing cycle of recurring plan into a human readable format
 */
export function formatBillingCycleOfRecurringPlan(
  recurringPlan: AdminAPITypes.IRecurringPlan
): string {
  const intervalCount = recurringPlan.payment.recurring.intervalCount;

  switch (recurringPlan.payment.recurring.interval) {
    case AdminAPITypes.EPlanInterval.day:
      return intervalCount === 1 ? "Daily" : `${intervalCount} days`;

    case AdminAPITypes.EPlanInterval.week:
      return intervalCount === 1 ? "Weekly" : `${intervalCount} weeks`;

    case AdminAPITypes.EPlanInterval.month:
      return intervalCount === 1 ? "Monthly" : `${intervalCount} months`;

    case AdminAPITypes.EPlanInterval.year:
      return intervalCount === 1 ? "Yearly" : `${intervalCount} years`;
  }
}

/**
 * Turns the first character of a string into uppercase
 */
export function uppercaseFirst(input: string): string {
  if (!input) {
    return "";
  }

  return `${input[0].toUpperCase()}${input.slice(1)}`;
}

/**
 * formats a given string (user role)
 * by replacing underscores with spaces and capitalizing the first letter of each word.
 */
export function formatUserRoleString(input: string): string {
  return input
    .split("_")
    .map(
      (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(" ")
    .trim();
}

/**
 * Convert the company role into a human readable format
 */
export function formatUserCompanyRole(
  userCompanyRole: CoreAPITypes.EUserCompanyRole
): string {
  switch (userCompanyRole) {
    case CoreAPITypes.EUserCompanyRole.companyExecutive:
      return "Enterprise Admin";
    case CoreAPITypes.EUserCompanyRole.companyViewer:
      return "Enterprise Viewer";
    case CoreAPITypes.EUserCompanyRole.companyManager:
      return "Group Manager";
    case CoreAPITypes.EUserCompanyRole.projectManager:
      return "Project Manager";
    case CoreAPITypes.EUserCompanyRole.member:
      return "Project Member";
    default:
      return DASH;
  }
}
/**
 * Create a more readable string for sqft assigned to a group by separating the number in thousands
 * By definition, -1 means that there is no restriction on the area assigned to a group
 */
export function formatGroupSqftAssigned(area: number | undefined): string {
  if (!area) {
    return "unrestricted";
  }
  return DataFormatter.numberThousandsSeparated(area);
}
