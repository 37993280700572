import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

/**
 * Use this hook to fetch the members of a group in a company by providing companyId and groupId.
 * Returns the fetched members and a loading flag.
 * If the loading fails, the fetched members will be undefined.
 */
export function useMembersOfGroup(
  companyId: APITypes.CompanyId,
  groupId: APITypes.GroupId,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IGroupMember[], boolean] {
  const [groupMembers, setGroupMembers] = useState<
    AdminAPITypes.IGroupMember[]
  >([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Fetches the group members
  useEffect(() => {
    async function fetchGroupMembers(
      companyId: APITypes.CompanyId,
      groupId: APITypes.GroupId
    ): Promise<void> {
      setIsFetching(true);

      // TODO: Pagination will be implemented in https://faro01.atlassian.net/browse/HBWB-33
      try {
        setGroupMembers(
          await CoreApi.V0.ADMIN.getGroupMembersById(companyId, groupId)
        );
      } catch (error) {
        errorHandler("Could not load group members", error);
        setGroupMembers([]);
      } finally {
        setIsFetching(false);
      }
    }

    fetchGroupMembers(companyId, groupId);
  }, [companyId, groupId, errorHandler]);

  return [groupMembers, isFetching];
}
