import { Grid, GridProps } from "@mui/material";
import React from "react";
import { Color } from "utils/styles/default-colors";
import { ILabelInfoPair } from "./info-row-types";

interface IInfoRowProps extends ILabelInfoPair {
  /** The alignment of the text */
  labelTextAlign?: "left" | "right";

  /** Whether to show a colon between label and info or not */
  shouldShowColon?: boolean;

  /** Optional sx properties to override the InfoRow main style. */
  sx?: GridProps["sx"];
}

/** Displays a pair for label/info in a flex row */
export function InfoRow({
  label,
  info,
  labelTextAlign = "right",
  shouldShowColon,
  sx = {},
}: IInfoRowProps): JSX.Element {
  return (
    <Grid container sx={{ padding: "3px 0px", ...sx }}>
      <Grid
        item
        sx={{
          minWidth: "40%",
          textAlign: labelTextAlign,
          paddingRight: "10px",
          color: "rgb(137, 137, 137, 0.5)",
          alignSelf: "center",
        }}
      >
        {label}
        {shouldShowColon ? ":" : ""}
      </Grid>
      <Grid
        item
        sx={{
          color: Color.grey46,
          overflowWrap: "anywhere",
          whiteSpace: "pre",
          maxHeight: "200px",
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {info}
      </Grid>
    </Grid>
  );
}
