import React from "react";
import { AppBar, Avatar, Grid, Toolbar } from "@mui/material";
import { Color } from "utils/styles/default-colors";
import { HBLogoIcon } from "./icons";
import { UserMailAddress } from "./user-mail-address";
import { HBTooltip } from "components/hb-customs/hb-tooltips";
import { HBEuropeFlagIcon } from "components/icons";
import { runtimeConfig } from "runtime-config";
import { WorkspaceLink } from "./links/external-links";
import { HomeLink } from "./links/internal-links";
import { HBButton } from "./hb-customs/hb-button";
import { AboutTooltipInfo } from "./about-info-tooltip";

interface INavBarProps {
  /** Image URL of user's profile */
  profileImageUrl: string;
}

export function NavBar({ profileImageUrl }: INavBarProps): JSX.Element {
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        sx={{
          // set this to 60px as it is set to 64px per default for big devices
          // and that would cause problems with the layout (because of root-height)
          minHeight: "60px",
        }}
      >
        <Grid
          container
          sx={{
            "& a": {
              textDecoration: "none",
            },
            "& Button": {
              color: Color.white,
              textDecoration: "none",
              textTransform: "capitalize",
              height: "42px",
              width: "42px",
            },
          }}
          justifyContent="space-around"
          alignContent="center"
        >
          <Grid item xs={6} container alignItems="center" spacing={2}>
            <Grid item>
              <HBTooltip title={<AboutTooltipInfo />}>
                <HBLogoIcon />
              </HBTooltip>
            </Grid>

            <Grid item>
              <HomeLink>
                <HBButton type="text">Home</HBButton>
              </HomeLink>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <UserMailAddress />
            </Grid>

            <Grid item>
              <WorkspaceLink>
                <Avatar src={profileImageUrl} />
              </WorkspaceLink>
            </Grid>

            <Grid item>
              <DeploymentInfo />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

function DeploymentInfo(): JSX.Element {
  return (
    <Grid container alignItems="center">
      {formatDeployment(runtimeConfig.deployment.environment)}

      <Grid item sx={{ ml: "5px" }}>
        <DeploymentRegion region={runtimeConfig.deployment.topLevelDomain} />
      </Grid>
    </Grid>
  );
}

/**
 * Format a string containing an environment (production, staging, development)
 * For production, we just do not print the environment
 */
function formatDeployment(environment: string): string {
  switch (environment) {
    case "production":
      return "";

    case "development":
      return "dev";

    default:
      return `${environment}`;
  }
}

interface IDeploymentRegionProps {
  /** Region of the current deployment (i.e. "eu" or "com") */
  region: string;
}

function DeploymentRegion({ region }: IDeploymentRegionProps): JSX.Element {
  switch (region) {
    case "eu":
      return <HBEuropeFlagIcon fontSize="large" />;

    default:
      return <>{region.toUpperCase()}</>;
  }
}
