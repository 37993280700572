import { useEffect, useState } from "react";
import { AdminAPITypes } from "@stellar/api-logic";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { CoreApi } from "api";
import { isRecurringPlan } from "utils/type-guards";
import { PlanDataExtractor } from "utils/plan-data-extractor";

/**
 * Hook for fetching a Payment Provider Subscription for a given plan.
 * Returns a tuple containing the fetched subscription and a loading flag.
 * subscription can be null, if the plan has no attached subscription.
 */
export function usePlanSubscription(
  plan: AdminAPITypes.IPlan
): [AdminAPITypes.IPaymentProviderSubscription | null, boolean] {
  const [subscription, setSubscription] =
    useState<AdminAPITypes.IPaymentProviderSubscription | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleErrorWithDialog } = useErrorHandler();

  useEffect(() => {
    async function fetchSubscription(): Promise<void> {
      if (isRecurringPlan(plan)) {
        setIsLoading(true);
        try {
          setSubscription(
            await CoreApi.V0.ADMIN.getSubscriptionBySubscriptionId(
              PlanDataExtractor.Payment.getSubscriptionId(plan)
            )
          );
        } catch (err) {
          setSubscription(null);
          handleErrorWithDialog("Could not load subscription of plan", err);
        }
        setIsLoading(false);
      } else {
        setSubscription(null);
      }
    }

    fetchSubscription();
  }, [plan, handleErrorWithDialog]);

  return [subscription, isLoading];
}
