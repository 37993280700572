import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes, CoreAPITypes } from "@stellar/api-logic";
import { ISubjectProject } from "pages/create-project/project-creation-context-types";
import { useState } from "react";
import {
  isAmbiguousTargetGroupError,
  isCreateProjectForAccount,
} from "utils/type-guards";
import { useErrorState } from "./use-error-state";

interface IRequestCreateProjectParams {
  /** Metadata to create the project with */
  createProjectPayload: AdminAPITypes.CreateProjectPayload;

  /** The subject in which the project is going to be created for */
  subject: ISubjectProject;

  /**
   * If provided, the project will be created within this company group
   * Note: Will only work if the company ID is available from a previous request
   */
  groupId: APITypes.GroupId | null;
}

interface IUseCreateProjectParams {
  /** Function which will be called to handle errors that are not an IAmbiguousTargetGroupError */
  errorHandler: ErrorHandlingFunction;
}

interface IUseCreateProjectReturn {
  /** Indicator wether there is currently a project creation request in process */
  isRequestingProjectCreation: boolean;

  /** Request the project creation on the backend with the provided parameters */
  requestCreateProject(params: IRequestCreateProjectParams): Promise<void>;

  /** ID of the newly created project */
  createdProjectId: APITypes.ProjectId | undefined;

  /** Error that has occurred during project creation */
  projectCreationError: CoreAPITypes.IResponseError | null;

  /** Function to discard the error */
  discardProjectCreationError(): void;
}

/**
 * Hook that should be used to create a project
 *
 * When calling the returned requestCreateProject method for the first time, an API call will be made without
 * any group information and the backend will figure out in which group (if any) the project needs to be created.
 *
 * If the user happens to be managing multiple groups, an error will be provided within projectCreationError,
 * which contains the potential groups to use and to call requestCreateProject with again.
 *
 * Once the project creation has been successful, createdProjectId will contain the ID of the new project.
 */
export function useCreateProject({
  errorHandler,
}: IUseCreateProjectParams): IUseCreateProjectReturn {
  const [isRequestingProjectCreation, setIsRequestingProjectCreation] =
    useState(false);

  const [createdProjectId, setCreatedProjectId] =
    useState<APITypes.ProjectId>();

  const { error, setError } = useErrorState<CoreAPITypes.IResponseError>();

  // Company ID to use in case the project needs to be created for a specific company group
  const [companyId, setCompanyId] = useState<APITypes.CompanyId>();

  async function requestCreateProject({
    createProjectPayload,
    subject,
    groupId,
  }: IRequestCreateProjectParams): Promise<void> {
    setIsRequestingProjectCreation(true);

    try {
      let createdProject: AdminAPITypes.IAdmProject;

      if (!isCreateProjectForAccount(subject)) {
        createdProject =
          await CoreApi.V0.ADMIN.createEmptyProjectWithinCompanyGroup(
            createProjectPayload,
            subject.company.id,
            subject.group.id
          );
      } else if (companyId && groupId) {
        createdProject =
          await CoreApi.V0.ADMIN.createEmptyProjectWithinCompanyGroup(
            createProjectPayload,
            companyId,
            groupId
          );
      } else {
        if (groupId) {
          // eslint-disable-next-line no-console -- No need to show this to user, even though worth share it in console
          console.info(
            "requestCreateProject(): project can only be created if company ID is present as well"
          );
        }

        createdProject = await CoreApi.V0.ADMIN.createEmptyProject(
          createProjectPayload
        );
      }

      setCreatedProjectId(createdProject.id);
    } catch (error) {
      if (isAmbiguousTargetGroupError(error)) {
        // The user account is managing multiple groups, so we need to tell the BE
        // in which group the project has to be created.
        setCompanyId(error.errorData.details.companyId);
      } else {
        errorHandler("Could not create project", error);
      }

      setError(error);
    }

    setIsRequestingProjectCreation(false);
  }

  return {
    isRequestingProjectCreation,
    requestCreateProject,
    createdProjectId,
    projectCreationError: error,
    discardProjectCreationError: () => setError(null),
  };
}
