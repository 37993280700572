import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

const defaultWarning = "Closing this page will cause any changes to be lost.";

/**
 * Used to show a warning before navigating away from a page
 * Only needs to be rendered inside the page's component
 */
export function NavigationWarning(): JSX.Element {
  // Shows an alert when user wants to reload or close the tab from the browser
  // These situations cannot be handled by <Prompt /> component of react-router-dom
  useEffect(() => {
    // handle non-standard browser behavior
    // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    function handleBeforeUnload(event: BeforeUnloadEvent): void {
      event.preventDefault();
      event.returnValue = "";
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // <Prompt /> shows an alert when user wants to change the route within the same document
  // (e.g. by clicking on a button or back/forward button of the browser)
  return <Prompt when={true} message={defaultWarning} />;
}
