import { Box, Breadcrumbs } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Color } from "utils/styles/default-colors";
import { RootRoute } from "router/root-routes";
import { IBreadcrumbsData } from "./breadcrumbs-types";

interface ICustomBreadCrumbsProps {
  /** Data to use for the Link (i.e. the breadcrumbs) generation */
  breadcrumbsData: IBreadcrumbsData[];
}

/** Main component to create breadcrumbs */
export function HBBreadcrumbs({
  breadcrumbsData: customBreadcrumbs,
}: ICustomBreadCrumbsProps): JSX.Element {
  // Create the complete breadcrumb by adding the "Home" link
  const breadcrumbs: IBreadcrumbsData[] = [
    { label: "Home", link: RootRoute },
  ].concat(customBreadcrumbs);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ ml: "100px", mt: "10px" }}>
      {breadcrumbs.map((breadcrumb, index) => (
        <Box
          sx={{
            color: Color.blue,
            textTransform: "capitalize",
            fontSize: "16px",
          }}
          key={index}
          component={Link}
          to={breadcrumb.link}
          color="inherit"
        >
          {breadcrumb.label}
        </Box>
      ))}
    </Breadcrumbs>
  );
}
