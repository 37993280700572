import React, { PropsWithChildren } from "react";
import { runtimeConfig } from "../../runtime-config";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import sortBy from "lodash/sortBy";
import { HBLink } from "components/hb-customs/hb-link";
import { ReactChildrenOnly } from "utils/utility-types";

/** External link to the workspace page */
export function WorkspaceLink({ children }: ReactChildrenOnly): JSX.Element {
  return (
    <HBLink href={runtimeConfig.urls.entryPageUrl} title="Go to Workspaces">
      {children}
    </HBLink>
  );
}

interface IPaymentProviderLinkProps {
  /** Subscription containing the invoice to display a link to */
  subscription: AdminAPITypes.IPaymentProviderSubscription;
}

/** External link to the newest invoice in the subscription */
export function PaymentProviderInvoiceLink({
  subscription,
}: IPaymentProviderLinkProps): JSX.Element {
  const newestInvoice = sortBy(
    subscription.invoices,
    (subscription) => new Date(subscription.date)
  ).reverse()[0];

  return <HBLink href={newestInvoice.providerUrl}>{newestInvoice.id}</HBLink>;
}

/** External link to the subscription of the payment provider */
export function PaymentProviderSubscriptionLink({
  subscription,
}: IPaymentProviderLinkProps): JSX.Element {
  return (
    <HBLink key={subscription.id} href={subscription.providerUrl}>
      {subscription.id}
    </HBLink>
  );
}

interface IOldAdminPanelCompanyLinkProps {
  /** ID of the company to open in the old Admin Panel */
  companyId: APITypes.CompanyId;
}

/** External link to company with companyId in the old admin-panel */
export function OldAdminPanelCompanyLink({
  children,
  companyId,
}: PropsWithChildren<IOldAdminPanelCompanyLinkProps>): JSX.Element {
  const href = new URL(
    `admin#nav_dashboard/edit/${companyId}`,
    runtimeConfig.urls.apiUrl
  ).toString();

  return (
    <HBLink href={href} title="Open Company in Legacy Admin Panel">
      {children}
    </HBLink>
  );
}

interface IOldAdminPanelProjectLinkProps {
  /** ID of the project to open in the old Admin Panel */
  projectId: APITypes.ProjectId;
}

/** External link to project with projectId in the old admin-panel */
export function OldAdminPanelProjectLink({
  children,
  projectId,
}: PropsWithChildren<IOldAdminPanelProjectLinkProps>): JSX.Element {
  const href = new URL(
    `admin#nav_projects/search/${projectId}`,
    runtimeConfig.urls.apiUrl
  ).toString();

  return (
    <HBLink href={href} title="Open Project in Legacy Admin Panel">
      {children}
    </HBLink>
  );
}

interface IOldAdminPanelAccountLinkProps {
  /** ID of the account to open in the old Admin Panel */
  accountId: APITypes.UserId;
}

/** External link to account with accountId in the old admin-panel */
export function OldAdminPanelAccountLink({
  children,
  accountId,
}: PropsWithChildren<IOldAdminPanelAccountLinkProps>): JSX.Element {
  const href = new URL(
    `admin#nav_actions/search/${accountId}`,
    runtimeConfig.urls.apiUrl
  ).toString();

  return (
    <HBLink href={href} title="Open Account in Legacy Admin Panel">
      {children}
    </HBLink>
  );
}
