import { Box, Divider, Grid, TextField } from "@mui/material";
import { FormContainer } from "components/forms/form-container";
import { HBRadioGroup } from "components/hb-customs/hb-radio-group";
import { AdminAPITypes } from "@stellar/api-logic";
import React, { useEffect, useState } from "react";
import {
  formatConstraintType,
  formatPlanSubjectType,
} from "utils/data-display";
import { HBDateRangePicker } from "components/hb-customs/hb-date-range-picker";
import { PlanLimitForm } from "components/forms/plan-limit-form";
import { useNavigation } from "router/use-navigation";
import { usePlanCreationContext } from "../plan-creation-context";
import { ProductDropdown } from "../create-plan-components/product-dropdown";
import { DateTimeUtils } from "@stellar/web-core";
import { TRIAL_PLAN_DURATION_IN_DAYS } from "utils/date-time";
import { PlanRangeInfo } from "./plan-range-info";
import { useDefaultPlanLimits } from "./use-default-plan-limits";
import { useAppDispatch, useAppSelector } from "store/store-helper";
import {
  selectedProductSelector,
  shouldSelectedProductHaveLimits,
} from "store/products/products-selector";
import { resetProductsState } from "store/products/products-slice";

export function CreatePlanForm(): JSX.Element {
  const planCreationContext = usePlanCreationContext();
  const { navigateToProfilePage } = useNavigation();

  const dispatch = useAppDispatch();

  const [isDateRangeAdjustedByUser, setIsDateRangeAdjustedByUser] =
    useState<boolean>(false);

  const {
    planSubjectId,
    planSubjectType,
    planSubjectName,
    shouldAddToExistingStripeSubscription,
    setShouldAddToExistingStripeSubscription,
    existingStripeSubscriptionId,
    setExistingStripeSubscriptionId,
    activeConstraints,
    setActiveConstraints,
    comments,
    setComments,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isDateRequired,
    setShouldShowSummary,
  } = planCreationContext;

  const selectedProduct = useAppSelector(selectedProductSelector);
  const shouldProductHaveLimits = useAppSelector(
    shouldSelectedProductHaveLimits(planSubjectType)
  );

  const validationErrorMessage: string | null = validatePlanCreationData();
  const shouldCreateButtonBeDisabled: boolean = validationErrorMessage !== null;

  // Returns an error message if the plan-creation data is not valid.
  // Returns null if the data is valid.
  function validatePlanCreationData(): string | null {
    // A feature must be selected
    if (selectedProduct === null) {
      return "Please select a product";
    }

    // Adding a comment for all type of plans is mandatory
    if (!comments) {
      return "Please add a comment";
    }

    if (
      shouldAddToExistingStripeSubscription &&
      !existingStripeSubscriptionId
    ) {
      return "Please select an existing Stripe subscription";
    }

    if (isDateRequired && !DateTimeUtils.isValidDate(startDate)) {
      return "Please select a valid start date";
    }

    if (isDateRequired) {
      if (!DateTimeUtils.isValidDate(endDate)) {
        return "Please select a valid end date";
      }

      if (
        startDate &&
        endDate &&
        DateTimeUtils.diffBetweenTwoDatesInDays(startDate, endDate) > 0
      ) {
        return "Please select an end date that comes after the start date";
      }
    }

    if (shouldProductHaveLimits && activeConstraints.length === 0) {
      return "Please add at least one valid limit";
    }

    for (const constraint of activeConstraints) {
      if (!isConstraintMaxValid(constraint.data.max)) {
        return `Please enter a valid ${formatConstraintType(
          constraint.type
        )} constraint`;
      }

      // Ensure that at least one user role is selected when the constraint type is 'userRole'
      if (constraint.type === AdminAPITypes.EConstraintType.userRole) {
        const roles = constraint.data.roles;
        const isCompanyRolesSelected =
          roles?.company && roles.company.length > 0;
        const isResourceRolesSelected =
          roles?.resource && roles.resource.length > 0;

        if (!isCompanyRolesSelected && !isResourceRolesSelected) {
          return "Please select at least one user role for the constraint.";
        }
      }
    }

    return null;
  }

  // Clear constraints if the plan should not have any limits (e.g. for project plans and some features)
  useEffect(() => {
    if (!shouldProductHaveLimits) {
      setActiveConstraints([]);
    }
  }, [dispatch, setActiveConstraints, shouldProductHaveLimits]);

  useDefaultPlanLimits({ setActiveConstraints });

  // Change the end data based on the selected plan:
  //  * Trial plans should last 21 days
  //  * All other plans should last one year
  // This should only be done in case the user did not manually adjusted the dates of the plan
  useEffect(() => {
    if (!isDateRangeAdjustedByUser) {
      // End date is subtracted by 1 as both start and end date are taken into account for plan duration

      if (
        selectedProduct?.identifier ===
        AdminAPITypes.EFeatureBundleIdentifier.trial
      ) {
        setEndDate(
          DateTimeUtils.addDays({
            date: startDate,
            days: TRIAL_PLAN_DURATION_IN_DAYS - 1,
          })
        );
      } else {
        setEndDate(
          DateTimeUtils.addDays({
            date: startDate,
            days: DateTimeUtils.Duration.oneYear.inDays - 1,
          })
        );
      }
    }
  }, [
    isDateRangeAdjustedByUser,
    selectedProduct?.identifier,
    setEndDate,
    startDate,
  ]);

  return (
    <FormContainer
      sx={{
        "& .MuiFormControl-root": {
          mt: "20px",
        },
      }}
      handleCreateClicked={() => {
        setShouldShowSummary(true);
      }}
      handleCancelClicked={() => {
        navigateToProfilePage(planSubjectType, planSubjectId);
        dispatch(resetProductsState());
      }}
      shouldCreateButtonBeDisabled={shouldCreateButtonBeDisabled}
      createButtonTooltip={validationErrorMessage}
    >
      <>
        <Box sx={{ fontSize: "20px" }}>Plan Details</Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* plan subject name */}
          <Grid item md={6}>
            <TextField
              label={formatPlanSubjectType(planSubjectType)}
              variant="outlined"
              value={planSubjectName}
              disabled
              fullWidth
            />
          </Grid>

          <Grid item md={6}>
            <Divider sx={{ margin: "20px 0px 10px 0px" }} />
          </Grid>

          <Grid container item md={6} justifyContent="space-between">
            <Grid item xs={7}>
              <HBRadioGroup
                label="Link to an existing Stripe subscription?"
                value={shouldAddToExistingStripeSubscription}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setShouldAddToExistingStripeSubscription(
                    event.target.value === "true"
                  );
                }}
                radioOptions={{
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Radio Button label
                  No: false,
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Radio Button label
                  Yes: true,
                }}
              />
            </Grid>

            {shouldAddToExistingStripeSubscription && (
              <Grid item xs={5}>
                {/* TODO: Add logic to use this input */}
                <TextField
                  label="Existing Stripe subscription"
                  variant="outlined"
                  fullWidth
                  value={existingStripeSubscriptionId}
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    setExistingStripeSubscriptionId(event.target.value)
                  }
                />
              </Grid>
            )}
          </Grid>

          <Grid item md={6}>
            <Divider sx={{ margin: "20px 0px 10px 0px" }} />
          </Grid>

          {/* product type */}
          <Grid item md={6}>
            <ProductDropdown planSubjectType={planSubjectType} />
          </Grid>

          {shouldProductHaveLimits && (
            <Grid item md={6} sx={{ pt: "15px", pb: "5px" }}>
              <PlanLimitForm
                activeConstraints={activeConstraints}
                setActiveConstraints={setActiveConstraints}
                planSubjectType={planSubjectType}
              />
            </Grid>
          )}

          {isDateRequired && (
            <Grid container justifyContent="space-between">
              <Grid item md={6}>
                <HBDateRangePicker
                  {...{
                    startDate,
                    setStartDate,
                    endDate,
                    setEndDate,
                  }}
                  onDateChange={() => setIsDateRangeAdjustedByUser(true)}
                />
              </Grid>
              <Grid item md={5}>
                <PlanRangeInfo startDate={startDate} endDate={endDate} />
              </Grid>
            </Grid>
          )}

          {/* comments */}
          <Grid item md={6}>
            <TextField
              label="Comments"
              multiline
              minRows={4}
              value={comments}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                setComments(event.target.value);
              }}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Box>
      </>
    </FormContainer>
  );
}

/**
 * Checks whether the given value for constraint creation payload is valid
 */
function isConstraintMaxValid(maxValue: number): boolean {
  // 0 is valid for sending to backend, but makes no sense from the
  // user's perspective, so require a number greater than 0
  return maxValue > 0;
}
