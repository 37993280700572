import React, { useContext } from "react";
import { IPlanCreationContext } from "./plan-creation-context-types";

export const PlanCreationContext =
  React.createContext<IPlanCreationContext | null>(null);

export function usePlanCreationContext(): IPlanCreationContext {
  const context = useContext(PlanCreationContext);

  if (!context) {
    throw new Error(
      "usePlanCreationContext() has to be used within a PlanCreationContext."
    );
  }

  return context;
}
