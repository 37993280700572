import { TableCell } from "@mui/material";
import React from "react";
import {
  DASH,
  formatProjectArea,
  formatProjectState,
} from "utils/data-display";
import { AdminAPITypes } from "@stellar/api-logic";
import { HBSortableTable } from "./hb-sortable-table";
import { SortableColumn } from "./hb-table-types";
import { Compare } from "utils/compare";
import { ProjectLink } from "components/links/internal-links";
import { DateTimeUtils } from "@stellar/web-core";
import { isCompanyContext } from "utils/type-guards";

interface IProjectsTableProps {
  projects: AdminAPITypes.IAdmProject[];
}

export function ProjectsTable({ projects }: IProjectsTableProps): JSX.Element {
  const columns: SortableColumn<AdminAPITypes.IAdmProject>[] = [
    { id: "name", label: "Project" },
    { id: "area", label: "Area", compareFunction: Compare.projectsByArea },
    { id: "state", label: "Archiving State" },
    {
      id: "group",
      label: "Group",
      compareFunction: Compare.projectsByGroup,
    },
    { id: "createdAt", label: "Created Date" },
    { id: "modifiedAt", label: "Last Edited" },
  ];

  return (
    <HBSortableTable
      columns={columns}
      tableData={projects}
      renderTableCells={(project) => {
        const groupName = isCompanyContext(project.context)
          ? project.context.group.name
          : DASH;

        return (
          <>
            <TableCell component="th" scope="row">
              <ProjectLink projectId={project.id}>{project.name}</ProjectLink>
            </TableCell>
            <TableCell>{formatProjectArea(project.area)}</TableCell>
            <TableCell>{formatProjectState(project.state)}</TableCell>
            <TableCell>{groupName}</TableCell>
            <TableCell>
              {DateTimeUtils.format({ date: project.createdAt })}
            </TableCell>
            <TableCell>
              {DateTimeUtils.format({ date: project.modifiedAt })}
            </TableCell>
          </>
        );
      }}
    />
  );
}
