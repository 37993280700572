import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store-helper";
import { ProductsAdapter } from "./products-slice";
import { BaseProduct, ProductIdentifier } from "./products-slice-helper-types";
import { AdminAPITypes } from "@stellar/api-logic";

/** Returns all the stored products */
export const allProductsSelector: (state: RootState) => BaseProduct[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return ProductsAdapter.getSelectors().selectAll(state.products);
    }
  );

/** Returns the selected product identifier */
export const selectedProductIdentifierSelector: (
  state: RootState
) => ProductIdentifier | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.products.selectedProductIdentifier;
  }
);

/** Returns the selected addon identifier */
export const selectedAddonIdentifierSelector: (
  state: RootState
) => AdminAPITypes.EFeatureBundleIdentifier | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.products.selectedAddonIdentifier;
  }
);

/** Returns the selected product */
export const selectedProductSelector: (state: RootState) => BaseProduct | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProductIdentifier =
        selectedProductIdentifierSelector(state);
      return getProductByIdentifierSelector(
        selectedProductIdentifier ?? undefined
      )(state);
    }
  );

/** Returns the selected addon */
export const selectedAddonSelector: (state: RootState) => BaseProduct | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedAddonIdentifier = selectedAddonIdentifierSelector(state);
      return getProductByIdentifierSelector(
        selectedAddonIdentifier ?? undefined
      )(state);
    }
  );

/** Gets a product by providing the identifier */
export function getProductByIdentifierSelector(
  productIdentifier: ProductIdentifier | undefined
): (state: RootState) => BaseProduct | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!productIdentifier) {
        return null;
      }
      return (
        ProductsAdapter.getSelectors().selectById(
          state.products,
          productIdentifier
        ) ?? null
      );
    }
  );
}

/** Get all the products of provided type from the store for the provided planSubjectType */
export function allProductsOfTypeSelector(
  productType: BaseProduct["type"],
  planSubjectType: AdminAPITypes.ESubjectType
): (state: RootState) => BaseProduct[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProductsOfType = allProductsSelector(state).filter(
        (product) => product.type === productType
      );

      /** All the products of type productType that have planSubjectType as their scope */
      return allProductsOfType.filter(({ identifier }) => {
        const availableScope =
          getProductByIdentifierSelector(identifier)(state)?.scope;
        if (availableScope === undefined) {
          return false;
        }
        return Object.keys(availableScope).includes(planSubjectType);
      });
    }
  );
}

/**
 * Returns true if the selected product should have an addon.
 * Currently only subscriptions can have an addon.
 */
export const shouldSelectedProductHaveAddons: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProduct = selectedProductSelector(state);
      if (selectedProduct === null) {
        return false;
      }
      return selectedProduct.type === "subscription";
    }
  );

/** Whether the products are fetching or not */
export const isFetchingProductsSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.products.isFetching;
    }
  );

/** Returns true if the selected product should have limits */
export function shouldSelectedProductHaveLimits(
  planSubjectType: AdminAPITypes.ESubjectType
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProduct = selectedProductSelector(state);
      if (selectedProduct === null) {
        return false;
      }
      return selectedProduct.scope[planSubjectType] ?? false;
    }
  );
}
