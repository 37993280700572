import { AdminAPITypes } from "@stellar/api-logic";
import React, { useContext } from "react";

export const ProjectContext =
  React.createContext<AdminAPITypes.IAdmProject | null>(null);

export function useProjectContext(): AdminAPITypes.IAdmProject {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error(
      "useProjectContext() has to be used within a ProjectContext."
    );
  }

  return context;
}
