import { ErrorBoundary } from "components/error-boundary/error-boundary";
import { HomePage } from "pages/home/home-page";
import { PageNotFound } from "pages/page-not-found";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { AccountRouter } from "./account/account-router";
import { AccountRouterRoot } from "./account/account-routes";
import { CompanyRouter } from "./company/company-router";
import { CompanyRouterRoot } from "./company/company-routes";
import { ProjectRouter } from "./project/project-router";
import { ProjectRouterRoot } from "./project/project-routes";
import { RootRoute } from "./root-routes";

export function RootRouter(): JSX.Element {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path={RootRoute}>
          <HomePage />
        </Route>

        <Route path={CompanyRouterRoot}>
          <CompanyRouter />
        </Route>

        <Route path={ProjectRouterRoot}>
          <ProjectRouter />
        </Route>

        <Route path={AccountRouterRoot}>
          <AccountRouter />
        </Route>

        {/* Catch all undefined routes */}
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}
