import { Switch } from "@mui/material";
import { CoreApi } from "api";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { Header } from "components/header";
import React, { useState } from "react";
import { formatProjectState } from "utils/data-display";
import { OldAdminPanelProjectLink } from "components/links/external-links";
import { DateTimeUtils } from "@stellar/web-core";
import { useProjectContext } from "./project-context";
import { HBDialog } from "components/hb-customs/hb-dialog";
import LaunchIcon from "@mui/icons-material/Launch";
import { HBSnackbar } from "components/hb-customs/hb-snackbar";

/**
 * Display Project information in the header of project profile page
 */
export function ProjectProfileHeader(): JSX.Element {
  const project = useProjectContext();

  return (
    <Header
      title="Project"
      heading={project.name}
      rightProperties={[
        {
          label: "Created",
          info: DateTimeUtils.format({ date: project.createdAt }),
        },
        { label: "Archiving State", info: formatProjectState(project.state) },
        {
          label: "Standalone",
          info: <ProjectStandaloneState />,
        },
        {
          label: "Project ID",
          info: (
            <>
              {project.id}
              <OldAdminPanelProjectLink projectId={project.id}>
                <LaunchIcon
                  sx={{ verticalAlign: "text-bottom" }}
                  fontSize="small"
                  color="primary"
                />
              </OldAdminPanelProjectLink>
            </>
          ),
        },
      ]}
    />
  );
}

/**
 * Rendering current "standalone" state of the project, and a switch to toggle the project standalone
 */
function ProjectStandaloneState(): JSX.Element {
  const project = useProjectContext();

  const [isProjectStandalone, setIsProjectStandalone] = useState<boolean>(
    project.standalone
  );
  const [shouldShowConfirmation, setShouldShowConfirmation] =
    useState<boolean>(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const { handleErrorWithDialog } = useErrorHandler();

  /**
   * Toggle project's standalone after user clicks on the Switch button
   */
  async function handleOnDialogConfirm(): Promise<void> {
    try {
      const { standalone: isStandalone } =
        await CoreApi.V0.ADMIN.changeStandaloneStateOfProject(
          project.id,
          !isProjectStandalone
        );

      setIsProjectStandalone(isStandalone);
      setIsSnackbarOpen(true);
    } catch (error) {
      handleErrorWithDialog(
        "The project's standalone state could not be changed",
        error
      );
    } finally {
      setShouldShowConfirmation(false);
    }
  }

  let confirmMessage: string = "";
  let confirmedMessage: string = "";

  if (isProjectStandalone) {
    confirmMessage = "You are about to set the project to non-standalone";
    confirmedMessage = "Project is set to standalone successfully";
  } else {
    confirmMessage = "You are about to set the project to standalone";
    confirmedMessage = "Project is set to non-standalone successfully";
  }

  return (
    <>
      <Switch
        checked={isProjectStandalone}
        onChange={() => setShouldShowConfirmation(true)}
        color="secondary"
        title="Standalone projects won’t count to any project/sqft company limits"
      />

      {shouldShowConfirmation && (
        <HBDialog
          title={confirmMessage}
          onClose={() => setShouldShowConfirmation(false)}
          onConfirm={handleOnDialogConfirm}
        >
          This will have an effect on the subscription and available
          functionality of the project. It could influence the accessibility of
          the project data for the company. Please only confirm if you fully
          understand the implications.
          <br />
          <br />
        </HBDialog>
      )}

      <HBSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
      >
        {confirmedMessage}
      </HBSnackbar>
    </>
  );
}
